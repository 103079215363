import React, { useState } from "react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { Box, Flex } from "@chakra-ui/react";
import ItemInviteTeamSelection from "../../../../../components/Common/ItemInviteTeamSelection";
import NoContentComponent from "../../../../../components/Common/NoContentComponent";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

interface TeamSelectionProps {
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItem: any;
  setSelectedItem: any;
  teams: any[];
  setTeams: React.Dispatch<React.SetStateAction<any[]>>;
}

const TeamTournamentSelection: React.FC<TeamSelectionProps> = ({
  goToStep,
  isSaving,
  setIsSaving,
  selectedItem,
  setSelectedItem,
  teams,
  setTeams,
}) => {
  const { hideContainerModal } = appStore((state) => state);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const onSelectTeam = (teamSelected: any) => {
    const updatedTeams = teams.map((team) => ({
      ...team,
      selected: team.id === teamSelected.id,
    }));
    setTeams(updatedTeams);
    setSelectedItem(teamSelected);
  };

  const onPresSelectChampion = async () => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      goToStep(stepsTournaments.successFinishSeason);
    } catch (error) {
      console.error("Error selecting champion: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [{ key: "team", value: selectedItem }];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPresSelectChampion();
    } else {
      return;
    }
  };

  if (isSaving) {
    <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={4}
      width="100%"
      height="100%"
    >
      <ModalHeaderComponent
        title={i18n.t("selectChampion")}
        subtitle={i18n.t("readyTournament")}
        confirmText={i18n.t("continue")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(stepsTournaments.optionsSeason)}
        onConfirm={handleSubmit}
        isSaving={isSaving}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      {errors.team && (
        <BodyText
          paddingLeft={4}
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.red}
        >
          {errors.team}
        </BodyText>
      )}
      <Flex
        flexDirection="column"
        gap={4}
        flexGrow={1}
        justifyContent={teams.length === 0 ? "center" : "flex-start"}
        alignItems={teams.length === 0 ? "center" : "flex-end"}
      >
        {teams.length > 0 ? (
          teams.map((team) => (
            <ItemInviteTeamSelection
              key={team.id}
              item={team}
              selectedItem={team.selected ? team : null}
              setSelectedItem={() => onSelectTeam(team)}
            />
          ))
        ) : (
          <NoContentComponent textPrimary={i18n.t("teamsNotLoaded")} />
        )}
      </Flex>
    </Box>
  );
};

export default TeamTournamentSelection;
