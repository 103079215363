import React, { useEffect, useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import i18n from "../../i18n/i18n";
import ButtonComponent from "../../components/Common/ButtonComponent";
import TournamentCardComponent from "./tournament/TournamentCardComponent";
import CupCardComponent from "./cups/CupCardComponent";
import { appStore, matchStore } from "../../zustand/globalStore";
import {
  getTournamentById,
  getTournamentsByUserId,
} from "../../models/Tournament";
import TeamCard from "./teams/TeamCard";
import { getTeamById, getTeamsByUser } from "../../models/TeamModel";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../../components/Common/BodyText";
import SpinnerComponent from "../../components/Common/SpinnerComponent";
import { ListCoups } from "./cups/containers/Coup.container";

interface TournamentCupAndTeamTabsComponentProps {
  handleParameter: (param: string) => void;
}

const TournamentCupAndTeamTabsComponent: React.FC<
  TournamentCupAndTeamTabsComponentProps
> = ({ handleParameter }) => {
  const [activeButton, setActiveButton] = useState("myTournaments");
  const [loading, setLoading] = useState(false);
  const [cups, setCups] = useState([]);
  const { userData, tournaments, setTournaments, teams, setTeams } = appStore(
    (state) => state
  );

  const loadTournaments = async () => {
    setLoading(true);
    const userTournaments = await getTournamentsByUserId(userData?.id);
    const loadedTournaments = [];
    for (const tournament of userTournaments) {
      const detailedTournament = await getTournamentById(
        tournament.tournament_id
      );
      if (!detailedTournament.deleted) {
        loadedTournaments.push({ ...tournament, ...detailedTournament });
      }
    }
    setTournaments(loadedTournaments);
    setLoading(false);
  };

  const loadTeams = async () => {
    setLoading(true);
    const userTeams = await getTeamsByUser(userData?.id);
    const loadedTeams = [];
    for (const team of userTeams) {
      const detailedTeam = await getTeamById(team.team_id);
      if (!detailedTeam.deleted) {
        loadedTeams.push({ ...team, ...detailedTeam });
      }
    }
    console.log(loadedTeams, "**************LOADED TEAMS************");
    setTeams(loadedTeams);
    setLoading(false);
  };

  const loadCups = async () => {
    setLoading(true);
    const response = await ListCoups({user_id: userData?.id});
    if (response.status === "success") {
      setCups(response.data)
      setLoading(false)
    } else {
      setCups([])
      setLoading(false)
    }
  };

  const handleButtonClick = (button: string) => {
    setActiveButton(button);
    handleParameter(button);
    if (button === "myTournaments" && tournaments.length === 0) {
      loadTournaments();
    }
    if (button === "myTeams" && teams.length === 0) {
      loadTeams();
    }
    if (button === "myCups" && cups.length === 0) {
      loadCups();
    }
  };

  const getActiveContent = () => {
    switch (activeButton) {
      case "myTournaments":
        return tournaments.length > 0 ? (
          <TournamentCardComponent tournaments={tournaments} />
        ) : (
          <NoDataMessage message={i18n.t("no_tournaments_found")} />
        );
      case "myTeams":
        return teams.length > 0 ? (
          <TeamCard teams={teams} />
        ) : (
          <NoDataMessage message={i18n.t("no_teams_found")} />
        );
      case "myCups":
        return cups.length > 0 ? (
          <CupCardComponent cups={cups} />
        ) : (
          <NoDataMessage message={i18n.t("no_cups_found")} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeButton === "myTournaments") {
      loadTournaments();
    } else if (activeButton === "myTeams") {
      loadTeams();
    } else if (activeButton === "myCups") {
      loadCups();
    }
  }, [activeButton]);

  const NoDataMessage = ({ message }: { message: string }) => (
    <BodyText
      size="medium"
      color={colors.text.black}
      height="50px"
      alignContent="center"
    >
      {message}
    </BodyText>
  );

  return (
    <Flex flexDirection="column" gap={4}>
      <Box>
        <Flex
          align="center"
          position="relative"
          overflow="hidden"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <ButtonComponent
            variant="variantTab"
            buttonName="myCups"
            activeButton={activeButton}
            onClick={() => handleButtonClick("myCups")}
          >
            {i18n.t("myCups")}
          </ButtonComponent>
          <ButtonComponent
            variant="variantTab"
            buttonName="myTournaments"
            activeButton={activeButton}
            onClick={() => handleButtonClick("myTournaments")}
          >
            {i18n.t("myTournaments")}
          </ButtonComponent>
          <ButtonComponent
            variant="variantTab"
            buttonName="myTeams"
            activeButton={activeButton}
            onClick={() => handleButtonClick("myTeams")}
          >
            {i18n.t("myTeams")}
          </ButtonComponent>
        </Flex>
      </Box>
      <Box>
        {loading ? (
          <SpinnerComponent spinnerKey="middle" />
        ) : (
          getActiveContent()
        )}
      </Box>
    </Flex>
  );
};

export default TournamentCupAndTeamTabsComponent;
