import { Avatar, Box, Checkbox, Flex, IconButton } from "@chakra-ui/react";
import { User } from "../../../../definitions/interfaces/Users/Users.interface";
import { ReactDispatchStringState, ReactDispatchUserState } from "../../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { colors } from "../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../../components/Common/BodyText";
import i18n from "../../../../i18n/i18n";
import { steps } from "../containers/steps.container";
import IconEdit from "../../../../assets/icons/IconEdit";
import { Tournament } from "../../../../definitions/interfaces/Tournaments/Tournament.interface";
import CountryFlag from "../../../../components/Common/CountryFlag";
import React from "react";

const ItemTournament = ({
    item,
    goStep,
    selectItems,
    itemsActive
}: {
    item: any;
    goStep: (key: string) => void;
    selectItems: any,
    itemsActive: string[]
}): JSX.Element => {
    return (
        <Box
            sx={{
                ...style.container,
            }}
            key={item.id}
            className="container-item-player-user"
        >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex alignItems={"center"} gap={"10px"} justifyContent={"flex-start"}>
                    <Avatar
                        src={item.media_url}
                        name={item.name}
                    />
                    <Box>
                        <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="semiBold"
                            textColor={colors.text.black}
                            textAlign="left"
                        >
                            {item.name}
                        </BodyText>
                        <Flex gap={1}>
                            <CountryFlag countryName={item.place?.terms?.at(-1)?.value} />
                            <BodyText
                                fontSize={{ base: "12px", md: "13px" }}
                                fontWeight="regular"
                                textColor={colors.text.black}
                                textAlign="left"
                            >
                                {item.place?.terms?.at(-1)?.value || i18n.t("noRegion")}
                            </BodyText>
                        </Flex>
                    </Box>
                </Flex>
                <Flex>
                    <Checkbox isChecked={itemsActive.includes(item.id)}
                        onChange={() => selectItems(prev => 
                            itemsActive.includes(item.id) 
                            ? prev.filter(id => id !== item.id)
                            : [...prev, item.id]
                        )} />
                </Flex>
            </Flex>
        </Box>
    );
};
export default ItemTournament;

const style = {
    container: {
        padding: "12px",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "space-between",
    },
};
