import React from "react";
import i18n from "../../../../../i18n/i18n";
import { Flex, FlexProps, useToast } from "@chakra-ui/react";
import NoContentComponent from "../../../../../components/Common/NoContentComponent";
import MatchCardItem from "../../../matches/MatchCardItem";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import { appStore } from "../../../../../zustand/globalStore";
import ModalHeaderMatches from "../../../../../components/Tournaments/ModalHeaderMatches";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";
import { createMatch } from "../../../../../models/MatchModel";

interface MatchesBaseProps {
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPhase: { [key: string]: any };
  selectedGroup: { [key: string]: any };
  teams: any[];
  matches: any[];
  setSelectedMatch: React.Dispatch<React.SetStateAction<any>>;
}

const ShowMatchesScreen: React.FC<MatchesBaseProps> = ({
  goToStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  selectedGroup,
  teams,
  matches,
  setSelectedMatch,
}) => {
  const toast = useToast();
  const { userData, currentTournament, hideContainerModal } = appStore(
    (state) => state
  );

  const onConfirm = async () => {
    try {
      if (teams.length < 2) {
        toast({
          title: i18n.t("msgSelectAtLeastTwoTeams"),
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setIsSaving(true);
      let arrayMatches = generateRandomMatch(teams);

      for (let arrayMatch of arrayMatches) {
        let dataToSave = {
          tournament_id: currentTournament?.id,
          first_team_id: arrayMatch[0].id,
          second_team_id: arrayMatch[1].id,
          status: "pending",
        };
        await createMatch(
          userData.id,
          dataToSave,
          currentTournament?.id,
          selectedPhase,
          selectedGroup.id
        );
      }
    } catch (error) {
      console.error("Error deleting team:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const generateRandomMatch = (teams: any) => {
    teams = teams.map((id) => ({ id }));
    teams.forEach(
      (team) => (team.enemies = teams.filter((enemy) => enemy !== team))
    );
    const matches = [];
    while (teams.some((team) => team.enemies.length)) {
      const playing = [];
      for (const team of teams) {
        if (playing.includes(team)) continue;
        const enemy = team.enemies.find((enemy) => !playing.includes(enemy));
        if (!enemy) continue;
        team.enemies.splice(team.enemies.indexOf(enemy), 1);
        enemy.enemies.splice(enemy.enemies.indexOf(team), 1);
        playing.push(team, enemy);
      }
      if (playing.length) matches.push(playing.map((t) => t.id));
    }
    let arrayMatches = [];
    for (let match of matches) {
      let array = chunk(match, 2);
      for (let a of array) {
        arrayMatches.push(a);
      }
    }
    let matchesReturn: any = [];
    if (selectedPhase.is_round) {
      for (let match of arrayMatches) {
        matchesReturn.push([...match].reverse());
      }
    }
    return [...arrayMatches, ...matchesReturn];
  };

  const chunk = (array: any, size: number) => {
    if (!array.length) {
      return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...chunk(tail, size)];
  };

  if (isSaving) {
    return <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <>
      <ModalHeaderMatches
        title={i18n.t("matchesOf", {
          name: selectedGroup?.name || selectedPhase?.name,
        })}
        subtitle={i18n.t("readyTournament")}
        onCancel={hideContainerModal}
        onBack={() =>
          goToStep(
            selectedGroup
              ? stepsTournaments.groupsSelectionMatch
              : stepsTournaments.createMatches
          )
        }
        onClick={onConfirm}
        onNext={() => goToStep(stepsTournaments.newMatch)}
        isSaving={isSaving}
        hasMoreSteps={true}
      />
      <Flex
        {...flexProps}
        width="100%"
        height="100%"
        justifyContent={matches.length === 0 ? "center" : "flex-start"}
        alignItems={matches.length === 0 ? "center" : "flex-end"}
      >
        {matches.length > 0 ? (
          matches.map((match, index) => (
            <MatchCardItem
              item={match}
              index={index}
              key={index}
              isEditable={true}
              editClick={() => {
                setSelectedMatch(match);
                goToStep(stepsTournaments.editMatch);
              }}
              deleteClick={() => {
                setSelectedMatch(match);
                goToStep(stepsTournaments.deleteMatch);
              }}
            />
          ))
        ) : (
          <NoContentComponent textPrimary={i18n.t("matchesNotLoaded")} />
        )}
      </Flex>
    </>
  );
};

export default ShowMatchesScreen;

const flexProps: FlexProps = {
  flexDirection: "column",
  gap: 4,
  overflowY: "auto",
  css: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
};
