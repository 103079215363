import React, { useState } from "react";
import { Button, Flex, Grid, useMediaQuery } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../../components/Common/BodyText";
import i18n from "../../i18n/i18n";
import TournamentViewComponent from "./tournament/TournamentViewComponent";
import TournamentCupAndTeamTabsComponent from "./TournamentCupAndTeamTabsComponent";
import { appStore } from "../../zustand/globalStore";
import { TypeViewToShow } from "../../definitions/enums/GlobalEnums";
import TeamViewComponent from "./teams/TeamViewComponent";
import CupViewComponent from "./cups/CupViewComponent";

const TournamentCupsAndTeamsPage: React.FC = () => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const { showContainerModal } = appStore((state) => state);
  const [tabActive, setTabActive] = useState("myTournaments");
  const handleParameter = (param: any) => {
    setTabActive(param);
  };

  return (
    <>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={2}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Flex flexDirection={"column"}>
          <BodyText
            fontSize={{ base: "24px", md: "28px" }}
            fontWeight="light"
            textColor={colors.text.black}
          >
            {i18n.t("your_tournaments")}
          </BodyText>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="regular"
            textColor={colors.text.lightGray3}
          >
            {i18n.t("manage_tournaments")}
          </BodyText>
        </Flex>

        <Flex
          alignItems="flex-end"
          gap={2}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Button
            borderRadius={12}
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            fontWeight={"600"}
            height="48px"
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => {
              showContainerModal(TypeViewToShow.CreateTeamView);
            }}
          >
            {i18n.t("createYourTeam")}
          </Button>
          <Button
            borderRadius={12}
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            fontWeight={"600"}
            height="48px"
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => {
              showContainerModal(TypeViewToShow.CreateOrEditCupView);
            }}
          >
            {i18n.t("createYourCup")}
          </Button>

          <Button
            borderRadius={12}
            bg={colors.figmaColors.leaguesGradient1}
            color={colors.text.white}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            fontWeight={"600"}
            height="48px"
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => {
              showContainerModal(TypeViewToShow.CreateTournamentView);
            }}
          >
            {i18n.t("createYourTournament")}
          </Button>
        </Flex>
      </Flex>
      <Grid
        width={"100%"}
        templateColumns={isMobile ? "1fr" : "1fr 2fr"}
        gap={8}
      >
        <TournamentCupAndTeamTabsComponent handleParameter={handleParameter} />
        {tabActive === "myTournaments" && <TournamentViewComponent />}
        {tabActive === "myCups" && <CupViewComponent setTabActive={setTabActive}/>}
        {tabActive === "myTeams" && (
          <TeamViewComponent setTabActive={setTabActive} />
        )}
      </Grid>
    </>
  );
};

export default TournamentCupsAndTeamsPage;
