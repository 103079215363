import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import { trackEvent } from "../../../utils/tools";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import { SortIcon } from "../../../definitions/constants/IconsComponent";
import IconArrowShort from "../../../assets/icons/IconArrowShort";
import CoupHeaderComponent from "./CoupHeaderComponent";
import { getTournamentsByCoup } from "./containers/Coup.container";
import { Tournament } from "../../../definitions/interfaces/Tournaments/Tournament.interface";
import SpinnerComponent from "../../../components/Common/SpinnerComponent";
import ItemTournament from "./components/ItemTournament";
import TournamentCardComponent from "../tournament/TournamentCardComponent";
import { ReactDispatchStringState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";

export default function CupViewComponent({setTabActive}:{setTabActive?:ReactDispatchStringState}) {
  const { currentCup } = appStore((state) => state);
  const [activeButton, setActiveButton] = useState('tournaments')
  const [tournaments, setTournaments] = useState<Tournament[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  console.log(currentCup);

  useEffect(() => {
    trackEvent("CupViewComponent", {});
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getTournamentsByCoup({ coup_id: currentCup?.id })
      if(response.status === "success"){
        setTournaments(response.data.tournament_data)
      }else{
        setTournaments([])
      }
      setLoading(false)
    })()
  }, [currentCup?.id])

  if (!currentCup?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_cup_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return <Flex sx={styles.container}>
    <Flex direction={"column"}>
      <CoupHeaderComponent />
      <Box
        display="flex"
        alignItems="flex-end"
        alignSelf="stretch"
        flexDirection="row"
      >
        <ButtonComponent
          variant="variantTab"
          buttonName="tournaments"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("tournaments");
          }}
        >
          {i18n.t("tournaments")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="matches"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("matches");
          }}
        >
          {i18n.t("moreDetails")}
        </ButtonComponent>
      </Box>
      {loading ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (<Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={4}
        overflowY="auto"
        padding={2}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {activeButton === "tournaments" &&  <TournamentCardComponent tournaments={tournaments} setTabActive={setTabActive}/>}
       
      </Box>)}
    </Flex>
  </Flex>;
}

const styles = {
  container: {
    gap: 3,
    flexDirection: "column",
  },
};
