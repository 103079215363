import { Flex } from "@chakra-ui/react";
import React from "react";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import OptionCard from "../../../../../components/Common/OptionCard";
import { ProfileCircle } from "../../../../../definitions/constants/IconsComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import i18n from "../../../../../i18n/i18n";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";
import IconEdit from "../../../../../assets/icons/IconEdit";
import IconTrash from "../../../../../assets/icons/IconTrash";

interface SeasonsBaseProps {
  goToStep: (key: string) => void;
  seasons: any[];
  setSelectedSeason: React.Dispatch<React.SetStateAction<any>>;
}

const ShowSeasons: React.FC<SeasonsBaseProps> = ({
  goToStep,
  seasons,
  setSelectedSeason,
}) => {
  return (
    <Flex flexDirection="column" gap={4} width="100%" height="100%">
      <Flex flexDirection="column" gap={2}>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("profile")}
        </BodyText>
        <OptionCard
          title={i18n.t("profileTournament")}
          description={i18n.t("renewTournament")}
          icon={<ProfileCircle />}
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.editTournament)}
        />
      </Flex>
      <Flex flexDirection="column" gap={2} width="100%" height="100%">
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("tournamentSeasons")}
        </BodyText>
        <Flex
          padding="0px 16px"
          borderRadius={16}
          gap={3}
          height="60px"
          alignItems="center"
          cursor="pointer"
          justifyContent="center"
          backgroundColor={colors.backgrounds.gray0}
          onClick={() => goToStep(stepsTournaments.newSeason)}
        >
          <IconAddSquare stroke={colors.text.lightGray3} />
          <BodyText
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="medium"
            textColor={colors.text.lightGray3}
          >
            {i18n.t("createNewSeason")}
          </BodyText>
        </Flex>
        <Flex
          flexDirection="column"
          gap={2}
          flexGrow={1}
          maxHeight="160px"
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {seasons.length > 0 ? (
            seasons.map((season) => (
              <Flex
                padding="0px 16px"
                borderRadius={16}
                gap={3}
                minHeight="60px"
                alignItems="center"
                justifyContent="space-between"
                backgroundColor={colors.backgrounds.gray0}
              >
                <Flex direction="column">
                  <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="medium"
                    textColor={colors.text.black}
                  >
                    {season.name}
                  </BodyText>
                  <BodyText
                    fontSize={{ base: "11px", md: "12px" }}
                    fontWeight="semiBold"
                    textColor={colors.text.lightGray3} //Current season other color
                  >
                    {season.year}
                  </BodyText>
                </Flex>
                <Flex gap={3}>
                  <IconEdit
                    stroke={colors.figmaColors.secColor}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedSeason(season);
                      goToStep(stepsTournaments.optionsSeason);
                    }}
                  />
                  <IconTrash
                    stroke={colors.backgrounds.red}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedSeason(season);
                      goToStep(stepsTournaments.deleteSeason);
                    }}
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <BodyText
              fontSize={{ base: "11px", md: "12px" }}
              fontWeight="semiBold"
              textColor={colors.text.black}
            >
              {i18n.t("noData")}
            </BodyText>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("additionalSettings")}
        </BodyText>
        <OptionCard
          title={i18n.t("deleteTournament")}
          description={i18n.t("deleteTournamentData")}
          icon={<IconTrash stroke={colors.backgrounds.white} />}
          iconColor={colors.backgrounds.red}
          onClick={() => goToStep(stepsTournaments.deleteTournament)}
        />
      </Flex>
    </Flex>
  );
};

export default ShowSeasons;
