import React, { useState } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../Common/BodyText";
import {
  CloseCircleIcon,
  WuamballIcon,
  ShieldIcon,
} from "../../definitions/constants/IconsComponent";
import i18n from "../../i18n/i18n";
import SpinnerComponent from "../Common/SpinnerComponent";
import CustomTextInput from "../Common/CustomTextInput";

interface SureDeleteBaseProps {
  title: string;
  subtitle: string;
  textLabel: string;
  textInput: string;
  loading: boolean;
  onConfirm: (formData: { [key: string]: string }) => void;
}

interface SureDeleteProps extends SureDeleteBaseProps {
  cancelRequired?: boolean;
  onCancel?: () => void;
  currentName: string;
}

const SureDeleteContent: React.FC<SureDeleteProps> = ({
  title,
  subtitle,
  textLabel,
  textInput,
  loading,
  cancelRequired = false,
  onCancel,
  onConfirm,
  currentName,
}) => {
  const dataForm = {
    name: "",
  };

  const [formData, setFormData] = useState(dataForm);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.name) {
      newErrors.name = i18n.t("fieldRequired");
    }

    if (formData.name && formData.name !== currentName) {
      newErrors.name = i18n.t("nameDoesNotMatch");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onConfirm(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          <Flex justifyContent="flex-end" width="100%">
            <Button
              onClick={onCancel}
              p={0}
              height={"auto"}
              minWidth={0}
              variant="ghost"
              color={colors.text.black}
              _hover={{
                backgroundColor: "transparent",
                color: colors.text.black,
              }}
            >
              <CloseCircleIcon />
            </Button>
          </Flex>
          <Flex direction="column" justifyContent="center">
            <WuamballIcon width="100%" height="36px" />
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            gap={2}
          >
            <ShieldIcon />
            <BodyText
              fontSize={{ base: "20px", md: "28px" }}
              fontWeight="light"
              textColor={colors.text.black}
            >
              {title}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {subtitle}
            </BodyText>
            <CustomTextInput
              textField={textLabel}
              placeholder={textInput + "..."}
              defaultValue={formData.name}
              onChangeValue={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
                setErrors((prev) => ({ ...prev, name: "" }));
              }}
              isPassword={false}
              isContentDelete={true}
              errorMessage={errors.name}
              showError={!!errors.name}
              typeInput="text"
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              alignSelf="stretch"
              gap={2}
            >
              {cancelRequired && (
                <Button
                  borderRadius="12px"
                  bg={colors.backgrounds.white}
                  color={colors.figmaColors.secColor}
                  border="1px solid"
                  borderColor={colors.figmaColors.secColor}
                  fontSize="14px"
                  height="48px"
                  fontWeight={"600"}
                  _hover={{
                    color: colors.backgrounds.white,
                    bg: colors.figmaColors.secColor,
                    borderColor: "blue.600",
                  }}
                  _active={{
                    bg: colors.figmaColors.secColor,
                    borderColor: "blue.700",
                  }}
                  onClick={onCancel}
                >
                  {i18n.t("cancel")}
                </Button>
              )}
              <Button
                borderRadius="12px"
                bg={colors.backgrounds.red}
                color={colors.text.white}
                fontSize="14px"
                height="48px"
                fontWeight={"600"}
                _hover={{
                  color: colors.backgrounds.white,
                  bg: colors.backgrounds.red,
                }}
                _active={{
                  bg: colors.backgrounds.red,
                }}
                onClick={handleSubmit}
              >
                {i18n.t("delete")}
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default SureDeleteContent;
