export const validations = {
    email: (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    },
    phone: (value: string) => {
        const phoneRegex = /^\+\d{1,3}\d{9}$/; 
        return phoneRegex.test(value);
    },
    direction: (value: string) => {
        return value.trim().length > 15;
    },
    default: (value: string) => {
        return value.length >= 8;
    },
    name: (value: string) => {
        const nameRegex = /^[a-zA-Z0-9\s]*$/;
        return nameRegex.test(value);
    }
};