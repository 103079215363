import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { appStore } from "../../../../../zustand/globalStore";
import ItemInviteTeam from "../../../../../components/Common/ItemInviteTeam";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import SearchUser from "../../../../../components/Common/SearchUser";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import { inviteTeam } from "../../../../../models/Tournament";
import { consumeApi } from "../../../../../utils/tools";
import { getAllTeams } from "../../../../../models/TeamModel";
import "../../../../../components/TournamentsOfTeamsOptions/Players/styles/InvitePlayers.style.scss";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";

const InviteTeams = ({
  goToStep,
}: {
  goToStep: (key: string) => void;
}): JSX.Element => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectTeam, setSelectTeam] = useState(null);
  const [searchText, setSearchText] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { userData, currentTournament, hideContainerModal } = appStore(
    (store) => store
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (searchText.length > 0) {
        const data = await consumeApi(`teams/search_teams_by_text`, "POST", {
          user_id: userData.id,
          text: `%${searchText.toLocaleLowerCase()}%`,
        });
        let teams = data.teams;
        setTeams(teams);
        setLoading(false);
      } else {
        const teams = await getAllTeams();
        setTeams(teams);
        setLoading(false);
      }
    })();
  }, [currentTournament?.id, userData?.id, searchText]);

  const processInvitation = async () => {
    setIsSaving(true);
    await inviteTeam(
      userData.id,
      currentTournament.id,
      selectTeam.id,
      currentTournament.name,
      selectTeam.name
    );
    setIsSaving(false);
    goToStep(stepsTournaments.successInviteTeams);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "selectUser",
        value: selectTeam,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      processInvitation();
    } else {
      return;
    }
  };

  return (
    <Flex width={"100%"} height="100%" direction={"column"} gap={"20px"}>
      <ModalHeaderComponent
        title={i18n.t("inviteTeams")}
        subtitle={i18n.t("readyTournament")}
        confirmText={i18n.t("invite")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(stepsTournaments.optionsSeason)}
        onConfirm={handleSubmit}
        isSaving={isSaving}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      <SearchUser
        searchText={searchText}
        handleSearch={handleSearch}
        errors={errors}
        label={i18n.t("searchTeam")}
        placeholder={i18n.t("searchTeam") + "..."}
      />
      <Box border={`0.5px solid ${colors.backgrounds.gray1}`} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={"20px 0px"}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          overflow={"scroll"}
          className="container-content-users"
          gap={2}
        >
          {searchText.trim() !== "" && (
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("searchResults")}: {searchText}
            </BodyText>
          )}
          {teams.length > 0 &&
            teams.map((item, index) => (
              <ItemInviteTeam
                item={item}
                setUser={setSelectTeam}
                teamActive={selectTeam}
              />
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default InviteTeams;
