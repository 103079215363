import { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  Flex,
} from "@chakra-ui/react";
import PrivacySwitch from "../Common/PrivacySwitch";
import CitySelector from "../Common/CitySelector";
import ImageUploader from "../Common/ImageUploader";
import i18n from "../../i18n/i18n";
import {
  handleImageChange,
  uploadFileToAmazonS3Web,
  createLocalityFromPlace,
} from "../../utils/tools";
import { createTeam } from "../../models/TeamModel";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";
import { appStore } from "../../zustand/globalStore";
import { dataForm } from "./types/dataForm.interface";
import ModalHeaderComponent from "../Common/ModalHeaderComponent";
import ToggleQuestion from "../Shared/ToggleQuestion";
import "./styles/main.style.scss";
import MessageConfirmationComponent from "../../components/MessageConfirmationComponent";
import { validations } from "../../utils/validations";
import CustomTextInput from "../Common/CustomTextInput";
import SpinnerComponent from "../Common/SpinnerComponent";

const CreateAndEditTeam: React.FC<{
  action: string;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ action, steps, goToStep, currentStep, isSaving, setIsSaving }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);

  const {
    currentTeam,
    hideContainerModal,
    userData,
    setCurrentTeam,
    teams,
    setTeams,
  } = appStore((state) => state);

  const dataFormInit = {
    name: action === "edit" ? currentTeam.name : "",
    address: action === "edit" ? currentTeam.address : "",
    email: action === "edit" ? currentTeam.email : "",
    phone: action === "edit" ? currentTeam.phone : "",
    private: action === "edit" ? currentTeam.private : "",
    automaticallyAcceptInvitations: false,
  };
  const [formData, setFormData] = useState<dataForm>(dataFormInit);
  const [place, setPlace] = useState<PlaceDetails | null>(
    action === "edit" ? currentTeam.place : null
  );
  const [image, setImage] = useState(
    action === "edit" ? currentTeam.media_url : ""
  );
  const [isPrivate, setIsPrivate] = useState(
    action === "edit" ? currentTeam.private : true
  );

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const handleSwitchChange = () => {
    setIsPrivate(!isPrivate);
  };

  const onPressCreateTeam = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      data.name = data.name.toUpperCase();
      data.automaticallyAcceptInvitations = false
      if (place) {
        data.locality =
          action === "edit" && place === currentTeam?.place
            ? currentTeam.place
            : createLocalityFromPlace(place);
      } else {
        delete data.locality;
      }
      data.place = data.locality;
      data.private = isPrivate;
      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "teams"
        );
        data.media_url = response;
      } else if (image) {
        data.media_url = currentTeam.media_url;
      }

      if (action === "edit") {
        data.id = currentTeam.id;
      }

      let teamCreated = await createTeam(userData.id, data);
      setCurrentTeam(teamCreated);

      if (action === "edit") {
        setTeams(
          teams.map((team) => (team.id === teamCreated.id ? teamCreated : team))
        );
        goToStep(steps.successEdit);
      } else {
        setTeams([teamCreated, ...teams]);
        goToStep(steps.successScreen);
      }
    } catch (error) {
      console.error("Error creating team:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
    ];

    if (formData.email) {
      if (validations.email(formData.email)) {
        delete newErrors.email;
      } else {
        newErrors.email = i18n.t("badEmail");
      }
    }
    if (formData.name) {
      if (validations.name(formData.name)) {
        delete newErrors.name;
      } else {
        newErrors.name = i18n.t("badName");
      }
    }
    if (formData.phone) {
      if (validations.phone(formData.phone)) {
        delete newErrors.phone;
      } else {
        newErrors.phone = i18n.t("badPhone");
      }
    }
    if (formData.address) {
      if (validations.direction(formData.address)) {
        delete newErrors.address;
      } else {
        newErrors.address = i18n.t("badAddress");
      }
    }

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateTeam(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(action === "edit"
            ? currentStep === steps.editTeam
            : currentStep === steps.default) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
              flexGrow={1}
            >
              {action === "edit" ? (
                <ModalHeaderComponent
                  title={i18n.t("yourTeam")}
                  subtitle={i18n.t("StartManagingTeam")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.default)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("titleCreateTeam")}
                  subtitle={i18n.t("msgCreateTeam")}
                  confirmText={i18n.t("createTeam")}
                  onCancel={hideContainerModal}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  actionButtonsRequired={true}
                />
              )}
              {action === "edit" && (
                <ToggleQuestion
                  title={i18n.t("automaticallyAcceptInvitations")}
                  subTitle={i18n.t(
                    "you_will_be_automatically_added_to_a_tournament"
                  )}
                  value={formData.automaticallyAcceptInvitations}
                  onChange={() => {
                    setFormData((prev) => ({
                      ...prev,
                      automaticallyAcceptInvitations:
                        !prev.automaticallyAcceptInvitations,
                    }));
                  }}
                />
              )}
              <Grid
                templateColumns="200px 1fr 1fr"
                gap={8}
                flexWrap={"wrap"}
                display={"flex"}
                flexGrow={1}
              >
                <GridItem
                  colSpan={1}
                  display="flex"
                  flexGrow={1}
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <FormControl
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1} alignContent={"center"}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("nameTeam")}
                      placeholder={i18n.t("nameTeam") + "..."}
                      defaultValue={formData.name}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, name: "" }));
                      }}
                      isPassword={false}
                      errorMessage={errors.name}
                      showError={!!errors.name}
                      typeInput="text"
                    />
                    {/*Use CustomCitySelector instead CitySelector to show custom city list*/}
                    <CitySelector
                      error={errors.city}
                      place={place}
                      setPlace={setPlace}
                    />
                  </Flex>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1} alignContent={"center"}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("cellPhone")}
                      placeholder={i18n.t("cellPhone") + "..."}
                      defaultValue={formData.phone}
                      onChangeValue={(e) => {
                        const value = e.target.value;
                        if (/^[0-9+]*$/.test(value)) {
                          setFormData((prev) => ({
                            ...prev,
                            phone: value,
                          }));
                        }
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.phone}
                      showError={!!errors.phone}
                      typeInput="tel"
                    />
                    <CustomTextInput
                      textField={i18n.t("email")}
                      placeholder={i18n.t("email") + "..."}
                      defaultValue={formData.email}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.email}
                      showError={!!errors.email}
                      typeInput="email"
                    />
                    <CustomTextInput
                      textField={i18n.t("address")}
                      placeholder={i18n.t("address") + "..."}
                      defaultValue={formData.address}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.address}
                      showError={!!errors.address}
                      typeInput="text"
                    />
                  </Flex>
                </GridItem>
              </Grid>
              <PrivacySwitch
                isPrivate={isPrivate}
                isPrivateText={i18n.t("createTeamPrivate")}
                onSwitchChange={handleSwitchChange}
              />
            </Box>
          )}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("teamReady")}
              subtitle={i18n.t("messageAlert")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateAndEditTeam;
