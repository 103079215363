import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  FormLabel,
} from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import moment from "moment-timezone";
import {
  CalendarIcon,
  ClockIcon,
} from "../../definitions/constants/IconsComponent";

interface Props {
  required?: boolean;
  placeholderDate: string;
  placeholderTime: string;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}

const SelectDateAndTime = ({
  date,
  setDate,
  placeholderDate,
  placeholderTime,
}: Props): JSX.Element => {
  const [openDateCalendar, setOpenDateCalendar] = useState(false);
  const [openTimeCalendar, setOpenTimeCalendar] = useState(false);
  const datePickerRef = useRef(null);
  const timePickerRef = useRef(null);
  const inputDateRef = useRef(null);
  const inputTimeRef = useRef(null);

  const formatDateToString = (date: Date): string => {
    return moment(date).format("DD-MM-YYYY");
  };

  const formatTimeToString = (date: Date): string => {
    return moment(date).format("HH:mm");
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      inputDateRef.current &&
      !inputDateRef.current.contains(event.target)
    ) {
      setOpenDateCalendar(false);
    }
    if (
      timePickerRef.current &&
      !timePickerRef.current.contains(event.target) &&
      inputTimeRef.current &&
      !inputTimeRef.current.contains(event.target)
    ) {
      setOpenTimeCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateChange = (newDate: Date) => {
    const updatedDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      date.getHours(),
      date.getMinutes()
    );
    setDate(updatedDate);
    setOpenDateCalendar(false);
  };

  const handleTimeChange = (newTime: Date) => {
    const updatedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      newTime.getHours(),
      newTime.getMinutes()
    );
    setDate(updatedDate);
    setOpenTimeCalendar(false);
  };

  return (
    <Flex gap={1} flexDirection={"column"}>
      <FormLabel {...formLabelStyle}>
        {i18n.t("deadlineForRegistrations")}
      </FormLabel>
      <Flex gap={2.5} padding="0 16px" alignItems="center">
        <FormControl sx={{ position: "relative" }}>
          <Flex gap={1} flexDirection={"column"}>
            <InputGroup>
              <Input
                ref={inputDateRef}
                onClick={() => setOpenDateCalendar(!openDateCalendar)}
                placeholder={i18n.t(placeholderDate) + "..."}
                sx={inputStyle}
                value={date ? formatDateToString(date) : ""}
                readOnly
              />
              <InputRightElement
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  pointerEvents: "none",
                  right: "12px",
                }}
              >
                <IconButton
                  icon={<CalendarIcon />}
                  aria-label="Open date calendar"
                  variant="ghost"
                  onClick={() => setOpenDateCalendar(!openDateCalendar)}
                  _focus={{ boxShadow: "none" }}
                />
              </InputRightElement>
            </InputGroup>
            {openDateCalendar && (
              <Flex
                ref={datePickerRef}
                position="absolute"
                top="100%"
                left="50%"
                transform="translateX(-50%)"
                zIndex="10"
                marginTop={1}
              >
                <DatePicker
                  selected={date}
                  onChange={handleDateChange}
                  inline
                />
              </Flex>
            )}
          </Flex>
        </FormControl>
        <FormControl sx={{ position: "relative" }}>
          <Flex gap={1} flexDirection={"column"}>
            <InputGroup>
              <Input
                ref={inputTimeRef}
                onClick={() => setOpenTimeCalendar(!openTimeCalendar)}
                placeholder={i18n.t(placeholderTime) + "..."}
                sx={inputStyle}
                value={date ? formatTimeToString(date) : ""}
                readOnly
              />
              <InputRightElement
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  pointerEvents: "none",
                  right: "12px",
                }}
              >
                <IconButton
                  icon={<ClockIcon />}
                  aria-label="Open time calendar"
                  variant="ghost"
                  onClick={() => setOpenTimeCalendar(!openTimeCalendar)}
                  _focus={{ boxShadow: "none" }}
                />
              </InputRightElement>
            </InputGroup>
            {openTimeCalendar && (
              <Flex
                ref={timePickerRef}
                position="absolute"
                top="100%"
                left="50%"
                transform="translateX(-50%)"
                zIndex="10"
                marginTop={1}
              >
                <DatePicker
                  selected={date}
                  onChange={handleTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  inline
                />
              </Flex>
            )}
          </Flex>
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default SelectDateAndTime;

const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  borderRadius: "20px",
  height: "60px",
  border: "none",
  color: colors.text.lightGray3,
  backgroundColor: colors.backgrounds.gray0,
};
const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
