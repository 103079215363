import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import MatchesTournamentComponent from "../tournament/MatchesTournamentComponent";
import PlayersTournamentComponent from "../tournament/PlayersTournamentComponent";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import { SortIcon } from "../../../definitions/constants/IconsComponent";
import { appStore, matchStore } from "../../../zustand/globalStore";
import { trackEvent } from "../../../utils/tools";
import DetailsTeamComponent from "./DetailsTeamComponent";
import TournamentsFromTeam from "./TournamentsFromTeam";
import MatchesTournamentOnWeek from "../tournament/MatchesTournamentOnWeek";
import IconArrowShort from "../../../assets/icons/IconArrowShort";

export default function TeamViewOwnerComponent({ setTabActive }) {
  const [activeButton, setActiveButton] = useState("thisWeek");
  const { currentTeam } = appStore((state) => state);
  const {currentMatch,setCurrentMatch} = matchStore(store=>store)
  useEffect(() => {
    trackEvent("TeamViewComponent", {});
  }, []);

  if (!currentTeam?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_team_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return (
    <> 
      <Box
        display="flex"
        alignItems="flex-end"
        alignSelf="stretch"
        flexDirection="row"
      >
        <ButtonComponent
          variant="variantTab"
          buttonName="thisWeek"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("thisWeek");
          }}
        >
          {i18n.t("thisWeek")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="matches"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("matches");
          }}
        >
          {i18n.t("matches")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="tournaments"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("tournaments");
          }}
        >
          {i18n.t("tournaments")}
        </ButtonComponent>
        <Box
          display="flex"
          flex="1"
          gap={2}
          alignItems="center"
          justifyContent="flex-end"
          alignSelf="center"
          flexDirection="row"
        >
          <SortIcon />

          <Text
            fontSize={{ base: "14px", md: "13px" }}
            fontWeight="600"
            textColor={colors.text.black}
            textAlign="center"
          >
            {i18n.t("season")}: 2024
          </Text>

          <IconArrowShort />
        </Box>
      </Box>
      {activeButton === "thisWeek" && <MatchesTournamentOnWeek />}
      {activeButton === "matches" && <MatchesTournamentComponent />}
      {activeButton === "tournaments" && (
        <TournamentsFromTeam
          teamId={currentTeam?.id}
          setTabActive={setTabActive}
        />
      )}
    </>
  );
}

