import React, { useState } from "react";
import { Box, FormLabel, Input, Flex } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import ToggleQuestion from "../../../../../components/Shared/ToggleQuestion";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import { createMatch, updateMatch } from "../../../../../models/MatchModel";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

interface CreateMatchBaseProps {
  isEdit: boolean;
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditMatchProps =
  | (CreateMatchBaseProps & {
    isEdit: false;
    selectedMatch?: never;
    selectedPhase?: never;
    selectedGroup?: never;
  })
  | (CreateMatchBaseProps & {
    isEdit: true;
    selectedMatch: { [key: string]: any };
    selectedPhase: { [key: string]: any };
    selectedGroup: { [key: string]: any };
  });


const CreateOrEditMatch: React.FC<CreateOrEditMatchProps> = ({
  isEdit,
  goToStep,
  currentStep,
  isSaving,
  setIsSaving,
  selectedMatch,
  selectedPhase,
  selectedGroup,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [matchData, setMatchData] = useState<any>();
  const [isDuplicateRegulations, setIsDuplicateRegulations] = useState(false);
  const { userData, currentTournament, hideContainerModal } = appStore(
    (state) => state
  );

  console.log("selectedMatch", selectedMatch);

  const dataForm = {
    name: isEdit ? selectedMatch.name : "",
  };
  const [formData, setFormData] = useState(dataForm);

  //TODO: Finish this
  // const onPressSaveMatch = async (data: any) => {
  //   if (isSaving) return;
  //   setIsSaving(true);
  //   let dataToSave: any = {};
  //   dataToSave.first_team_id = firstTeam.id;
  //   dataToSave.second_team_id = secondTeam.id;
  //   if (dataToSave.first_team_id == dataToSave.second_team_id) {
  //     Alert.alert("Alerta", "Debe seleccionar distintos equipos");
  //     return;
  //   }
  //   if (matchDate) dataToSave.match_date = matchDate;
  //   if (place) {
  //     dataToSave.place_description = place.description;
  //     dataToSave.place = place;
  //   }
  //   if (data.field_name) dataToSave.field_name = data.field_name;
  //   if (referees) dataToSave.referees = referees;
  //   if (vocal) dataToSave.vocal_user_id = vocal.id;
  //   if (selectedMatch.id) {
  //     await updateMatch(
  //       matchData.id,
  //       dataToSave,
  //       matchData.tournament_id,
  //       matchData.phase_id,
  //       matchData.group_id
  //     );
  //   } else {
  //     await createMatch(
  //       userData.id,
  //       dataToSave,
  //       currentTournament.id,
  //       selectedPhase,
  //       selectedGroup.id
  //     );
  //   }

  //   setIsSaving(false);
  // };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      // onPressSaveMatch(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === stepsTournaments.editMatch
            : currentStep === stepsTournaments.newMatch) && (
              <Box
                as="form"
                onSubmit={handleSubmit}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={4}
              >
                <ModalHeaderComponent
                  title={isEdit ? i18n.t("editMatch") : i18n.t("newMatch")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("continue")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(stepsTournaments.matchesOf)}
                  onConfirm={() =>
                    isEdit
                      ? goToStep(stepsTournaments.editTechnicalTeam)
                      : goToStep(stepsTournaments.technicalTeam)
                  }
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              </Box>
            )}
          {(isEdit
            ? currentStep === stepsTournaments.editTechnicalTeam
            : currentStep === stepsTournaments.technicalTeam) && (
              <Box
                as="form"
                onSubmit={handleSubmit}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={4}
              >
                <ModalHeaderComponent
                  title={i18n.t("technicalStaffTeam")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() =>
                    isEdit
                      ? goToStep(stepsTournaments.editMatch)
                      : goToStep(stepsTournaments.newMatch)
                  }
                  onConfirm={() =>
                    isEdit
                      ? goToStep(stepsTournaments.successEditMatch)
                      : goToStep(stepsTournaments.successNewMatch)
                  }
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              </Box>
            )}
        </>
      )}
    </>
  );
};

export default CreateOrEditMatch;
