import { useEffect, useState, useCallback, memo } from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import { functions } from "../../../config/firebase";
import { httpsCallable } from "firebase/functions";
import {
  getNumberOfTeamsByUser,
  getNumberOfTournamentByUser,
  getUserById,
} from "../../../models/UserModel";
import { appStore } from "../../../zustand/globalStore";
import MatchCardItem from "./MatchCardItem";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import {
  MatchIncidence,
  MatchesResponse,
} from "../../../definitions/interfaces/GlobalInterfaces";
import SpinnerComponent from "../../../components/Common/SpinnerComponent";

const MatchCard = ({ dataKey, showPendingOnly }) => {
  const [user, setUser] = useState({});
  const [matches, setMatches] = useState([]);
  const [countTournaments, setCountTournaments] = useState(0);
  const [countTeams, setCountTeams] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    userData,
    setActiveCardIndex,
    activeDataKey,
    setActiveDataKey,
    setMatchId,
  } = appStore((state) => state);

  const fetchMatchData = useCallback(async () => {
    setLoading(true);
    try {
      const matchesFromUser = await httpsCallable<unknown, MatchesResponse>(
        functions,
        showPendingOnly ? "getPendingMatches" : "getAllMatchesByUser"
      )({ userId: userData.id });

      const user = await getUserById(userData.id);
      setUser(user);
      const fetchedMatches = matchesFromUser?.data?.incidences;
      setMatches(fetchedMatches);
      setMatches(matchesFromUser?.data?.incidences);
      setCountTournaments(await getNumberOfTournamentByUser(userData?.id));
      setCountTeams(await getNumberOfTeamsByUser(userData?.id));

      if (fetchedMatches.length > 0) {
        setMatchId(fetchedMatches[0].id);
      }
    } finally {
      setLoading(false);
    }
  }, [userData.id, showPendingOnly]);

  useEffect(() => {
    fetchMatchData();
  }, [showPendingOnly, fetchMatchData]);

  const RenderItemMatch = memo(
    ({ item, index }: { item: MatchIncidence; index: number }) => {
      const getRole = () => {
        if (userData.id === item.vocal_user_id) return i18n.t("vocal");
        if (userData.id === item.user_referee_id) return i18n.t("referee");
        if (userData.id === item.user_assist_first_referee_id)
          return i18n.t("assistantReferee1");
        if (userData.id === item.user_assist_second_referee_id)
          return i18n.t("assistantReferee2");
        if (userData.id === item.user_four_referee_id)
          return i18n.t("fourthOfficial");
        if (userData.id === item.user_assist_five_referee_id)
          return i18n.t("assistantReferee5");
        if (userData.id === item.user_assist_six_referee_id)
          return i18n.t("assistantReferee6");
        return undefined;
      };

      const role = getRole();

      return (
        <>
          {role && (
            <Flex>
              <BodyText
                fontWeight="bold"
                size="extraSmall"
                color={colors.text.white}
              >
                {role}
              </BodyText>
            </Flex>
          )}
          <MatchCardItem
            item={item}
            index={index}
            key={index}
            isEditable={false}
            onClick={() => setMatchId(item.id)}
          />
        </>
      );
    }
  );

  useEffect(() => {
    if (activeDataKey !== dataKey) {
      setActiveCardIndex(0);
      setActiveDataKey(dataKey);
    }
  }, [dataKey, activeDataKey, setActiveCardIndex, setActiveDataKey]);

  if (loading) {
    return <SpinnerComponent spinnerKey="middle" />;
  }

  return (
    <Flex gap={4} flexDirection="column">
      <Flex gap={1.5} flexDirection="column">
        {matches.length > 0 ? (
          <Flex gap={1.5} flexDirection="column">
            {matches.map((match, index) => (
              <RenderItemMatch key={index} item={match} index={index} />
            ))}
          </Flex>
        ) : (
          <BodyText
            size="medium"
            color={colors.text.black}
            height="50px"
            alignContent="center"
          >
            {i18n.t("no_matches_found")}
          </BodyText>
        )}
      </Flex>
    </Flex>
  );
};

const MatchCardWeek = () => <MatchCard dataKey="week" showPendingOnly={true} />;

const MatchCardAll = () => <MatchCard dataKey="all" showPendingOnly={false} />;

export { MatchCardWeek, MatchCardAll };
