import { Flex } from "@chakra-ui/react";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";
import BodyText from "../../../../../components/Common/BodyText";
import i18n from "../../../../../i18n/i18n";
import { useEffect, useState } from "react";
import {
  getCurrentManagersFromTeam,
  removeManagerTeam,
} from "../../../../../models/TeamModel";
import { appStore } from "../../../../../zustand/globalStore";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import ItemManagerTeam from "./components/ItemManagerTeam";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";
import { ReactDispatchUserState } from "../../../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

const ManagersOptionTeams = ({
  goToStep,
  hideContainerModal,
  setSelectedUser,
}: {
  goToStep: (key: string) => void;
  hideContainerModal: () => void;
  setSelectedUser: ReactDispatchUserState;
}): JSX.Element => {
  const [managers, setManagers] = useState<User[]>([]);
  const [deleteManager, setDeleteManager] = useState<User | false>(false);
  const { currentTeam, userData } = appStore((store) => store);
  const [loading, setLoading] = useState(false);
  const goDeleteManager = ({ manager }) => {
    setDeleteManager(manager);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCurrentManagersFromTeam(currentTeam.id);
      setManagers([userData, ...response]);
      setLoading(false);
    })();
  }, [currentTeam.id, userData]);

  if (loading) {
    return <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <Flex flexDirection="column" gap={4} width={"100%"} height={"100%"}>
      <Flex
        padding="0px 16px"
        borderRadius={16}
        gap={3}
        height="60px"
        alignItems="center"
        cursor="pointer"
        justifyContent="center"
        backgroundColor={colors.backgrounds.gray0}
        onClick={() => goToStep(stepsTeams.inviteManagers)}
      >
        <IconAddSquare stroke={colors.text.lightGray3} />
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("addManager")}
        </BodyText>
      </Flex>
      <Flex
        flexDirection="column"
        gap={4}
        overflowY="auto"
        maxHeight="410px"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {managers.length > 0 &&
          managers.map((item) => {
            return (
              <ItemManagerTeam
                item={item}
                setSelectUser={setSelectedUser}
                goToStep={goToStep}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

export default ManagersOptionTeams;
