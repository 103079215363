import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../../components/Common/BodyText";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";

interface OptionCardProps {
  title: string;
  description: string;
  icon?: ReactNode;
  iconColor?: string;
  arrowColor?: string;
  onClick: () => void;
}

const OptionCard: React.FC<OptionCardProps> = ({
  title,
  description,
  icon,
  iconColor,
  arrowColor,
  onClick,
}) => (
  <Flex
    padding="0px 16px"
    borderRadius={20}
    gap={2.5}
    minH="70px"
    width="100%"
    border="1px solid"
    alignItems="center"
    cursor="pointer"
    borderColor={colors.backgrounds.gray1}
    onClick={onClick}
  >
    <Flex gap={2.5} w="100%">
      {icon && (
        <Flex
          width="40px"
          height="40px"
          borderRadius={10}
          justifyContent="center"
          alignItems="center"
          backgroundColor={iconColor}
        >
          {icon}
        </Flex>
      )}
      <Flex flexDirection="column" justifyContent="center">
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.black}
        >
          {title}
        </BodyText>
        {description && (
          <BodyText
            fontSize="11px"
            fontWeight="regular"
            textColor={colors.text.lightGray3}
          >
            {description}
          </BodyText>
        )}
      </Flex>
    </Flex>
    <IconArrowLinear stroke={arrowColor || "currentColor"} />
  </Flex>
);

export default OptionCard;
