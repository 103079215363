import { useState } from "react";
import i18n from "../../../i18n/i18n";
import { appStore } from "../../../zustand/globalStore";
import DeleteContentModal from "../../../components/Modals/DeleteContentModal";
import { stepsDocuments } from "../../../definitions/constants/GlobalConstants";
import { deleteFileUser } from "../../../models/UserModel";
import SpinnerComponent from "../../Common/SpinnerComponent";

const DeleteDocument = ({
  goToStep,
  selectedDocument,
}: {
  goToStep: (key: string) => void;
  selectedDocument: { [key: string]: any };
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { userData, hideContainerModal } = appStore((store) => store);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await deleteFileUser(userData.id, selectedDocument.id);
      goToStep(stepsDocuments.successAction);
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <DeleteContentModal
      message={i18n.t("sureDeleteFile", { name: selectedDocument.name })}
      onCancel={hideContainerModal}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteDocument;
