import React from "react";
import CardComponent from "../../../components/Common/CardComponent";
import { appStore } from "../../../zustand/globalStore";
import i18n from "../../../i18n/i18n";

const CupCardComponent: React.FC<{ cups: any[] }> = ({ cups }) => {
  const { setCurrentCup } = appStore((state) => state);

  return (
    <CardComponent
      items={cups}
      isTeam={false}
      isCoup={true}
      onSetCurrentItem={setCurrentCup}
      getItemName={(cup) => cup.name}
      getItemRegion={(cup) => {
        return cup.place?.terms?.at(-1)?.value || i18n.t("noRegion");
      }}
      getItemImage={(cup) => cup.media_url}
      getItemCountryImage={(cup) => cup.place?.terms?.at(-1)?.value}
    />
  );
};

export default CupCardComponent;
