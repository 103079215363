import { Box, Flex, Image, Input } from "@chakra-ui/react";
import { AddIcon } from "../../definitions/constants/IconsComponent";
import { EditIcon } from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";

interface ImageUploaderProps {
  image: string;
  width?: string;
  isDocument?: boolean;
  onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageIdUploader: React.FC<ImageUploaderProps> = ({
  image,
  width = "100%",
  isDocument = false,
  onImageChange,
}) => {
  return (
    <Box
      width={width}
      height="200px"
      borderRadius={"20px"}
      bg={colors.backgrounds.gray1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={() => document.getElementById("file-input-id")?.click()}
    >
      <Input
        type="file"
        accept="image/*"
        onChange={onImageChange}
        display="none"
        id="file-input-id"
        onClick={(e) => e.stopPropagation()}
      />
      {image && (
        <Image
          src={image}
          boxSize="100%"
          objectFit="cover"
          borderRadius={"20px"}
        />
      )}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {image ? (
          <Flex gap="5px" alignItems="center">
            <EditIcon boxSize="20px" color="white" />
            {isDocument && (
              <BodyText
                fontSize={{ base: "12px", md: "13px" }}
                fontWeight="semiBold"
                textColor={colors.text.white}
              >
                {i18n.t("selectDocument")}
              </BodyText>
            )}
          </Flex>
        ) : (
          <AddIcon boxSize="20px" color="white" />
        )}
      </Box>
    </Box>
  );
};

export default ImageIdUploader;
