import React, { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { trackEvent } from "../../utils/tools";
import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import FormComponent from "../../components/Common/FormComponent";
import { useToast } from "@chakra-ui/react";
import AccessComponent from "../../components/LayoutsComponents/AccessComponent";
import CustomTextInput from "../../components/Common/CustomTextInput";
import i18n from "../../i18n/i18n";

const LoginPage: React.FC = () => {
  const toast = useToast();
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent("SignInScreen", {});
  }, []);

  const sendLogin = (event) => {
    event.preventDefault();

    if (emailInput === "") {
      setEmailError(true);
    }

    if (passwordInput === "") {
      setPasswordError(true);
    }

    if (emailInput !== "" && passwordInput !== "") {
      setEmailError(false);
      setPasswordError(false);
      setLoading(true);
      callFireBase();
    }
  };

  const callFireBase = async () => {
    try {
      console.log(auth);
      await signInWithEmailAndPassword(
        auth,
        emailInput.trim(),
        passwordInput
      ).then((userCredential) => {
        console.log("Logged in user", userCredential);
      });
    } catch (error) {
      console.log(error);

      const errorMapping = {
        "auth/wrong-password": i18n.t("wrong_password"),
        "auth/user-not-found": i18n.t("invalid_user"),
        "auth/invalid-email": i18n.t("wrong_email"),
        "auth/too-many-requests": i18n.t("too_many_requests"),
      };

      const _error =
        error instanceof FirebaseError ? error.code : error.toString();
      const message = errorMapping[_error] || i18n.t("wrong_password");

      toast({
        title: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccessComponent>
      <FormComponent
        isLogin={true}
        isRegister={false}
        titleFrom={i18n.t("log_in")}
        buttonText={i18n.t("start")}
        footerText={i18n.t("no_account_yet")}
        footerLink={i18n.t("sign_up")}
        onSubmit={sendLogin}
      >
        <CustomTextInput
          textField={i18n.t("email")}
          placeholder={i18n.t("email")}
          onChangeText={(text) => setEmailInput(text)}
          isPassword={false}
          showError={emailError}
          typeInput="email"
        />
        <CustomTextInput
          textField={i18n.t("password")}
          placeholder={i18n.t("password")}
          onChangeText={(text) => setPasswordInput(text)}
          isPassword={true}
          showError={passwordError}
        />
      </FormComponent>
    </AccessComponent>
  );
};

export default LoginPage;
