import { Box, IconButton } from "@chakra-ui/react";
import { User } from "../../../../../../definitions/interfaces/Users/Users.interface";
import { colors } from "../../../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../../../../components/Common/BodyText";
import i18n from "../../../../../../i18n/i18n";
import "../../styles/ItemManagerTeam.scss";
import IconTrash from "../../../../../../assets/icons/IconTrash";
import { appStore } from "../../../../../../zustand/globalStore";
import { ReactDispatchUserState } from "../../../../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { stepsTeams } from "../../../../../../definitions/constants/GlobalConstants";

const ItemManagerTeam = ({
  item,
  setSelectUser,
  goToStep,
}: {
  item: User;
  setSelectUser: ReactDispatchUserState;
  goToStep: (key: string) => void;
}): JSX.Element => {
  const { userData } = appStore((store) => store);

  return (
    <Box
      key={item.id}
      className="container-item-user-manager"
      sx={{ border: `1px solid ${colors.backgrounds.gray1}` }}
    >
      <Box>
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.black}
        >
          {item.firstname} {item.lastname}{" "}
          {item.id === userData.id && "(" + i18n.t("you") + ")"}
        </BodyText>
        <BodyText
          fontSize={{ base: "11px", md: "12px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {i18n.t(item.rol)}
          {item.id === userData.id && i18n.t("admin")}
        </BodyText>
      </Box>
      {item.id !== userData.id && (
        <IconButton
          aria-label="Delete manager"
          background={"transparent"}
          onClick={() => {
            setSelectUser(item);
            goToStep(stepsTeams.deleteManagers);
          }}
        >
          <IconTrash stroke={colors.backgrounds.red} />
        </IconButton>
      )}
    </Box>
  );
};
export default ItemManagerTeam;
