import React, { useEffect, useState } from "react";
import i18n from "../../../i18n/i18n";
import { Avatar, Box, Button, Flex, Image } from "@chakra-ui/react";
import { Tournament } from "../../../definitions/interfaces/Tournaments/Tournament.interface";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import axios from "axios";
import { appStore } from "../../../zustand/globalStore";
import { ReactDispatchStringState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { TypeViewToShow } from "../../../definitions/enums/GlobalEnums";

const CardTournamentFromTeam: React.FC<{
  tournament: Tournament;
  setTabActive: ReactDispatchStringState;
}> = ({ tournament, setTabActive }) => {
  const [flag, setFlag] = useState("");
  const { setCurrentTournament, showContainerModal } = appStore(
    (state) => state
  );
  useEffect(() => {
    (async () => {
      const responseFlag = await axios.get(
        `https://restcountries.com/v3.1/name/${tournament.place_description
          .split(",")[1]
          .trim()}?fullText=true`
      );
      setFlag(responseFlag.data[0]?.flags.svg);
    })();
  }, [tournament.place_description]);

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={styles.container_description}>
          <Avatar src={tournament.image} name={tournament.name} />
          <Box>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="bold"
              textColor={colors.text.black}
            >
              {tournament.name}
            </BodyText>
            <Flex alignItems="center" gap={1}>
              <Image src={flag} height="14px" width="20px" />
              <BodyText
                color={colors.text.lightGray3}
                size="xxSmall"
                fontWeight="regular"
              >
                {tournament.place_description.split(",")[1].trim()}
              </BodyText>
            </Flex>
          </Box>
        </Box>
        <Button
          sx={styles.buttonViewInformation}
          onClick={() => {
            setCurrentTournament(tournament);
            setTabActive("myTournaments");
          }}
        >
          <BodyText
            fontSize={{ base: "9px", md: "10px" }}
            fontWeight="bold"
            textColor={colors.figmaColors.secColor}
          >
            {i18n.t("view_info")}
          </BodyText>
        </Button>
      </Box>
      <Box sx={styles.buttonsActions}>
        <Button
          sx={styles.buttonAction}
          onClick={() => {
            setCurrentTournament(tournament);
            showContainerModal(TypeViewToShow.PlayersTournamentTeam);
          }}
        >
          <BodyText
            fontSize={{ base: "9px", md: "10px" }}
            fontWeight="bold"
            textColor={colors.text.white}
          >
            {i18n.t("registerPlayers")}
          </BodyText>
        </Button>
        <Button
          sx={styles.buttonAction}
          onClick={() => {
            setCurrentTournament(tournament);
            showContainerModal(TypeViewToShow.CoachingStaff);
          }}
        >
          <BodyText
            fontSize={{ base: "9px", md: "10px" }}
            fontWeight="bold"
            textColor={colors.text.white}
          >
            {i18n.t("register_coaching_staff")}
          </BodyText>
        </Button>
        <Button
          sx={styles.buttonAction}
          onClick={() => {
            setCurrentTournament(tournament);
            showContainerModal(TypeViewToShow.TeamSquad);
          }}
        >
          <BodyText
            fontSize={{ base: "9px", md: "10px" }}
            fontWeight="bold"
            textColor={colors.text.white}
          >
            {i18n.t("register_team_roster")}
          </BodyText>
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: "12px",
    background: "#F8F8F8",
    border: "0.5px solid #E0E0E0",
    borderRadius: "20px",
  },
  container_description: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  buttonViewInformation: {
    border: "0.5px solid " + colors.figmaColors.secColor,
    color: colors.figmaColors.secColor,
    height: "max-content",
    padding: "8px 15px",
    borderRadius: "14px",
  },
  buttonAction: {
    background: colors.figmaColors.secColor,
    height: "max-content",
    padding: "8px 15px",
    borderRadius: "14px",
  },
  buttonsActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
};
export default CardTournamentFromTeam;
