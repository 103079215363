import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, GridItem, FormControl, Flex } from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import ModalHeaderComponent from "../Common/ModalHeaderComponent";
import CitySelector from "../../components/Common/CitySelector";
import ImageUploader from "../../components/Common/ImageUploader";
import i18n from "../../i18n/i18n";
import {
  consumeApi,
  handleImageChange,
  createLocalityFromPlace,
  uploadFileToAmazonS3Web,
} from "../../utils/tools";
import { v4 as uuidV4 } from "uuid";
import { getUserByUsername } from "../../models/UserModel";
import SelectDate from "../../components/Common/SelectDate";
import ToggleQuestion from "../../components/Shared/ToggleQuestion";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";
import { DeviceOs } from "../../hooks/DeviceOs";
import MessageConfirmationComponent from "../MessageConfirmationComponent";
import { dataForm } from "./types/dataForm.interface";
import CustomTextInput from "../Common/CustomTextInput";
import SpinnerComponent from "../Common/SpinnerComponent";
import { validations } from "../../utils/validations";

const steps = {
  default: "default",
  successAction: "successAction",
};

const UpdateOrViewProfileData = () => {
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const { isNeedToCreateANewUser } = useParams();
  const { hideContainerModal, userData, setUserData } = appStore(
    (state) => state
  );
  console.log("Current user:", userData);

  const dataForm = {
    firstname: userData ? userData.firstname : "",
    lastname: userData ? userData.lastname : "",
    email: userData ? userData.email : "",
    phone_number: userData ? userData.phone_number : "",
    address: userData ? userData.address : "",
    automaticallyAcceptInvitations: userData
      ? userData.automaticallyAcceptInvitations
      : false,
  };
  const [formData, setFormData] = useState<dataForm>(dataForm);
  const [place, setPlace] = useState<PlaceDetails | null>(
    userData ? userData.place : null
  );
  const [birthDate, setBirthDate] = useState<Date | null>(
    userData && userData.bith_date ? new Date(userData.bith_date) : null
  );
  const [image, setImage] = useState(userData ? userData.image_url : "");
  const [username, setUsername] = useState(userData ? userData.username : "");

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const onPressSave = async (data: any) => {
    setIsSaving(true);
    try {
      if (place) {
        data.place =
          place === userData?.place
            ? userData.place
            : createLocalityFromPlace(place);
        data.place_id =
          place?.place_id === userData?.place?.place_id
            ? userData.place?.place_id
            : createLocalityFromPlace(place).place_id;
        data.place_description =
          place?.description === userData?.place?.description
            ? userData.place?.description
            : createLocalityFromPlace(place).description;
      } else {
        delete data.place;
        delete data.place_id;
        delete data.place_description;
      }

      if (birthDate) {
        data.bith_date =
          birthDate === userData?.bith_date
            ? userData.bith_date
            : birthDate.getTime();
      } else {
        delete data.bith_date;
      }

      if (isNeedToCreateANewUser) {
        let username = `${data.firstname}${data.lastname}`
          .toLowerCase()
          .split(" ")
          .join("")
          .split("@")
          .join("");
        const usersByUsername = await getUserByUsername(username);
        if (usersByUsername.length > 0) {
          username = `${username}${usersByUsername.length}`;
        }
        data.username = username;
      }

      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        data.image_url = response;
      } else if (image) {
        data.image_url = userData.image_url;
      }

      if (isNeedToCreateANewUser) {
        let publicIp = await fetch("https://api.ipify.org?format=json")
          .then((response) => response.json())
          .then((data) => data.ip);
        data.device_os = DeviceOs();
        data.ip_address = publicIp;
        data.firebase_id = uuidV4.toString();
        await consumeApi(`users/create_user`, "POST", data);
      } else {
        //remove email from data
        delete data.email;
        await consumeApi(`users/update_user`, "POST", {
          user_id: userData?.id,
          fields_to_update: data,
        });
        const response = await consumeApi(`users/get_user_by_id`, "POST", {
          user_id: userData?.id,
        });
        let user = response.user_data;
        setUserData(user);
        goToStep(steps.successAction);
      }
    } catch (error) {
      console.warn("Error updating data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "user", value: username },
      { key: "email", value: formData.email },
      { key: "firstname", value: formData.firstname },
      { key: "lastname", value: formData.lastname },
      { key: "birthDate", value: birthDate },
      { key: "city", value: place },
    ];

    if (formData.firstname) {
      if (validations.name(formData.firstname)) {
        delete newErrors.firstname;
      } else {
        newErrors.firstname = i18n.t("badName");
      }
    }
    if (formData.lastname) {
      if (validations.name(formData.lastname)) {
        delete newErrors.lastname;
      } else {
        newErrors.lastname = i18n.t("badName");
      }
    }
    if (formData.phone_number) {
      if (validations.phone(formData.phone_number)) {
        delete newErrors.phone_number;
      } else {
        newErrors.phone_number = i18n.t("badPhone");
      }
    }
    if (formData.address) {
      if (validations.direction(formData.address)) {
        delete newErrors.address;
      } else {
        newErrors.address = i18n.t("badAddress");
      }
    }

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressSave(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {currentStep === steps.default && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              gap={4}
              flexGrow={1}
            >
              <ModalHeaderComponent
                title={i18n.t("yourProfile")}
                subtitle={i18n.t("updateYourProfile")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                actionButtonsRequired={true}
              />
              <ToggleQuestion
                title={i18n.t("automaticallyAcceptInvitations")}
                subTitle={i18n.t("you_will_be_automatically_added_to_a_team")}
                value={formData.automaticallyAcceptInvitations}
                onChange={() => {
                  setFormData((prev) => ({
                    ...prev,
                    automaticallyAcceptInvitations:
                      !prev.automaticallyAcceptInvitations,
                  }));
                }}
              />
              <Grid
                templateColumns="200px 1fr 1fr"
                gap={8}
                flexWrap={"wrap"}
                display={"flex"}
                flexGrow={1}
              >
                <GridItem
                  colSpan={1}
                  display="flex"
                  flexGrow={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("user")}
                      placeholder={i18n.t("user") + "..."}
                      defaultValue={username}
                      onChangeValue={(e) => console.log(e.target.value)}
                      isPassword={false}
                      isReadOnly={true}
                      errorMessage={errors.user}
                      showError={!!errors.user}
                      typeInput="text"
                    />
                    <CustomTextInput
                      textField={i18n.t("name")}
                      placeholder={i18n.t("name") + "..."}
                      defaultValue={formData.firstname}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          firstname: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, firstname: "" }));
                      }}
                      isPassword={false}
                      errorMessage={errors.firstname}
                      showError={!!errors.firstname}
                      typeInput="text"
                    />
                    <CustomTextInput
                      textField={i18n.t("lastName")}
                      placeholder={i18n.t("lastName") + "..."}
                      defaultValue={formData.lastname}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          lastname: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, lastname: "" }));
                      }}
                      isPassword={false}
                      errorMessage={errors.lastname}
                      showError={!!errors.lastname}
                      typeInput="text"
                    />
                    <SelectDate
                      error={errors.birthDate}
                      label={i18n.t("birthDate")}
                      placeholder={i18n.t("birthDate")}
                      required={true}
                      date={birthDate}
                      setDate={setBirthDate}
                    />
                  </Flex>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("email")}
                      placeholder={i18n.t("email") + "..."}
                      defaultValue={formData.email}
                      onChangeValue={(e) => console.log(e.target.value)}
                      isPassword={false}
                      isReadOnly={true}
                      errorMessage={errors.email}
                      showError={!!errors.email}
                      typeInput="email"
                    />
                    <CustomTextInput
                      textField={i18n.t("cellPhone")}
                      placeholder={i18n.t("cellPhone") + "..."}
                      defaultValue={formData.phone_number}
                      onChangeValue={(e) => {
                        const value = e.target.value;
                        if (/^[0-9+]*$/.test(value)) {
                          setFormData((prev) => ({
                            ...prev,
                            phone_number: value,
                          }));
                        }
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.phone_number}
                      showError={!!errors.phone_number}
                      typeInput="tel"
                    />
                    <CustomTextInput
                      textField={i18n.t("address")}
                      placeholder={i18n.t("address") + "..."}
                      defaultValue={formData.address}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.address}
                      showError={!!errors.address}
                      typeInput="text"
                    />
                    <CitySelector
                      error={errors.city}
                      place={place}
                      setPlace={setPlace}
                    />
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
          )}
          {currentStep === steps.successAction && (
            <MessageConfirmationComponent
              title={i18n.t("profileUpdated")}
              subtitle={i18n.t("completedAction")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default UpdateOrViewProfileData;
