import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { TypeViewToShow } from "../definitions/enums/GlobalEnums";
import CreateTeam from "../pages/tournaments/teams/CreateTeam";
import { appStore } from "../zustand/globalStore";
import CreateTournament from "../pages/tournaments/tournament/CreateTournament";
import CreateOrEditCupView from "../pages/tournaments/cups/CreateOrEditCupView";
import ShowDocumentsUserView from "../components/User/ShowDocumentsUserView";
import ShowSupportGroupView from "../components/User/ShowSupportGroupView";
import SureDeleteAccountView from "../components/User/SureDeleteAccountView";
import UpdateOrViewProfileData from "../components/User/UpdateOrViewProfileData";
import UpdateOrViewTournamentData from "../pages/tournaments/tournament/UpdateOrViewTournamentData";
import UpdateOrViewTeamData from "../pages/tournaments/teams/UpdateOrViewTeamData";
import Players from "./TournamentsOfTeamsOptions/Players/Players";
import CoachingStaff from "./TournamentsOfTeamsOptions/CoachingStaff/CoachingStaff";
import TeamSquad from "./TournamentsOfTeamsOptions/TeamSquad/TeamSquad";

const ModalContainerComponent = () => {
  const { currentView, isContainerModalOpen, hideContainerModal } = appStore(
    (state) => state
  );

  return (
    <Modal
      isOpen={isContainerModalOpen}
      onClose={() => {
        hideContainerModal();
      }}
      isCentered
    >
      <ModalOverlay
        bg="blackAlpha.200"
        backdropFilter="blur(3px)"
        // Quit this in case blur not pass design required
      />
      <ModalContent
        maxWidth="1000px"
        p={8}
        margin={{
          base: "auto 8px",
          sm: "auto 16px",
          md: "auto 24px",
          lg: "auto 32px",
        }}
        borderRadius="32px"
        height={"max-content"}
        minHeight={"650px"}
        maxHeight={"650px"}
        // overflow={"hidden"}
      >
        {/* <ModalHeader>{"title"}</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody display="flex" p={0} height="100%">
          {currentView === TypeViewToShow.CreateTeamView && <CreateTeam />}
          {currentView === TypeViewToShow.CreateTournamentView && (
            <CreateTournament />
          )}
          {currentView === TypeViewToShow.CreateOrEditCupView && (
            <CreateOrEditCupView />
          )}
          {currentView === TypeViewToShow.UpdateOrViewProfileData && (
            <UpdateOrViewProfileData />
          )}
          {currentView === TypeViewToShow.ShowDocumentsUserView && (
            <ShowDocumentsUserView />
          )}
          {currentView === TypeViewToShow.ShowSupportGroupView && (
            <ShowSupportGroupView />
          )}
          {currentView === TypeViewToShow.SureDeleteAccountView && (
            <SureDeleteAccountView />
          )}
          {currentView === TypeViewToShow.UpdateOrViewTournamentData && (
            <UpdateOrViewTournamentData />
          )}
          {currentView === TypeViewToShow.UpdateOrViewTeamData && (
            <UpdateOrViewTeamData />
          )}
          {currentView === TypeViewToShow.PlayersTournamentTeam && <Players />}
          {currentView === TypeViewToShow.CoachingStaff && <CoachingStaff />}
          {currentView === TypeViewToShow.TeamSquad && <TeamSquad />}
        </ModalBody>
        {/* {footerContent && <ModalFooter>{footerContent}</ModalFooter>} */}
      </ModalContent>
    </Modal>
  );
};
export default ModalContainerComponent;
