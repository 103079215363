import React from 'react'
import { Coup, ReactDispatchCoupState } from '../containers/Coups.interface'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import BodyText from '../../../../components/Common/BodyText'
import { colors } from '../../../../definitions/constants/GlobalStyles'
import i18n from '../../../../i18n/i18n'
import { ReactDispatchUserState } from '../../../../definitions/interfaces/Global/ReactDispatchStringState.type'
import { steps } from '../containers/steps.container'
import IconEdit from '../../../../assets/icons/IconEdit'
import IconTrash from '../../../../assets/icons/IconTrash'

const ItemCoup = ({ item, setItem, goStep }: { item: Coup, setItem: ReactDispatchCoupState, goStep: (key: string) => void }) => {
    return (
        <Flex key={item.id} sx={{ background: colors.backgrounds.gray0 }} padding={"15px"} borderRadius={"16px"} justifyContent={"space-between"} alignItems={"center"}>
            <Flex direction={"column"}>
                <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="semiBold"
                    textColor={colors.text.black}
                >
                    {item.name}
                </BodyText>
                <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="semiBold"
                    textColor={colors.figmaColors.secColor}
                >
                    {item.tournaments_count}  {i18n.t("tournaments")}
                </BodyText>
            </Flex>
            <Flex>
                <IconButton
                    aria-label="Edit player"
                    background={"transparent"}
                    className="edit-button"
                    onClick={() => {
                        setItem(item);
                        goStep(steps.editCup);
                    }}
                >
                    <IconEdit />
                </IconButton>
                <IconButton
                    aria-label="Delete player"
                    background={"transparent"}
                    onClick={() => {
                        setItem(item);
                        goStep(steps.deleteCup);
                    }}
                >
                    <IconTrash stroke={colors.backgrounds.red} />
                </IconButton>
            </Flex>
        </Flex>
    )
}

export default ItemCoup
