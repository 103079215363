import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import i18n from "../../../i18n/i18n";
import MatchInfoCard from "./MatchInfoCard";
import { MatchSelectedProps } from "../../../definitions/interfaces/GlobalInterfaces";
import { getCurrentTechnicalStaffFromTeam, getPlayersByTeamAndTournament } from "../../../models/TeamModel";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import ItemUser from "./ItemUser";

interface TeamLineupProps {
  teamName: string;
  players: User[] | null;
  managers: User[] | null;
  loading?: boolean;
}

const TeamLineup: React.FC<TeamLineupProps> = ({ teamName, players, managers, loading }) => {
  const groupedPlayers = {
    coach: managers?.filter((manager: User) => manager.rol === 'coach1') || [],
    goalkeepers: players?.filter(player => player.position === 'goalkeeper') || [],
    defense: players?.filter(player =>
      ['center_back', 'right_back', 'left_back', 'sweeper'].includes(player.position)
    ) || [],
    midfielders: players?.filter(player =>
      ['midfielder', 'defensive_midfielder', 'central_midfielder'].includes(player.position)
    ) || [],
    forwards: players?.filter(player =>
      ['right_winger', 'left_winger', 'forwards', 'striker', 'center_forward'].includes(player.position)
    ) || [],
  };
  return (
    <>
      <Flex
        p={4}
        gap={2}
        width={"100%"}
        borderRadius={16}
        direction="column"
        background={colors.figmaColors.appBgColor}
      >
        <BodyText
          fontSize={17}
          fontWeight="semiBold"
          textAlign="center"
          color={colors.text.black}
        >
          {i18n.t("lineup_of", { team: teamName })}
        </BodyText>
        {/* Mostrar entrenador */}
        <Flex direction="column">
          <BodyText fontSize={17} fontWeight="regular" textAlign="left" color={colors.text.black}>
            {i18n.t("coach")}
          </BodyText>
          {groupedPlayers.coach.map((manager) => (
            <ItemUser key={manager.id} item={manager} type="coach" />
          ))}
        </Flex>
        <Flex direction="column">
          <BodyText fontSize={17} fontWeight="regular" textAlign="left" color={colors.text.black}>
            {i18n.t("goalkeepers")}
          </BodyText>
          {groupedPlayers.goalkeepers.map((player) => (
            <ItemUser key={player.id} item={player} type="player" />
          ))}
        </Flex>
        <Flex direction="column">
          <BodyText fontSize={17} fontWeight="regular" textAlign="left" color={colors.text.black}>
            {i18n.t("defense")}
          </BodyText>
          {groupedPlayers.defense.map((player) => (
            <ItemUser key={player.id} item={player} type="player" />
          ))}
        </Flex>
        <Flex direction="column">
          <BodyText fontSize={17} fontWeight="regular" textAlign="left" color={colors.text.black}>
            {i18n.t("midfielders")}
          </BodyText>
          {groupedPlayers.midfielders.map((player) => (
            <ItemUser key={player.id} item={player} type="player" />
          ))}
        </Flex>
        <Flex direction="column">
          <BodyText fontSize={17} fontWeight="regular" textAlign="left" color={colors.text.black}>
            {i18n.t("forwards")}
          </BodyText>
          {groupedPlayers.forwards.map((player) => (
            <ItemUser key={player.id} item={player} type="player" />
          ))}
        </Flex>
      </Flex>
    </>
  );
}

const MatchLineups: React.FC<MatchSelectedProps> = ({
  firstTeam,
  secondTeam,
  tournament
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [playersFirstTeam, setPlayersFirstTeam] = useState<User[] | null>(null)
  const [playersSecondTeam, setPlayersSecondTeam] = useState<User[] | null>(null)
  const [managersFirstTeam, setManagersFirstTeam] = useState<User[] | null>(null)
  const [managersSecondTeam, setManagersSecondTeam] = useState<User[] | null>(null)
  useEffect(() => {
    (async () => {
      setLoading(true);
      const responseFirstTeam = await getPlayersByTeamAndTournament(
        firstTeam?.id,
        tournament?.id
      );
      const responseSecondTeam = await getPlayersByTeamAndTournament(
        secondTeam?.id,
        tournament?.id
      );
      setPlayersFirstTeam(responseFirstTeam);
      setPlayersSecondTeam(responseSecondTeam)
      setLoading(false);
    })();
  }, [tournament?.id, firstTeam?.id, secondTeam?.id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const technicalStaffRespFirstTeam = await getCurrentTechnicalStaffFromTeam(
        firstTeam?.id,
        tournament?.id
      );
      const technicalStaffRespSecondTeam = await getCurrentTechnicalStaffFromTeam(
        secondTeam?.id,
        tournament?.id
      );
      setManagersFirstTeam(technicalStaffRespFirstTeam);
      setManagersSecondTeam(technicalStaffRespSecondTeam)
      setLoading(false);
    })();
  }, [firstTeam?.id, tournament?.id, secondTeam?.id]);

  return (
    <>
      {loading ? <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin={"20px 0px"}
      >
        <Spinner size="lg" color="blue.500" />
      </Box> : <Flex gap={3}>
        <TeamLineup teamName={firstTeam.name} players={playersFirstTeam} managers={managersFirstTeam} loading={loading} />
        <TeamLineup teamName={secondTeam.name} players={playersSecondTeam} managers={managersSecondTeam} loading={loading} />
      </Flex>}

    </>
  );
};

export default MatchLineups;
