export enum Positions {
    CENTER_BACK = 'center_back',          // Defensa central
    RIGHT_BACK = 'right_back',            // Lateral derecho
    LEFT_BACK = 'left_back',              // Lateral izquierdo
    SWEEPER = 'sweeper',                  // Líbero
    MIDFIELDER = 'midfielder',            // Mediocampista
    DEFENSIVE_MIDFIELDER = 'defensive_midfielder', // Mediocentro defensivo
    CENTRAL_MIDFIELDER = 'central_midfielder',     // Centrocampista central
    RIGHT_WINGER = 'right_winger',        // Extremo derecho
    LEFT_WINGER = 'left_winger',          // Extremo izquierdo
    FORWARDS = 'forwards',                // Delanteros
    STRIKER = 'striker',                  // Delantero centro
    CENTER_FORWARD = 'center_forward'     // Mediapunta
  }