import BodyText from "./BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import { Flex } from "@chakra-ui/react";

const NoContentComponent = ({ textPrimary }) => {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <BodyText
        fontSize={{ base: "14px", md: "17px" }}
        fontWeight="medium"
        textColor={colors.text.black}
      >
        {textPrimary}
      </BodyText>
      <BodyText
        fontSize={{ base: "12px", md: "13px" }}
        fontWeight="regular"
        textColor={colors.text.lightGray3}
      >
        {i18n.t("stayReady")}
      </BodyText>
    </Flex>
  );
};

export default NoContentComponent;
