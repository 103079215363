//Container component for details match selected
import { Flex, Divider, Image, Avatar } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import ItemActionMatch from "../../../components/ItemActionMatch";
import { matchStore } from "../../../zustand/globalStore";
import { MatchRolUser } from "../../../definitions/enums/GlobalEnums";
import { MatchSelectedProps } from "../../../definitions/interfaces/GlobalInterfaces";
import i18n from "../../../i18n/i18n";

const MatchDetails: React.FC<MatchSelectedProps> = ({
  firstTeam,
  secondTeam,
  roles,
}) => {
  const { currentMatch, setIncidences, incidences } = matchStore(
    (state) => state
  );
  console.log({firstTeam,secondTeam} , "TEAMS MATCHES***************")
  return (
    <Flex gap={3} flexDirection={"column"}>
      <Flex
        p={"16px 8px"}
        gap={4}
        border="1px"
        borderRadius={16}
        alignItems={"center"}
        flexDirection={"column"}
        
        borderColor={colors.figmaColors.grey1}
      >
        <Flex
          gap={4}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Flex width={"100%"} justifyContent={"center"}>
            <Avatar
              boxSize={"40px"}
              src={firstTeam.media_url}
              name={`${firstTeam.name ?? ""} `}
            />
          </Flex>
          <BodyText
            fontSize={17}
            fontWeight="semiBold"
            textAlign="center"
            whiteSpace="nowrap"
            color={colors.text.lightGray3}
          >
            {currentMatch?.goals_first_team} {" - "} 
             {currentMatch?.goals_second_team}
          </BodyText>
          <Flex width={"100%"} justifyContent={"center"}>
            <Avatar
              boxSize={"40px"}
              src={secondTeam.media_url}
              name={`${secondTeam.name ?? ""} `}
            />
          </Flex>
        </Flex>
        <Divider />
        {incidences.length > 0 ? (
          incidences.map((incidence, index) => (
            <ItemActionMatch
              incidence={incidence}
              modify={
                (roles.includes(MatchRolUser.vocal) ||
                  roles.includes(MatchRolUser.own)) &&
                currentMatch?.status !== "finished"
                  ? true
                  : (roles.includes(MatchRolUser.referee) ||
                      roles.includes(MatchRolUser.own)) &&
                    !currentMatch?.validated_by_referee
                  ? true
                  : false
              }
              left={incidence.left}
              key={index}
              typeUser={"player"}
              setIncidences={setIncidences}
            />
          ))
        ) : (
          <BodyText size="medium" color={colors.text.black}>
            {i18n.t("no_incidences")}
          </BodyText>
        )}
      </Flex>
    </Flex>
  );
};

export default MatchDetails;
