import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import ImageFromText from "../../../components/Common/ImageFromText";
import { matchStore } from "../../../zustand/globalStore";
import { MatchRolUser } from "../../../definitions/enums/GlobalEnums";
import IconEdit from "../../../assets/icons/IconEdit";
import IconTrash from "../../../assets/icons/IconTrash";

const MatchHeader: React.FC<{
  league?: string;
  leagueImage?: string;
  phase?: string;
  status?: string;
  isRight?: boolean;
  roles?: any[];
  time?: string;
  editClick?: () => void;
  deleteClick?: () => void;
  isEditable: boolean;
}> = ({
  league,
  leagueImage,
  phase,
  status = "",
  isRight = false,
  roles = [],
  time = "",
  editClick,
  deleteClick,
  isEditable,
}) => {
  const { currentMatch } = matchStore((state) => state);

  const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  const getStatusBgColor = (status: string) => {
    return status.toLowerCase() === "playing"
      ? colors.backgrounds.green
      : colors.figmaColors.grey3;
  };

  const getStatusTextColor = (status: string) => {
    return status.toLowerCase() === "playing"
      ? colors.text.black
      : colors.text.white;
  };

  const shouldShowTime =
    currentMatch?.status !== "finished" &&
    (roles.includes(MatchRolUser.vocal) || roles.includes(MatchRolUser.user));

  const shouldShowStatus = currentMatch?.status !== "playing";

  const bgColor = getStatusBgColor(status);
  const textColor = shouldShowTime
    ? colors.text.black
    : getStatusTextColor(status);
  const textContent = shouldShowTime
    ? capitalizeFirstLetter(time)
    : capitalizeFirstLetter(status);

  return (
    <Flex
      gap={2.5}
      width="100%"
      alignItems={"center"}
      justifyContent={"center"}
    >
      {(league || leagueImage) && (
        <Flex gap={1.5} alignItems={"center"}>
          {leagueImage ? (
            <Image
              src={leagueImage}
              width="18px"
              height="16px"
              objectFit={"cover"}
            />
          ) : (
            <ImageFromText
              sizeIcon={{
                width: "18px",
                height: "16px",
                borderRadius: 0,
              }}
              sizeText="10px"
              text={league ?? ""}
            />
          )}
          <BodyText
            fontSize={13}
            fontWeight="semiBold"
            color={colors.text.black}
          >
            {league}:
          </BodyText>
          <BodyText
            fontSize={13}
            fontWeight="regular"
            color={colors.text.lightGray3}
          >
            {phase}
          </BodyText>
        </Flex>
      )}
      {isRight ? (
        <>
          {(shouldShowTime || shouldShowStatus) && (
            <StatusBadge
              bgColor={bgColor}
              textColor={textColor}
              text={textContent}
            />
          )}
        </>
      ) : (
        <Flex
          width="100%"
          justifyContent={isEditable ? "space-between" : "center"}
          alignItems="center"
        >
          {isEditable && (
            <StatusBadge
              bgColor="white"
              textColor="white"
              text={capitalizeFirstLetter(status)}
            />
          )}
          <StatusBadge
            bgColor={getStatusBgColor(status)}
            textColor={getStatusTextColor(status)}
            text={capitalizeFirstLetter(status)}
          />
          {isEditable && (
            <Flex gap={2.5}>
              <IconEdit
                stroke={colors.figmaColors.secColor}
                cursor="pointer"
                onClick={editClick}
              />
              <IconTrash
                stroke={colors.backgrounds.red}
                cursor="pointer"
                onClick={deleteClick}
              />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default MatchHeader;

const StatusBadge: React.FC<{
  bgColor: string;
  textColor: string;
  text: string;
}> = ({ bgColor, textColor, text }) => (
  <Flex
    width={"auto"}
    height={"auto"}
    p={"3px 8px"}
    borderRadius={10}
    bg={bgColor}
  >
    <BodyText fontSize={11} fontWeight="bold" color={textColor}>
      {text}
    </BodyText>
  </Flex>
);
