import { Box, Flex } from "@chakra-ui/react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { useEffect, useState } from "react";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import { getAllInvitationsManagerToTeam } from "../../../../../models/TeamModel";
import { appStore } from "../../../../../zustand/globalStore";
import ItemRequest from "../Managers/components/ItemRequest";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../../../components/Common/BodyText";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

const RequestsSent = ({
  goToStep,
}: {
  goToStep: (key: string) => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { currentTeam, hideContainerModal } = appStore((store) => store);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const findUsers = await getAllInvitationsManagerToTeam(currentTeam.id);
      setUsers(findUsers);
      setLoading(false);
    })();
  }, [currentTeam.id]);

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      {loading && <SpinnerComponent spinnerKey="modal" />}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={4}
        width="100%"
        height="100%"
      >
        <ModalHeaderComponent
          title={i18n.t("applicationSent")}
          subtitle={i18n.t("StartManagingTeam")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTeams.default)}
          isSaving={loading}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
        <Flex
          flexDirection="column"
          flexGrow={1}
          gap={4}
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {users.length > 0 ? (
            users.map((item) => <ItemRequest item={item} setUsers={setUsers} />)
          ) : (
            <BodyText
              fontSize={{ base: "11px", md: "12px" }}
              fontWeight="semiBold"
              textColor={colors.text.black}
            >
              {i18n.t("noData")}
            </BodyText>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default RequestsSent;
