import React, { useState } from "react";
import {
  Box,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import CustomMenuSelect from "../../Shared/CustomMenuSelect";
import { TypeFiles } from "../../../definitions/enums/GlobalEnums";
import ImageIdUploader from "../../Common/ImageIdUploader";
import {
  handleImageChange,
  uploadFileToAmazonS3Web,
} from "../../../utils/tools";
import { v4 as uuidV4 } from "uuid";
import { createFileUser } from "../../../models/UserModel";
import SpinnerComponent from "../../Common/SpinnerComponent";
import { InputErrorIcon } from "../../../definitions/constants/IconsComponent";
import CustomTextInput from "../../Common/CustomTextInput";

interface CreateDocumentBaseProps {
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditDocumentProps =
  | (CreateDocumentBaseProps & {
      isEdit: false;
      selectedDocument?: never;
    })
  | (CreateDocumentBaseProps & {
      isEdit: true;
      selectedDocument: { [key: string]: any };
    });

const CreateOrEditDocument: React.FC<CreateOrEditDocumentProps> = ({
  isEdit,
  steps,
  goToStep,
  currentStep,
  isSaving,
  setIsSaving,
  selectedDocument,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);
  const { userData, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedDocument.name : "",
  };
  const [formData, setFormData] = useState(dataForm);
  const [image, setImage] = useState(isEdit ? selectedDocument.media_url : "");
  const [typeDocument, setTypeDocument] = useState(
    isEdit ? selectedDocument.type : ""
  );

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const onPressSave = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      data.type = i18n.t(typeDocument);
      data.name = data.name.trim();

      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        data.media_url = response;
      } else if (image) {
        data.media_url = selectedDocument.media_url;
      }

      if (!isEdit) {
        data.id = uuidV4().toString();
      } else {
        data.id = selectedDocument.id;
      }

      await createFileUser(userData.id, data);
      if (isEdit) {
        goToStep(steps.successEdit);
      } else {
        goToStep(steps.successCreate);
      }
    } catch (error) {
      console.error("Error saving file or document:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "typeDocument",
        value: typeDocument,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressSave(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.editDocument
            : currentStep === steps.createDocument) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              <ModalHeaderComponent
                title={isEdit ? i18n.t("editDocument") : i18n.t("newDocument")}
                subtitle={i18n.t("signedByChampions")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={() => goToStep(steps.default)}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
              <FormControl
                gap={1}
                flexDirection={"column"}
                width="100%"
                isRequired={true}
                isInvalid={!!errors.typeDocument}
              >
                <FormLabel
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                  requiredIndicator={null}
                >
                  <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="semiBold"
                    paddingLeft={4}
                    textColor={colors.text.black}
                  >
                    {i18n.t("documentType")}
                  </BodyText>
                  <BodyText
                    fontSize={{ base: "12px", md: "13px" }}
                    fontWeight="regular"
                    textColor={colors.text.lightGray3}
                  >
                    {i18n.t("required")}
                  </BodyText>
                </FormLabel>
                <CustomMenuSelect
                  options={TypeFiles}
                  isInvalid={!!errors.typeDocument}
                  selectedDescription={typeDocument}
                  setSelectedDescription={setTypeDocument}
                />
                {errors.typeDocument && (
                  <FormErrorMessage color="red.500" gap="4px">
                    <InputErrorIcon />
                    <BodyText color="red.500">
                      {errors.typeDocument || i18n.t("input_error")}
                    </BodyText>
                  </FormErrorMessage>
                )}
              </FormControl>
              <CustomTextInput
                textField={i18n.t("description")}
                placeholder={i18n.t("description") + "..."}
                defaultValue={formData.name}
                onChangeValue={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                isRequired={false}
                isPassword={false}
                typeInput="text"
              />
              <FormControl
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ImageIdUploader
                  image={image}
                  width="350px"
                  isDocument={true}
                  onImageChange={handleInputImageChange}
                />
              </FormControl>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditDocument;
