import { Box, Checkbox, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ModalHeaderComponent from '../../../../components/Common/ModalHeaderComponent'
import i18n from '../../../../i18n/i18n'
import { steps } from '../containers/steps.container'
// import { getTournamentsByUserId } from '../containers/Coup.container'
import { appStore } from '../../../../zustand/globalStore'
import { Tournament } from '../../../../definitions/interfaces/Tournaments/Tournament.interface'
import ItemTournament from './ItemTournament'
import { getTournamentById, getTournamentsByUserId } from '../../../../models/Tournament'
import BodyText from '../../../../components/Common/BodyText'
import { colors } from '../../../../definitions/constants/GlobalStyles'
import { addTournamentsToCoup, getTournamentsByCoup } from '../containers/Coup.container'
import SpinnerComponent from '../../../../components/Common/SpinnerComponent'

const SelectTournaments = ({ hideContainerModal, goToStep, coup_id, isEdit }: {isEdit?:boolean; coup_id: string; hideContainerModal: any; goToStep: (key: string) => void }) => {
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const { userData } = appStore(store => store)
    const [tournaments, setTournaments] = useState<Tournament[]>([])
    const [selectTournaments, setSelectTournaments] = useState<string[]>([])

    const handleConfirm = async () => {
        setIsSaving(true)
        const response = await addTournamentsToCoup({ coup_id, tournament_ids: selectTournaments })
        setIsSaving(false)
        if(isEdit){
            goToStep(steps.successEdit)
        }else{
            goToStep(steps.successSelectTournaments)
        }
    }

    const handleSelectAll = () => {
        if (selectTournaments.length === tournaments.length) {
            setSelectTournaments([]);
        } else {
            setSelectTournaments(tournaments.map(tournament => tournament.id));
        }
    }
    useEffect(() => {
        (async () => {
            setIsSaving(true);
            const userTournaments = await getTournamentsByUserId(userData?.id);
            const loadedTournaments = [];
            const tournamentsCoups = await getTournamentsByCoup({ coup_id })
            if (tournamentsCoups.status === 'success') {
                if (tournamentsCoups.data.tournaments && tournamentsCoups.data.tournaments.length > 0) {
                    setSelectTournaments(tournamentsCoups.data.tournaments)
                }
            }
            for (const tournament of userTournaments) {
                const detailedTournament = await getTournamentById(
                    tournament.tournament_id
                );
                if (!detailedTournament.deleted) {
                    loadedTournaments.push({ ...tournament, ...detailedTournament });
                }
            }
            setTournaments(loadedTournaments);
            setIsSaving(false);
        })()
    }, [])

    return (
        <>
            {isSaving ? (
                <SpinnerComponent spinnerKey="modal" />
            ) : <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                gap={4}
                overflowY="auto"
                css={{
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none",
                }}
            >
                <ModalHeaderComponent
                    title={i18n.t("selectTournamentsOfCoups")}
                    subtitle={i18n.t("msgCreateCup")}
                    confirmText={i18n.t("continue")}
                    onCancel={hideContainerModal}
                    onConfirm={handleConfirm}
                    isSaving={isSaving}
                    onBack={() => goToStep(steps.default)}
                    hasMoreSteps={true}
                    actionButtonsRequired={true}
                />
                <Flex width={"100%"} justifyContent={"flex-end"}>
                    <Flex gap={2}>
                        <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.black}
                            textAlign="left"
                        >
                            {i18n.t("selectAll")}
                        </BodyText>
                        <Checkbox onChange={handleSelectAll} isChecked={selectTournaments.length === tournaments.length} />
                    </Flex>
                </Flex>
                <Flex display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    gap={2}
                    overflow={"scroll"}
                    height={"400px"}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",
                    }}>
                    {tournaments.length > 0 && tournaments.map((item, index) => {
                        return <ItemTournament item={item} goStep={goToStep} selectItems={setSelectTournaments} itemsActive={selectTournaments} />
                    })}
                </Flex>
            </Box>}
        </>

    )
}

export default SelectTournaments
