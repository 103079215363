import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Flex, Image } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import ImageFromText from "../../Common/ImageFromText";
import BodyText from "../../Common/BodyText";
import CountryFlag from "../../Common/CountryFlag";
import i18n from "../../../i18n/i18n";
import { CircleCheckIcon } from "../../../definitions/constants/IconsComponent";
import { Teams } from "../../../definitions/interfaces/Teams/Teams.interface";


const ItemTeam = ({
  item,
}:{item: Teams}) => {
  const [activeCardIndex, setActiveCardIndex] = useState<number>(-1);
  const [locality, setLocality] = useState(JSON.parse(item.locality || "{}"))
  return (
        <Flex
          key={item.id}
          align="center"
          borderRadius={20}
          padding={3}
          gap={2}
          border="1px solid"
          borderColor={colors.backgrounds.gray1}
          onClick={() => {
            
          }}
          cursor="pointer"
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap={2}>
            <Box
              display="flex"
              borderRadius="100%"
              minWidth="60px"
              border={ "none"}
              borderColor={"transparent" }
              justifyContent="center"
              alignItems="center"
              padding={"10px"}
              width="60px"
              height="60px"
            >
              <Avatar src={item.media_url} name={item.name}/>
            </Box>
            <Flex flexDirection="column" alignItems="flex-start">
              <BodyText
                size="small"
                fontWeight="regular"
                color={colors.text.black}
              >
                {item.name}
              </BodyText>
              <Flex alignItems="center" gap={1}>
                <CountryFlag countryName={locality.terms[1].value} />
                <BodyText
                  color={colors.text.lightGray3}
                  size="xxSmall"
                  fontWeight="regular"
                >
                  {locality.terms[1].value}
                </BodyText>
              </Flex>
            </Flex>
          </Flex>
          <Button
            borderRadius={10}
            gap={1}
            padding={"0px 8px"}
            height={"30px"}
            minWidth="fit-content"
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="13px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => {
       
            }}
          >
            {i18n.t("seeInformation")}
          </Button>
        </Flex>

  );
};

export default ItemTeam;
