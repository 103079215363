import React, { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  Flex,
} from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import i18n from "../../i18n/i18n";
import {
  consumeApi,
  handleImageChange,
  uploadFileToAmazonS3Web,
  createLocalityFromPlace,
} from "../../utils/tools";
import { createTournament, updateTournament } from "../../models/Tournament";
import ModalHeaderComponent from "../../components/Common/ModalHeaderComponent";
import PrivacySwitch from "../../components/Common/PrivacySwitch";
import CitySelector from "../../components/Common/CitySelector";
import ImageUploader from "../../components/Common/ImageUploader";
import MessageConfirmationComponent from "../../components/MessageConfirmationComponent";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";
import { validations } from "../../utils/validations";
import CustomTextInput from "../Common/CustomTextInput";
import SpinnerComponent from "../Common/SpinnerComponent";

const CreateOrEditTournament: React.FC<{
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isEdit, steps, goToStep, currentStep, isSaving, setIsSaving }) => {
  const [seasonName, setSeasonName] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<File | undefined>(undefined);

  const {
    currentTournament,
    hideContainerModal,
    userData,
    setCurrentTournament,
    tournaments,
    setTournaments,
  } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? currentTournament.name : "",
    address: isEdit ? currentTournament.address : "",
    phone_number: isEdit ? currentTournament.phone_number : "",
    email: isEdit ? currentTournament.email : "",
    location: isEdit ? currentTournament.location : "",
  };
  const [place, setPlace] = useState<PlaceDetails | null>(
    isEdit ? currentTournament.place : null
  );
  const [image, setImage] = useState(isEdit ? currentTournament.media_url : "");
  const [isPrivate, setIsPrivate] = useState(
    isEdit ? currentTournament.private : false
  );
  const [formData, setFormData] = useState(dataForm);

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const handleSwitchChange = () => {
    setIsPrivate(!isPrivate);
  };

  const onPressCreateOrEditTournament = async (data: any) => {
    if (isSaving) return;

    setIsSaving(true);

    try {
      const tournamentData = prepareTournamentData(data);

      if (media) {
        tournamentData.media_url = await uploadMedia(media);
      } else if (image) {
        tournamentData.media_url = currentTournament.media_url;
      }

      const newTournament = isEdit
        ? await updateExistingTournament(currentTournament.id, tournamentData)
        : await createNewTournament(tournamentData);

      data.user_type = "tournament";
      await consumeApi(
        `users/create_or_update_user_for_team_or_tournament`,
        "POST",
        tournamentData
      );

      updateTournamentsState(newTournament);
      navigateToNextStep(isEdit);
    } catch (error) {
      console.error("Error creating or updating tournament: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const prepareTournamentData = (data: any): any => {
    const preparedData = {
      ...data,
      user_id: userData.id,
      name: data.name.toUpperCase(),
      user_type: "tournament",
      private: isPrivate,
      id: isEdit ? currentTournament.id : undefined,
    };

    if (place) {
      preparedData.place =
        isEdit && place?.description === currentTournament?.place?.description
          ? currentTournament.place
          : createLocalityFromPlace(place);

      preparedData.place_id =
        isEdit && place?.place_id === currentTournament?.place?.place_id
          ? currentTournament.place?.place_id
          : createLocalityFromPlace(place).place_id;

      preparedData.place_description =
        isEdit && place?.description === currentTournament?.place?.description
          ? currentTournament.place?.description
          : createLocalityFromPlace(place).description;
    } else {
      delete preparedData.place;
      delete preparedData.place_id;
      delete preparedData.place_description;
    }

    return preparedData;
  };

  const uploadMedia = async (media: File): Promise<string> => {
    return uploadFileToAmazonS3Web(
      media,
      (percentage: number) => console.log(percentage),
      "tournaments"
    );
  };

  const createNewTournament = async (data: any) => {
    const newTournament = await createTournament(userData.id, data);
    setCurrentTournament(newTournament);
    const payload = {
      tournament_id: newTournament.id,
      season_name: seasonName.trim() || `${i18n.t("season")} 1`,
      season_year: new Date().getFullYear(),
      duplicate_regulations: false,
    };
    await consumeApi(`tournaments/set_season`, "POST", payload);
    return newTournament;
  };

  const updateExistingTournament = async (id: string, data: any) => {
    await updateTournament(id, data);
    return { ...data, id };
  };

  const updateTournamentsState = (newTournament: any) => {
    const updatedTournaments = isEdit
      ? tournaments.map((tournament) =>
          tournament.id === newTournament.id ? newTournament : tournament
        )
      : [newTournament, ...tournaments];

    setTournaments(updatedTournaments);
  };

  const navigateToNextStep = (isEdit: boolean) => {
    goToStep(isEdit ? steps.successEdit : steps.successScreen);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
    ];

    if (!isEdit) {
      fieldsToValidate.push({ key: "seasonName", value: seasonName });
    }
    if (formData.email) {
      if (validations.email(formData.email)) {
        delete newErrors.email;
      } else {
        newErrors.email = i18n.t("badEmail");
      }
    }
    if (formData.name) {
      if (validations.name(formData.name)) {
        delete newErrors.name;
      } else {
        newErrors.name = i18n.t("badName");
      }
    }
    if (formData.phone_number) {
      if (validations.phone(formData.phone_number)) {
        delete newErrors.phone_number;
      } else {
        newErrors.phone_number = i18n.t("badPhone");
      }
    }
    if (formData.address) {
      if (validations.direction(formData.address)) {
        delete newErrors.address;
      } else {
        newErrors.address = i18n.t("badAddress");
      }
    }

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditTournament(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.editTournament
            : currentStep === steps.default) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
              flexGrow={1}
            >
              {isEdit ? (
                <ModalHeaderComponent
                  title={i18n.t("yourTournament")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.default)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("titleTournamentTeam")}
                  subtitle={i18n.t("msgCreateTournament")}
                  confirmText={i18n.t("createTournament")}
                  onCancel={hideContainerModal}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  actionButtonsRequired={true}
                />
              )}
              <Grid
                templateColumns="200px 1fr 1fr"
                gap={8}
                flexWrap={"wrap"}
                display={"flex"}
                flexGrow={1}
              >
                <GridItem
                  colSpan={1}
                  display="flex"
                  flexGrow={1}
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <FormControl
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1} alignContent={"center"}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("nameTournament")}
                      placeholder={i18n.t("nameTournament") + "..."}
                      defaultValue={formData.name}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, name: "" }));
                      }}
                      isPassword={false}
                      errorMessage={errors.name}
                      showError={!!errors.name}
                      typeInput="text"
                    />
                    {!isEdit && (
                      <CustomTextInput
                        textField={i18n.t("seasonName")}
                        placeholder={i18n.t("seasonName") + "..."}
                        defaultValue={seasonName}
                        onChangeValue={(e) => setSeasonName(e.target.value)}
                        isPassword={false}
                        errorMessage={errors.seasonName}
                        showError={!!errors.seasonName}
                        typeInput="text"
                      />
                    )}
                    <CitySelector
                      error={errors.city}
                      place={place}
                      setPlace={setPlace}
                    />
                  </Flex>
                </GridItem>
                <GridItem colSpan={1} flexGrow={1} alignContent={"center"}>
                  <Flex gap={2.5} flexDirection={"column"}>
                    <CustomTextInput
                      textField={i18n.t("cellPhone")}
                      placeholder={i18n.t("cellPhone") + "..."}
                      defaultValue={formData.phone_number}
                      onChangeValue={(e) => {
                        const value = e.target.value;
                        if (/^[0-9+]*$/.test(value)) {
                          setFormData((prev) => ({
                            ...prev,
                            phone_number: value,
                          }));
                        }
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.phone_number}
                      showError={!!errors.phone_number}
                      typeInput="tel"
                    />
                    <CustomTextInput
                      textField={i18n.t("email")}
                      placeholder={i18n.t("email") + "..."}
                      defaultValue={formData.email}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.email}
                      showError={!!errors.email}
                      typeInput="email"
                    />
                    <CustomTextInput
                      textField={i18n.t("address")}
                      placeholder={i18n.t("address") + "..."}
                      defaultValue={formData.address}
                      onChangeValue={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                      isRequired={false}
                      isPassword={false}
                      errorMessage={errors.address}
                      showError={!!errors.address}
                      typeInput="text"
                    />
                  </Flex>
                </GridItem>
              </Grid>
              <PrivacySwitch
                isPrivate={isPrivate}
                isPrivateText={i18n.t("createTournamentPrivate")}
                onSwitchChange={handleSwitchChange}
              />
            </Box>
          )}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("tournamentReady")}
              subtitle={i18n.t("messageAlert")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditTournament;
