import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { appStore } from "../../../zustand/globalStore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import i18n from "../../../i18n/i18n";
import { consumeApi, trackEvent } from "../../../utils/tools";
import { colors } from "../../../definitions/constants/GlobalStyles";
import SpinnerComponent from "../../../components/Common/SpinnerComponent";

interface Team {
  team_name: string;
  match_played: number;
  count_wined: number;
  count_tie: number;
  count_lost: number;
  goals_for: number;
  goals_against: number;
  diference_goals: number;
  points: number;
}

interface StatisticsTeamsGroupProps {
  phase?: any;
  tournament?: any;
}

export default function StatisticsTeamsGroup({
  phase,
  tournament,
}: StatisticsTeamsGroupProps) {
  const { currentTournament } = appStore((state) => state);
  const [teams, setTeams] = useState<any[any]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    trackEvent("StatisticsTeamsGroup", {});
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (currentTournament?.is_external) {
      try {
        const season =
          currentTournament?.seasons.length > 0
            ? currentTournament.seasons[0].year
            : null;
        const data = await consumeApi(
          `tournaments/get_stadistics_for_tournament`,
          "POST",
          {
            tournament_id: currentTournament.id,
            season,
          }
        );
        setTeams(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      return;
    } else {
      try {
        const funtionB = httpsCallable(functions, "getEstadisticsByPhase");
        const result: any = await funtionB({
          tournamentId: currentTournament?.id,
          phaseId: phase?.id,
        });
        setTeams(result.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const TabTable = () => (
    <Flex direction="row" justify="space-around" py={3} px={2}>
      <Box width="6%" textAlign="left">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          #
        </Text>
      </Box>
      <Box width="38%" textAlign="left">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          {i18n.t("teams")}
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          PJ
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          PG
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          PE
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          PP
        </Text>
      </Box>
      {/* <Box width="6%" textAlign="center">
        <Text fontWeight="semibold" fontSize="sm" color="black">
          GF
        </Text>
      </Box>
      <Box width="6%" textAlign="center">
        <Text fontWeight="semibold" fontSize="sm" color="black">
          GC
        </Text>
      </Box>
      <Box width="6%" textAlign="center">
        <Text fontWeight="semibold" fontSize="sm" color="black">
          DG
        </Text>
      </Box> */}
      <Box width="10%" textAlign="center">
        <Text
          fontWeight="semibold"
          fontSize="13px"
          color={colors.figmaColors.grey3}
        >
          PTOS
        </Text>
      </Box>
    </Flex>
  );

  const DataItem = ({ team, index }: { team: Team; index: number }) => (
    <Flex
      direction="row"
      justify="space-around"
      py={1}
      px={1}
      align="center"
      wrap="wrap"
    >
      <Box width="6%" textAlign="center">
        <Box
          bg="green.500"
          width="20px"
          height="20px"
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontWeight="semibold"
            fontSize="13px"
            color="white"
            textAlign="left"
          >
            {index}
          </Text>
        </Box>
      </Box>
      <Box width="38%" textAlign="left">
        <Text fontWeight="600" fontSize="13px" color="black" isTruncated>
          {team.team_name}
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text fontWeight="600" fontSize="13px" color={colors.figmaColors.grey3}>
          {team.match_played}
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text fontWeight="600" fontSize="13px" color={colors.figmaColors.grey3}>
          {team.count_wined}
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text fontWeight="600" fontSize="13px" color={colors.figmaColors.grey3}>
          {team.count_tie}
        </Text>
      </Box>
      <Box width="3%" textAlign="center">
        <Text fontWeight="600" fontSize="13px" color={colors.figmaColors.grey3}>
          {team.count_lost}
        </Text>
      </Box>
      {/* <Box width="6%" textAlign="center">
        <Text fontWeight="regular" fontSize="xs" color="black">
          {team.goals_for}
        </Text>
      </Box>
      <Box width="6%" textAlign="center">
        <Text fontWeight="regular" fontSize="xs" color="black">
          {team.goals_against}
        </Text>
      </Box>
      <Box width="6%" textAlign="center">
        <Text fontWeight="regular" fontSize="xs" color="black">
          {team.diference_goals}
        </Text>
      </Box> */}
      <Box width="10%" textAlign="center">
        <Text fontWeight="600" fontSize="13px" color={colors.figmaColors.grey3}>
          {team.points}
        </Text>
      </Box>
    </Flex>
  );

  if (loading) {
    return <SpinnerComponent spinnerKey="detail" />;
  }

  return (
    <Box>
      {teams?.have_groups ? (
        <>
          {teams?.data?.map((group: any, index: number) => (
            <Box key={index} mt={4}>
              <Text
                fontWeight="semibold"
                fontSize="sm"
                color="black"
                textAlign="center"
              >
                {group.name}
              </Text>
              <Box
                bg={colors.figmaColors.grey0}
                borderWidth={1}
                borderRadius="16px"
                borderColor={colors.figmaColors.grey1}
                p={4}
                mt={2}
              >
                <TabTable />
                {group.teams.map((team: Team, index: number) => (
                  <DataItem key={index} team={team} index={index + 1} />
                ))}
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box
          bg="gray.50"
          borderWidth={1}
          borderRadius={20}
          borderColor="gray.200"
          bgColor={colors.backgrounds.gray1}
          p={4}
        >
          <TabTable />
          {teams?.data?.map((team: Team, index: number) => (
            <DataItem key={index} team={team} index={index + 1} />
          ))}
        </Box>
      )}
    </Box>
  );
}
