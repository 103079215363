import React from "react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { Flex, FlexProps } from "@chakra-ui/react";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";
import BodyText from "../../../../../components/Common/BodyText";
import IconEdit from "../../../../../assets/icons/IconEdit";
import IconTrash from "../../../../../assets/icons/IconTrash";
import NoContentComponent from "../../../../../components/Common/NoContentComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import { appStore } from "../../../../../zustand/globalStore";

interface ModalitiesBaseProps {
  goToStep: (key: string) => void;
  isSaving: boolean;
  phases: any[];
  setSelectedPhase: React.Dispatch<React.SetStateAction<any>>;
}

const ShowModalities: React.FC<ModalitiesBaseProps> = ({
  goToStep,
  isSaving,
  phases,
  setSelectedPhase,
}) => {
  const { hideContainerModal } = appStore((state) => state);

  return (
    <>
      <ModalHeaderComponent
        title={i18n.t("modality")}
        subtitle={i18n.t("readyTournament")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(stepsTournaments.optionsSeason)}
        isSaving={isSaving}
        hasMoreSteps={true}
        actionButtonsRequired={false}
      />
      <Flex {...flexProps} height="100%" width="100%">
        <Flex
          padding="0px 16px"
          borderRadius={16}
          gap={3}
          height="60px"
          alignItems="center"
          cursor="pointer"
          justifyContent="center"
          backgroundColor={colors.backgrounds.gray0}
          onClick={() => goToStep(stepsTournaments.newPhase)}
        >
          <IconAddSquare stroke={colors.text.lightGray3} />
          <BodyText
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="medium"
            textColor={colors.text.lightGray3}
          >
            {i18n.t("createNewPhase")}
          </BodyText>
        </Flex>
        <Flex
          flexDirection="column"
          gap={4}
          flexGrow={1}
          justifyContent={phases.length === 0 ? "center" : "flex-start"}
          alignItems={phases.length === 0 ? "center" : "flex-end"}
        >
          {phases.length > 0 ? (
            phases.map((phase) => (
              <Flex
                padding="0px 16px"
                borderRadius={16}
                gap={3}
                height="60px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid"
                borderColor={colors.backgrounds.gray1}
              >
                <Flex direction="column">
                  <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="medium"
                    textColor={colors.text.black}
                  >
                    {phase.name}
                  </BodyText>
                </Flex>
                <Flex gap={3}>
                  <IconEdit
                    stroke={colors.figmaColors.secColor}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedPhase(phase);
                      goToStep(stepsTournaments.editPhase);
                    }}
                  />
                  <IconTrash
                    stroke={colors.backgrounds.red}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedPhase(phase);
                      goToStep(stepsTournaments.deletePhase);
                    }}
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <NoContentComponent textPrimary={i18n.t("phasesNotLoaded")} />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ShowModalities;

const flexProps: FlexProps = {
  flexDirection: "column",
  gap: 4,
  overflowY: "auto",
  css: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
};
