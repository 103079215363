import React, { useState, useEffect, useCallback } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../Common/BodyText";
import IconEdit from "../../assets/icons/IconEdit";
import i18n from "../../i18n/i18n";
import IconAddSquare from "../../assets/icons/IconAddSquare";
import IconTrash from "../../assets/icons/IconTrash";
import HeaderDefaultOptions from "../Common/HeaderDefaultOptions";
import { getFilesUser } from "../../models/UserModel";
import MessageConfirmationComponent from "../MessageConfirmationComponent";
import CreateOrEditDocument from "./Documents/CreateOrEditDocument";
import DeleteDocument from "./Documents/DeleteDocument";
import { stepsDocuments } from "../../definitions/constants/GlobalConstants";

function ShowDocumentsUserView() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentStep, setCurrentStep] = useState(stepsDocuments.default);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const { userData, hideContainerModal } = appStore((state) => state);

  const fetchFiles = useCallback(async () => {
    try {
      setLoading(true);
      const files = await getFilesUser(userData.id);
      setFiles(files);
    } catch (error) {
      console.error("Error fetching files: ", error);
    } finally {
      setLoading(false);
    }
  }, [userData.id, setFiles]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={currentStep === stepsDocuments.deleteDocument ? 8 : 4}
      width="100%"
      height="100%"
    >
      {currentStep === stepsDocuments.default && (
        <HeaderDefaultOptions
          title={i18n.t("documents")}
          description={i18n.t("signedByChampions")}
        />
      )}
      {currentStep === stepsDocuments.default && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <Flex
            padding="0px 16px"
            borderRadius={16}
            gap={3}
            height="60px"
            alignItems="center"
            cursor="pointer"
            justifyContent="center"
            backgroundColor={colors.backgrounds.gray0}
            onClick={() => goToStep(stepsDocuments.createDocument)}
          >
            <IconAddSquare stroke={colors.text.lightGray3} />
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("newDocument")}
            </BodyText>
          </Flex>
          {files.length > 0 ? (
            files.map((file) => (
              <Flex
                padding="0px 16px"
                borderRadius={16}
                gap={3}
                height="60px"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid"
                borderColor={colors.backgrounds.gray1}
              >
                <Flex direction="column">
                  <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="medium"
                    textColor={colors.text.black}
                  >
                    {file.name}
                  </BodyText>
                  <BodyText
                    fontSize={{ base: "11px", md: "12px" }}
                    fontWeight="regular"
                    textColor={colors.text.lightGray3}
                  >
                    {file.type}
                  </BodyText>
                </Flex>
                <Flex gap={3}>
                  <IconEdit
                    stroke={colors.figmaColors.secColor}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedFile(file);
                      goToStep(stepsDocuments.editDocument);
                    }}
                  />
                  <IconTrash
                    stroke={colors.backgrounds.red}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedFile(file);
                      goToStep(stepsDocuments.deleteDocument);
                    }}
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <BodyText
              fontSize={{ base: "11px", md: "12px" }}
              fontWeight="semiBold"
              textColor={colors.text.black}
            >
              {i18n.t("noData")}
            </BodyText>
          )}
        </Flex>
      )}
      {currentStep === stepsDocuments.createDocument && (
        <CreateOrEditDocument
          isEdit={false}
          steps={stepsDocuments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={loading}
          setIsSaving={setLoading}
        />
      )}
      {currentStep === stepsDocuments.successCreate && (
        <MessageConfirmationComponent
          title={i18n.t("documentAdded")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsDocuments.editDocument && (
        <CreateOrEditDocument
          isEdit={true}
          steps={stepsDocuments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={loading}
          setIsSaving={setLoading}
          selectedDocument={selectedFile}
        />
      )}
      {currentStep === stepsDocuments.successEdit && (
        <MessageConfirmationComponent
          title={i18n.t("documentEdited")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsDocuments.deleteDocument && (
        <DeleteDocument goToStep={goToStep} selectedDocument={selectedFile} />
      )}
      {currentStep === stepsDocuments.successAction && (
        <MessageConfirmationComponent
          title={i18n.t("documentDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
    </Box>
  );
}

export default ShowDocumentsUserView;
