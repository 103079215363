import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { appStore } from "../../../zustand/globalStore";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import MessageConfirmationComponent from "../../../components/MessageConfirmationComponent";
import SpinnerComponent from "../../../components/Common/SpinnerComponent";
import { steps } from "./containers/steps.container";
import CreateAndEditCoup from "./components/CreateAndEditCoup";
import { EditCoup, ListCoups } from "./containers/Coup.container";
import { Coup, ReactDispatchCoupState } from "./containers/Coups.interface";
import ItemCoup from "./components/ItemCoup";
import DeleteContentModal from "../../../components/Modals/DeleteContentModal";
import SelectTournaments from "./components/SelectTournaments";


const CreateOrEditCupView = () => {
  const dataForm = {};
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(dataForm);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentStep, setCurrentStep] = useState(steps.default);
  const [currentCoups, setCurrentCoups] = useState<Coup[]>([])
  const [currentCoup, setCurrentCoup] = useState<Coup>(null)
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const { hideContainerModal, userData } = appStore((state) => state);

  useEffect(() => {

    (async () => {
      setIsSaving(true)
      const response = await ListCoups({ user_id: userData?.id })
      console.log(response)
      if (response.status === "success") {
        setCurrentCoups(response.data)
        setIsSaving(false)
      } else {
        setCurrentCoups([])
        setIsSaving(false)
      }
    })()
  }, [])

  const handleDeleteCoup = async () => {
    setIsSaving(true)
    await EditCoup({ coup_id: currentCoup?.id, is_deleted: true })
    setIsSaving(false)
    goToStep(steps.successDelete)
  }
  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={currentStep === steps.deleteCup ? 8 : 4}
          overflowY="auto"

          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {currentStep === steps.default && (
            <ModalHeaderComponent
              title={i18n.t("titleCup")}
              subtitle={i18n.t("msgCreateCup")}
              confirmText={i18n.t("createCup")}
              onCancel={hideContainerModal}
              onConfirm={() => goToStep(steps.newCup)}
              isSaving={isSaving}
              actionButtonsRequired={true}
            />
          )}
          {currentStep === steps.default && <Flex display="flex"
            flexDirection="column"
            flexGrow={1}
            gap={2}
            overflow={"scroll"}
            height={"400px"}
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}>
            {currentCoups.length > 0 && currentCoups.map((item, index) => <ItemCoup item={item} goStep={goToStep} setItem={setCurrentCoup} />)}
          </Flex>}
          {currentStep === steps.newCup && <CreateAndEditCoup type="create" goToStep={goToStep} hideContainerModal={hideContainerModal} />}
          {currentStep === steps.editCup && <CreateAndEditCoup type="edit" goToStep={goToStep} hideContainerModal={hideContainerModal} currentCoup={currentCoup} />}
          {currentStep === steps.deleteCup && <DeleteContentModal
            message={i18n.t("sureDeleteCoup", {
              manager: `${currentCoup?.name}`,
            })}
            onCancel={hideContainerModal}
            onConfirm={handleDeleteCoup}
            loading={isSaving}
          />}
          {currentStep === steps.successDelete && <MessageConfirmationComponent
            title={i18n.t("coupDeleted")}
            subtitle={i18n.t("operationCompleted")}
            isDelete={true}
            onClick={hideContainerModal}
          />}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("cupReady")}
              subtitle={i18n.t("operationCompleted")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
          {currentStep === steps.selectTournaments && <SelectTournaments goToStep={goToStep} hideContainerModal={hideContainerModal} coup_id={currentCoup?.id}/>}
          {currentStep === steps.selectEditTournaments && <SelectTournaments isEdit={true} goToStep={goToStep} hideContainerModal={hideContainerModal} coup_id={currentCoup?.id}/>}
          {currentStep === steps.successSelectTournaments && <MessageConfirmationComponent
            title={i18n.t("yourCoupIsReady") + "🎉"}
            subtitle={i18n.t("operationCompleted")}
            isDelete={false}
            onClick={hideContainerModal}
          />}
          {currentStep === steps.successEdit && <MessageConfirmationComponent
            title={i18n.t("yourCoupIsEdited") + "🎉"}
            subtitle={i18n.t("operationCompleted")}
            isDelete={false}
            onClick={hideContainerModal}
          /> }
        </Box>
      )}
    </>
  );
};

export default CreateOrEditCupView;
