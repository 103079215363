import { consumeApi } from "../../../../utils/tools";

export const CreateCoup = async ({name, user_id}:{name:string; user_id: string}) => {
    try{
        const newCoup = await consumeApi("tournaments/coups", "POST", {name, user_id})
        console.log(newCoup)
    }catch(error){
        console.error(error)
    }
}

export const EditCoup = async ({name, coup_id, is_deleted}:{name?:string; coup_id: string; is_deleted: boolean}) => {
    try{
        const editCoup = await consumeApi("tournaments/coups/"+coup_id, "POST", {name, is_deleted })
        console.log(editCoup)
    }catch(error){
        console.error(error)
    }
}

export const ListCoups = async ({user_id}:{user_id:string}) => {
    try{
        const coups = await consumeApi("tournaments/coups/list_coups", "POST", { user_id})
        if(coups.status === "success"){
            return coups
        }else{
            return []
        }
    }catch(error){
        console.error(error)
    }
}

export const getTournamentsByUserId = async ({user_id}:{user_id:string}) => {
    try{
        const tournaments = await consumeApi("tournaments/get_tournaments_by_user_id", "POST", { user_id})
        console.log(tournaments)
        return tournaments
    }catch(error){
        console.error(error)
    }
}

export const addTournamentsToCoup = async ({coup_id, tournament_ids}:{coup_id:string; tournament_ids: string[]}) => {
    try{
        const response = await consumeApi(`/tournaments/coups/${coup_id}/tournaments`, "POST", {tournament_ids})
        console.log(response, "RESPONSE ADD TOURNAMENTS COUPS")
    }catch(error){
        console.error(error)
    }
}

export const getTournamentsByCoup = async ({coup_id}:{coup_id:string}) => {
    try{
        const response = await consumeApi(`tournaments/coups/${coup_id}`, "GET")
        console.log(response, "tournaments of coup")
        return response
    }catch(error){
        console.error(error)
    }
}

