import { Avatar, Box, Stack, Text } from "@chakra-ui/react";
import { getMatchStatusSpanish } from "../definitions/constants/GlobalConstants";
import i18n from "../i18n/i18n";
import { colors } from "../definitions/constants/GlobalStyles";
import "./styles/MatchItem.style.scss";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

interface ItemSelectSelectProps {
  onClick: () => void;
  match: any;
}

export default function MatchItem({ onClick, match }: ItemSelectSelectProps) {
  const [matchDate, setMatchDate] = useState({
    day: null,
    number_day: null,
    month: null,
    hour: null,
    minuts: null,
  });

  useEffect(() => {
    if (match?.match_date) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const matchTime = moment.tz(match.match_date, userTimeZone);
      setMatchDate({
        day: matchTime.format("dddd"),
        number_day: matchTime.date(),
        month: matchTime.format("MMMM"),
        hour: matchTime.format("HH"),
        minuts: matchTime.format("mm"),
      });
    }
  }, [match]);

  return (
    <Box
      // onClick={onClick}
      onClick={onClick}
      cursor="pointer"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="20px"
      p={5}
      mb={4}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        flexDirection="row"
        justifyContent="space-between"
      ></Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {match.status && (
          <Box
            display="flex"
            px={2}
            py={1}
            justifyContent="center"
            alignItems="center"
            borderRadius="10px"
            backgroundColor={colors.text.lightGray3}
          >
            <Text fontWeight="700" color="white" fontSize="11px">
              {getMatchStatusSpanish(match.status) ?? ""}
            </Text>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={4}
        flexDirection="row"
        justifyContent={"center"}
        width={"100%"}
      >
        <Stack alignItems="center" gap={2} flexDirection="row">
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="center"
            alignItems="center"
            gap={"10px"}
            sx={{ padding: "10px" }}
          >
            <Avatar
              src={match?.first_team_media_url}
              name={match?.first_team_name ?? ""}
            />
            <Text
              color={colors.text.black}
              fontSize="14px"
              fontWeight="600"
              className="text-name"
            >
              {match?.first_team_name ?? ""}
            </Text>
          </Box>
          <Box
            display="flex"
            width="50px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
            backgroundColor="white"
          >
            <Text
              color={colors.text.lightGray3}
              fontSize="17px"
              fontWeight="400"
            >
              {match?.goals_first_team ?? ""}
            </Text>
          </Box>
        </Stack>
        <Text fontWeight="semibold" color="black" fontSize="lg">
          -
        </Text>
        <Stack alignItems="center" gap={2} flexDirection="row">
          <Box
            display="flex"
            width="50px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
            backgroundColor="white"
          >
            <Text
              color={colors.text.lightGray3}
              fontSize="17px"
              fontWeight="400"
            >
              {match.goals_second_team ?? ""}
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={"10px"}
            sx={{ padding: "10px" }}
          >
            <Avatar src={match.second_team_url} name={match.second_team_name} />
            <Text
              color={colors.text.black}
              fontSize="14px"
              fontWeight="600"
              className="text-name"
            >
              {match.second_team_name ?? ""}
            </Text>
          </Box>
        </Stack>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          flexDirection="row"
          mb={2}
        >
          <Text color={colors.text.lightGray3} fontSize="13px" className="text">
            {match?.day
              ? `${i18n.t("date")}: ${i18n.t(matchDate.day)}/${
                  matchDate.number_day
                } ${i18n.t(matchDate.month)}`
              : `${i18n.t("date")}: ${i18n.t("undefined")}`}
          </Text>

          <Text color={colors.text.lightGray3} fontSize="13px">
            {match?.hour
              ? `${i18n.t("hour")}: ${matchDate.hour}:${matchDate.minuts}`
              : `${i18n.t("hour")}: ${i18n.t("undefined")}`}
          </Text>
        </Box>
        <Text color={colors.text.lightGray3} fontSize="13px">
          {match?.field_name
            ? `${i18n.t("place")}: ${match.field_name}`
            : `${i18n.t("place")}: ${i18n.t("undefined")}`}
        </Text>
      </Box>
    </Box>
  );
}
