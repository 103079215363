import React, { useEffect, useState } from "react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { Box, Checkbox, Flex } from "@chakra-ui/react";
import {
  assignTeamToGroupOrPhase,
  getTeamsAvailableByPhase,
  getTeamsDetailsFromTournamentByGroup,
  getTeamsDetailsFromTournamentByPhase,
} from "../../../../../models/Tournament";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../../../components/Common/BodyText";
import ItemInviteTeamSelection from "../../../../../components/Common/ItemInviteTeamSelection";
import NoContentComponent from "../../../../../components/Common/NoContentComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

interface TeamSelectionProps {
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPhase: { [key: string]: any };
  selectedGroup: { [key: string]: any };
}

const TeamGroupPhaseSelection: React.FC<TeamSelectionProps> = ({
  goToStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  selectedGroup,
}) => {
  const [teams, setTeams] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  useEffect(() => {
    const loadTeams = async () => {
      try {
        setIsSaving(true);

        const availableTeams = await getTeamsAvailableByPhase(
          currentTournament.id,
          selectedPhase.id
        );

        const formattedAvailableTeams = availableTeams.map((team) => ({
          ...team,
          selected: false,
        }));

        const selectedTeams = selectedPhase.have_groups
          ? await getTeamsDetailsFromTournamentByGroup(
              currentTournament.id,
              selectedPhase.id,
              selectedGroup.id
            )
          : await getTeamsDetailsFromTournamentByPhase(
              currentTournament.id,
              selectedPhase.id
            );

        const formattedSelectedTeams = selectedTeams.map((team) => ({
          ...team,
          selected: true,
        }));

        const allTeams = [
          ...formattedSelectedTeams,
          ...formattedAvailableTeams,
        ];

        setTeams(allTeams);
        const allSelected = allTeams.every((team) => team.selected);
        setSelectAll(allSelected);
      } catch (error) {
        console.error("Error loading teams: ", error);
      } finally {
        setIsSaving(false);
      }
    };

    loadTeams();
  }, [
    currentTournament?.id,
    selectedPhase?.id,
    selectedPhase?.have_groups,
    selectedGroup?.id,
    setIsSaving,
  ]);

  useEffect(() => {
    const allSelected =
      teams.length > 0 && teams.every((team) => team.selected);
    setSelectAll(allSelected);
  }, [teams]);

  const onSelectTeam = (teamSelected: any) => {
    const updatedTeams = teams.map((team) =>
      team.id === teamSelected.id ? { ...team, selected: !team.selected } : team
    );
    setTeams(updatedTeams);

    const allSelected = updatedTeams.every((team) => team.selected);
    setSelectAll(allSelected);
  };

  const onToggleSelectAll = () => {
    const newSelectAllState = !selectAll;
    const updatedTeams = teams.map((team) => ({
      ...team,
      selected: newSelectAllState,
    }));
    setTeams(updatedTeams);
    setSelectAll(newSelectAllState);
  };

  const processInvitation = async () => {
    const selectedTeams = teams.filter((team) => team.selected);

    setIsSaving(true);
    await assignTeamToGroupOrPhase(
      selectedTeams,
      currentTournament,
      selectedPhase,
      selectedGroup
    );
    setIsSaving(false);
    goToStep(stepsTournaments.successTeamsSelection);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    processInvitation();
  };

  if (isSaving) {
    <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      gap={4}
      width="100%"
      height="100%"
    >
      <ModalHeaderComponent
        title={i18n.t("teamSelection", {
          name: selectedGroup ? selectedGroup.name : selectedPhase.name,
        })}
        subtitle={i18n.t("readyTournament")}
        confirmText={i18n.t("continue")}
        onCancel={hideContainerModal}
        onBack={() =>
          goToStep(
            selectedGroup
              ? stepsTournaments.groupsSelection
              : stepsTournaments.selectTeams
          )
        }
        onConfirm={handleSubmit}
        isSaving={isSaving}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      <Flex
        flexDirection="column"
        gap={4}
        flexGrow={1}
        justifyContent={teams.length === 0 ? "center" : "flex-start"}
        alignItems={teams.length === 0 ? "center" : "flex-end"}
      >
        {teams.length > 0 && (
          <Flex gap={2} padding="8px 12px">
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("selectAll")}
            </BodyText>
            <Checkbox
              isChecked={selectAll}
              onChange={onToggleSelectAll}
              sx={{
                "& .chakra-checkbox__control": {
                  boxSize: "20px",
                  borderRadius: "5px",
                  border: `1px solid ${colors.figmaColors.secColor}`,
                  bg: selectAll
                    ? colors.figmaColors.secColor
                    : colors.text.white,
                },
              }}
            />
          </Flex>
        )}
        {teams.length > 0 ? (
          teams.map((team) => (
            <ItemInviteTeamSelection
              key={team.id}
              item={team}
              selectedItem={team.selected ? team : null}
              setSelectedItem={() => onSelectTeam(team)}
            />
          ))
        ) : (
          <NoContentComponent textPrimary={i18n.t("teamsNotLoaded")} />
        )}
      </Flex>
    </Box>
  );
};

export default TeamGroupPhaseSelection;
