import React from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Flex,
} from "@chakra-ui/react";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import i18n from "../../../../../i18n/i18n";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import IncrementDecrementInput from "../../../../../components/Common/IncrementDecrementInput";
import SelectDateAndTime from "../../../../../components/Common/SelectDateAndTime";
import { updateTournament } from "../../../../../models/Tournament";
import PrivacySwitch from "../../../../../components/Common/PrivacySwitch";
import useTournamentRegulations from "../../../../../hooks/useRegulations";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

const TournamentRegulations: React.FC<{
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ goToStep, isSaving, setIsSaving }) => {
  const { currentTournament, hideContainerModal } = appStore((state) => state);
  const { formData, setFormData } = useTournamentRegulations(
    currentTournament.id
  );

  const handleSwitchChange = () => {
    setFormData((prev) => ({
      ...prev,
      isRequiredForm: !prev.isRequiredForm,
    }));
  };

  const onPressRegulations = async () => {
    if (isSaving) return;

    setIsSaving(true);
    try {
      await updateTournament(currentTournament.id, {
        limit_date_inscription: formData.limitDateInscription,
        number_players_on_field: formData.numberPlayersOnField,
        number_changes: formData.numberChanges,
        extra_time_minuts: formData.extraTimeMinutes,
        number_penalties: formData.numberPenalties,
        minuts_first_time: formData.minutesFirstTime,
        minuts_second_time: formData.minutesSecondTime,
        max_time_send_template_minuts: formData.maxTimeSendTemplateMinutes,
        required_form: formData.isRequiredForm,
      });
      goToStep(stepsTournaments.successSaveRegulations);
    } catch (error) {
      console.error("Error updating regulations: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <Box
          as="form"
          onSubmit={onPressRegulations}
          display="flex"
          gap={4}
          flexGrow={1}
          flexDirection="column"
        >
          <ModalHeaderComponent
            title={i18n.t("regulations")}
            subtitle={i18n.t("readyTournament")}
            confirmText={i18n.t("save")}
            onCancel={hideContainerModal}
            onBack={() => goToStep(stepsTournaments.optionsSeason)}
            onConfirm={onPressRegulations}
            isSaving={isSaving}
            hasMoreSteps={true}
            actionButtonsRequired={true}
          />
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            <Grid templateColumns="1fr 1fr" gap={4}>
              <GridItem colSpan={1}>
                <FormControl>
                  <Flex gap={4} flexDirection={"column"}>
                    <BodyText
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="semiBold"
                      textAlign="center"
                      textColor={colors.figmaColors.secColor}
                    >
                      {i18n.t("forTeams")}
                    </BodyText>
                    <SelectDateAndTime
                      date={formData.limitDateInscription}
                      setDate={(date: any) =>
                        setFormData((prev) => ({
                          ...prev,
                          limitDateInscription: date,
                        }))
                      }
                      placeholderDate={i18n.t("selectDate")}
                      placeholderTime={i18n.t("selectHour")}
                    />
                    <Flex gap={1} flexDirection={"column"}>
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("soccerFormDeliveryTime")} ({i18n.t("min")})
                      </FormLabel>
                      <Flex gap={2.5} padding="0 16px" alignItems="center">
                        <IncrementDecrementInput
                          value={formData.maxTimeSendTemplateMinutes}
                          setValue={(value) =>
                            setFormData((prev) => ({
                              ...prev,
                              maxTimeSendTemplateMinutes: value,
                            }))
                          }
                        />
                        <Flex
                          padding={4}
                          height="60px"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius={20}
                          backgroundColor={colors.backgrounds.gray0}
                          flex={1}
                        >
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textAlign="center"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("beforeGameTime")}
                          </BodyText>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <Flex gap={4} flexDirection={"column"}>
                    <BodyText
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="semiBold"
                      textAlign="center"
                      textColor={colors.figmaColors.secColor}
                    >
                      {i18n.t("forMatch")}
                    </BodyText>
                    <Flex gap={1} flexDirection={"column"}>
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("numberAllowedMatch")}
                      </FormLabel>
                      <Flex gap={2.5} padding="0 16px" alignItems="center">
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("numberPlayers")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.numberPlayersOnField}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                numberPlayersOnField: value,
                              }))
                            }
                          />
                        </Flex>
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("numberSubstitutions")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.numberChanges}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                numberChanges: value,
                              }))
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("matchDuration")} ({i18n.t("min")})
                      </FormLabel>
                      <Flex gap={2.5} padding="0 16px" alignItems="center">
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("firstHalf")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.minutesFirstTime}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                minutesFirstTime: value,
                              }))
                            }
                          />
                        </Flex>
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("secondHalf")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.minutesSecondTime}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                minutesSecondTime: value,
                              }))
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap={1} flexDirection={"column"}>
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("plaOffsSettings")} ({i18n.t("min")})
                      </FormLabel>
                      <Flex gap={2.5} padding="0 16px" alignItems="center">
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("overtimeTotal")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.extraTimeMinutes}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                extraTimeMinutes: value,
                              }))
                            }
                          />
                        </Flex>
                        <Flex gap={1} flexDirection={"column"} flex={1}>
                          <BodyText
                            fontSize={{ base: "11px", md: "12px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("penaltyKicks")}
                          </BodyText>
                          <IncrementDecrementInput
                            value={formData.numberPenalties}
                            setValue={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                numberPenalties: value,
                              }))
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </FormControl>
              </GridItem>
            </Grid>
            <PrivacySwitch
              isPrivate={formData.isRequiredForm}
              isPrivateText={i18n.t("requiredForm")}
              onSwitchChange={handleSwitchChange}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default TournamentRegulations;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
