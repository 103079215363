import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Link as ChakraLink,
  Box,
  Flex,
  Heading,
  Text,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
} from "@chakra-ui/react";
import {
  AppleStoreIcon,
  GooglePlayIcon,
  HamburgerIcon,
  WuamballIcon,
} from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";
import BodyText from "../Common/BodyText";

interface HeaderComponentProps {
  children: React.ReactNode;
}

function HeaderComponent({ children }: HeaderComponentProps) {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isResponsive] = useMediaQuery("(max-width: 1230px)");
  const [checkScroll, setCheckScroll] = useState(false);
  const menuItemProps = {
    borderRadius: 12,
    height: "40px",
    fontSize: 13,
    fontWeight: 600,
    justifyContent: "space-between",
    bg: colors.backgrounds.gray0,
    textColor: colors.text.lightGray3,
  };
  const handleResponsiveClick = (option: { to?: string; target?: string }) => {
    if (option.to) {
      if (option.target === "_blank") {
        window.open(option.to, "_blank");
      } else {
        window.location.href = option.to;
      }
    }
  };

  const menuOptions = [
    {
      to: "/terms_and_conditions",
      label: i18n.t("terms"),
      isIcon: false,
    },
    { to: "/login", label: i18n.t("log_in"), isIcon: false },
    {
      to: appStoreUrl,
      label: i18n.t("appStore"),
      icon: <AppleStoreIcon maxWidth="126px" width="100%" />,
      target: "_blank",
      isIcon: true,
    },
    {
      to: playStoreUrl,
      label: i18n.t("googlePlay"),
      icon: <GooglePlayIcon maxWidth="126px" width="100%" />,
      target: "_blank",
      isIcon: true,
    },
  ];

  const menuOptionsResponsive = menuOptions.map((option) => ({
    ...option,
    onClick: () => handleResponsiveClick(option),
  }));
  
  const handleScroll = () => {
    const scrollTriggerHeight = window.innerHeight * 0.5;
    setCheckScroll(window.scrollY > scrollTriggerHeight);
  };


  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        p={{
          base: "8px 16px",
          lg: "12px 24px",
          xl: "12px 32px",
        }}
        bg={colors.text.white}
        boxShadow="md"
        gap={2}
        width="100%"
        position="fixed"
        zIndex={10}
      >
        <Flex
          maxWidth={isResponsive ? "100%" : "80.5%"}
          align="center"
          justify="space-between"
          gap={2.5}
          margin="auto"
          height="48px"
        >
          {!isMobile ? (
            <>
              <Box>
                <Heading as={Link} fontSize="xl">
                  <ChakraLink as={Link} to={"/"}>
                    <WuamballIcon onClick={handleClick} />
                  </ChakraLink>
                </Heading>
              </Box>
              <Box>
                <Flex align="center" justify="space-between" gap="10">
                  {menuOptions.map((link, index) => (
                    <ChakraLink
                      key={index}
                      as={Link}
                      to={link.to}
                      target={link.target || "_self"}
                    >
                      {link.isIcon ? (
                        link.icon
                      ) : (
                        <Text fontSize="md">{link.label}</Text>
                      )}
                    </ChakraLink>
                  ))}
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Heading as={Link} fontSize="xl">
                  <ChakraLink as={Link} to={"/"}>
                    <WuamballIcon onClick={handleClick} />
                  </ChakraLink>
                </Heading>
              </Box>
              <Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<HamburgerIcon />}
                    variant="outline"
                    borderRadius="50%"
                    boxSize="48px"
                    minWidth="48px"
                  />
                  <Box className="content-width">
                    <MenuList
                      borderRadius={16}
                      padding={4}
                      mt={{
                        base: "3px",
                        sm: "6px",
                        lg: "11px",
                      }}
                    >
                      <BodyText
                        fontSize={13}
                        fontWeight="regular"
                        color={colors.text.lightGray3}
                      >
                        {i18n.t("options")}
                      </BodyText>
                      <Box mt={2} display="flex" flexDirection="column" gap={2}>
                        {menuOptionsResponsive.map((option, index) => (
                          <MenuItem
                            key={index}
                            onClick={option.onClick}
                            {...menuItemProps}
                          >
                            {option.label}
                            <IconArrowLinear
                              stroke={colors.figmaColors.secColor}
                            />
                          </MenuItem>
                        ))}
                      </Box>
                    </MenuList>
                  </Box>
                </Menu>
              </Box>
            </>
          )}
        </Flex>
      </Box>
      <Box minW={"100%"} justifyContent={"center"} display={"flex"}>
        <Box
          maxWidth={{
            base: "100%",
            md: "1230px",
          }}
          minW={{
            base: "100%",
            md: "80%",
          }}
          p={{
            base: "0",
            md: "5",
          }}
          width={{
            base: "100%",
            md: "85%",
            lg: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
      {checkScroll && (
        <Button
          position="fixed"
          bottom={isMobile ? "16px" : "24px"}
          right={isMobile ? "16px" : "24px"}
          borderRadius={isMobile ? 10 : 12}
          bg="blue.500"
          color="white"
          size={isMobile ? "sm" : "md"}
          boxShadow="lg"
          onClick={handleClick}
        >
          <IconArrowLinear
            style={{
              transform: "rotate(-90deg)",
            }}
          />
        </Button>
      )}
    </>
  );
}

export default HeaderComponent;
