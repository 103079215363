import { useCallback, useEffect, useState } from "react";
import { getTournamentById } from "../models/Tournament";

const useTournamentRegulations = (tournamentId: string) => {
  const DEFAULTS = {
    numberPlayersOnField: 3,
    numberChanges: 2,
    extraTimeMinutes: 30,
    numberPenalties: 5,
    minutesFirstTime: 45,
    minutesSecondTime: 45,
    maxTimeSendTemplateMinutes: 15,
    limitDateInscription: new Date(),
    isRequiredForm: false,
  };

  const [formData, setFormData] = useState({
    numberPlayersOnField: DEFAULTS.numberPlayersOnField,
    numberChanges: DEFAULTS.numberChanges,
    extraTimeMinutes: DEFAULTS.extraTimeMinutes,
    numberPenalties: DEFAULTS.numberPenalties,
    minutesFirstTime: DEFAULTS.minutesFirstTime,
    minutesSecondTime: DEFAULTS.minutesSecondTime,
    maxTimeSendTemplateMinutes: DEFAULTS.maxTimeSendTemplateMinutes,
    limitDateInscription: DEFAULTS.limitDateInscription,
    isRequiredForm: DEFAULTS.isRequiredForm,
  });

  const fetchTournamentData = useCallback(async () => {
    try {
      const tournamentData: any = await getTournamentById(tournamentId);
      setFormData((prev) => ({
        ...prev,
        limitDateInscription: tournamentData.limit_date_inscription
          ? new Date(tournamentData.limit_date_inscription.seconds * 1000)
          : DEFAULTS.limitDateInscription,
        numberPlayersOnField:
          tournamentData.number_players_on_field ??
          DEFAULTS.numberPlayersOnField,
        numberChanges: tournamentData.number_changes ?? DEFAULTS.numberChanges,
        extraTimeMinutes:
          tournamentData.extra_time_minuts ?? DEFAULTS.extraTimeMinutes,
        numberPenalties:
          tournamentData.number_penalties ?? DEFAULTS.numberPenalties,
        minutesFirstTime:
          tournamentData.minuts_first_time ?? DEFAULTS.minutesFirstTime,
        minutesSecondTime:
          tournamentData.minuts_second_time ?? DEFAULTS.minutesSecondTime,
        maxTimeSendTemplateMinutes:
          tournamentData.max_time_send_template_minuts ??
          DEFAULTS.maxTimeSendTemplateMinutes,
        isRequiredForm: tournamentData.required_form ?? DEFAULTS.isRequiredForm,
      }));
    } catch (error) {
      console.error("Error fetching regulations: ", error);
    }
  }, [tournamentId]);

  useEffect(() => {
    fetchTournamentData();
  }, [fetchTournamentData]);

  return { formData, setFormData };
};

export default useTournamentRegulations;
