import React, { ReactNode } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import {
  CloseCircleIcon,
  WuamballIcon,
  PlayerIcon,
  PlayerIconGray,
  ConfettiIcon,
} from "../definitions/constants/IconsComponent";
import { colors } from "../definitions/constants/GlobalStyles";
import i18n from "../i18n/i18n";
import BodyText from "./Common/BodyText";

interface MessageConfirmationProps {
  title: string;
  middleTitle?: string;
  subtitle: string;
  isFinished?: boolean;
  isDelete: boolean;
  teamName?: string;
  teamIcon?: ReactNode;
  onClick: () => void;
}

const MessageConfirmationComponent: React.FC<MessageConfirmationProps> = ({
  title,
  middleTitle,
  subtitle,
  isFinished,
  isDelete,
  teamName,
  teamIcon,
  onClick,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
      gap={8}
    >
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={onClick}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>

      <Flex direction="column" justifyContent="center">
        <WuamballIcon width="100%" height="36px" />
      </Flex>
      <Flex justifyContent="center" position="relative">
        {isDelete ? (
          <PlayerIconGray />
        ) : (
          <PlayerIcon
            width={isFinished ? "114px" : "280px"}
            height={isFinished ? "102px" : "250px"}
          />
        )}
        <Flex
          position="absolute"
          top="70%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <ConfettiIcon />
        </Flex>
      </Flex>

      {isFinished && (
        <Flex
          justifyContent="center"
          alignItems="center"
          direction="column"
          alignSelf="stretch"
          gap={1}
        >
          {teamIcon}
          <BodyText
            fontSize={{ base: "13px", md: "14px" }}
            fontWeight="semiBold"
            textColor={colors.text.black}
          >
            {teamName}
          </BodyText>
        </Flex>
      )}

      <Flex
        alignItems="center"
        direction="column"
        alignSelf="stretch"
        gap={2}
        flexGrow={1}
        justifyContent={isFinished ? "flex-start" : "center"}
      >
        <BodyText
          fontSize={{ base: "20px", md: "28px" }}
          fontWeight="light"
          textAlign="center"
          textColor={colors.text.black}
        >
          {title}
        </BodyText>
        {middleTitle && (
          <BodyText
            fontSize={{ base: "14px", md: "17px" }}
            fontWeight="regular"
            textColor={colors.figmaColors.grey3}
          >
            {middleTitle}
          </BodyText>
        )}
        <BodyText
          fontSize={{ base: "13px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.figmaColors.grey3}
        >
          {subtitle}
        </BodyText>
        <Button
          borderRadius="12px"
          bg={colors.figmaColors.secColor}
          color={colors.text.white}
          border="1px solid"
          borderColor={colors.figmaColors.secColor}
          fontSize="14px"
          height="48px"
          fontWeight={"600"}
          _hover={{
            color: colors.backgrounds.white,
            bg: colors.figmaColors.secColor,
            borderColor: "blue.600",
          }}
          _active={{
            bg: colors.figmaColors.secColor,
            borderColor: "blue.700",
          }}
          onClick={onClick}
        >
          {i18n.t("accept")}
        </Button>
      </Flex>
    </Box>
  );
};

export default MessageConfirmationComponent;
