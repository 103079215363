import React from "react";
import { Box, Flex, Switch } from "@chakra-ui/react";
import BodyText from "./BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";

interface PrivacySwitchProps {
  isPrivate: boolean;
  isPrivateText: string;
  onSwitchChange: () => void;
}

const PrivacySwitch: React.FC<PrivacySwitchProps> = ({
  isPrivate,
  isPrivateText,
  onSwitchChange,
}) => {
  return (
    <Flex width="100%" justifyContent="space-between" gap={2.5}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="semiBold"
          textColor={colors.text.black}
        >
          {isPrivateText}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("required")}
        </BodyText>
      </Box>
      <Switch
        size={{ base: "md", md: "lg" }}
        isChecked={isPrivate}
        onChange={onSwitchChange}
      />
    </Flex>
  );
};

export default PrivacySwitch;
