import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import TournamentTeamHeader from "../../../components/Common/TournamentTeamHeader";
import { appStore, matchStore } from "../../../zustand/globalStore";
import { trackEvent } from "../../../utils/tools";
import TeamViewOwnerComponent from "./TeamViewOwnerComponent";
import TeamViewOtherComponent from "./TeamViewOtherComponent";
import MatchDetailComponent from "../../../components/MatchDetailComponent/MatchDetailComponent";

export default function TeamViewComponent({ setTabActive }) {
  const { currentTeam, userData } = appStore((state) => state);
  const [viewMatch, setViewMatch] = useState(false);
  const { currentMatch, setCurrentMatch } = matchStore(store => store)

  useEffect(() => {
    trackEvent("TeamViewComponent", {});
    return ()=> setCurrentMatch(null)
  }, []);

  if (!currentTeam?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_team_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return (
    <Flex sx={styles.container}>
      {currentMatch ? <MatchDetailComponent /> : <> <TournamentTeamHeader isTeam={true} data={currentTeam} />
        {currentTeam?.user_id === userData.id ? (
          <TeamViewOwnerComponent setTabActive={setTabActive} />
        ) : (
          <TeamViewOtherComponent />
        )}</>}

    </Flex>
  );
}

const styles = {
  container: {
    gap: 3,
    flexDirection: "column",
  },
};
