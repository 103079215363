import React, { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { trackEvent } from "../../utils/tools";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { auth } from "../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import FormComponent from "../../components/Common/FormComponent";
import AccessComponent from "../../components/LayoutsComponents/AccessComponent";
import CustomTextInput from "../../components/Common/CustomTextInput";
import i18n from "../../i18n/i18n";

const RecoverPage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent("RecoverPasswordScreen", {});
  }, []);

  const sendRecover = (event) => {
    event.preventDefault();

    if (emailInput === "") {
      setEmailError(true);
    }

    if (emailInput !== "") {
      setEmailError(false);
      setLoading(true);
      sendEmail();
    }
  };

  const sendEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, emailInput.trim());
      toast({
        title: i18n.t("recover_success"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      console.log(error);

      const errorMapping = {
        "auth/user-not-found": i18n.t("user_not_found"),
        "auth/invalid-email": i18n.t("wrong_email"),
      };

      const _error =
        error instanceof FirebaseError ? error.code : error.toString();
      const message = errorMapping[_error] || i18n.t("error_creating_account");

      toast({
        title: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccessComponent>
      <FormComponent
        isLogin={false}
        isRegister={false}
        titleFrom={i18n.t("recover_password")}
        buttonText={i18n.t("recover")}
        footerText={i18n.t("already_have_account")}
        footerLink={i18n.t("log_in")}
        onSubmit={sendRecover}
        isInputValid={emailInput.trim() !== ""}
      >
        <CustomTextInput
          textField={i18n.t("email")}
          placeholder={i18n.t("email")}
          onChangeText={(text) => setEmailInput(text)}
          isPassword={false}
          showError={emailError}
          typeInput="email"
        />
      </FormComponent>
    </AccessComponent>
  );
};

export default RecoverPage;
