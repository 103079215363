import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import BodyText from "./BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  InputErrorIcon,
  SendIconWhite,
} from "../../definitions/constants/IconsComponent";
import "../styles/SearchUser.style.scss";

const SearchUser = ({
  searchText,
  handleSearch,
  errors,
  label,
  placeholder,
}: {
  searchText: string;
  handleSearch: (e: any) => void;
  errors: { [key: string]: string };
  label: string;
  placeholder: string;
}): JSX.Element => {
  return (
    <FormControl
      className="container-search-user"
      gap={1}
      isRequired={true}
      isInvalid={!!errors.selectUser}
    >
      <FormLabel
        display="flex"
        flexDirection="row"
        gap={1}
        alignItems="center"
        requiredIndicator={null}
      >
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="semiBold"
          paddingLeft={4}
          textColor={colors.text.black}
        >
          {label}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("required")}
        </BodyText>
      </FormLabel>
      <InputGroup className="input-and-button">
        <Input
          {...inputStyle}
          placeholder={placeholder}
          value={searchText}
          borderColor={colors.backgrounds.gray1}
          onChange={handleSearch}
        />
        <IconButton
          aria-label="Search user"
          background={colors.figmaColors.secColor}
          color={"white"}
        >
          <SendIconWhite />
        </IconButton>
      </InputGroup>
      {errors.selectUser && (
        <FormErrorMessage color="red.500" gap="4px">
          <InputErrorIcon />
          <BodyText color="red.500">
            {errors.selectUser || i18n.t("input_error")}
          </BodyText>
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default SearchUser;

const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  color: colors.text.lightGray3,
};
