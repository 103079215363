import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { CloseCircleIcon } from "../../definitions/constants/IconsComponent";
import BodyText from "./BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import IconArrowLarge from "../../assets/icons/IconArrowLarge";

interface ModalHeaderBaseProps {
  title: string;
  subtitle: string;
  actionButtonsRequired?: boolean;
  onCancel: () => void;
  isSaving?: boolean;
  cancelText?: string;
  colorButtonCancel?: string;
  colorButtonConfirm?: string;
  colorTextButtonCancel?: string;
  colorTextButtonConfirm?: string;
  declineButton?: boolean;
  declineCallback?: () => void;
}

interface ModalHeaderWithStepsProps extends ModalHeaderBaseProps {
  hasMoreSteps: true;
  onBack: () => void;
}

interface ModalHeaderWithoutStepsProps extends ModalHeaderBaseProps {
  hasMoreSteps?: false;
  onBack?: never;
}

interface ModalHeaderWithActionButtons extends ModalHeaderBaseProps {
  actionButtonsRequired: true;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  confirmText: string;
}

interface ModalHeaderWithoutActionButtons extends ModalHeaderBaseProps {
  actionButtonsRequired?: false;
  onConfirm?: never;
  confirmText?: never;
}

type ModalHeaderProps =
  | (ModalHeaderWithStepsProps & ModalHeaderWithActionButtons)
  | (ModalHeaderWithoutStepsProps & ModalHeaderWithoutActionButtons)
  | (ModalHeaderWithStepsProps & ModalHeaderWithoutActionButtons)
  | (ModalHeaderWithoutStepsProps & ModalHeaderWithActionButtons);

const ModalHeaderComponent: React.FC<ModalHeaderProps> = ({
  title,
  subtitle,
  actionButtonsRequired = false,
  onCancel,
  onConfirm,
  onBack,
  isSaving = false,
  hasMoreSteps = false,
  confirmText,
  cancelText = i18n.t("cancel"),
  colorButtonConfirm,
  colorButtonCancel,
  colorTextButtonCancel,
  colorTextButtonConfirm,
  declineButton,
  declineCallback,
}) => {
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={onCancel}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Flex gap={2}>
          {hasMoreSteps && onBack && (
            <Button
              onClick={onBack}
              p={0}
              height={"auto"}
              minWidth={0}
              variant="ghost"
              color={colors.text.black}
              transform="rotate(180deg)"
              _hover={{
                backgroundColor: "transparent",
                color: colors.text.black,
              }}
            >
              <IconArrowLarge
                stroke={colors.figmaColors.secColor}
                strokeWidth="1"
                width="28px"
                height="28px"
              />
            </Button>
          )}
          <Flex flexDirection={"column"}>
            <BodyText
              fontSize={{ base: "20px", md: "28px" }}
              fontWeight="light"
              textColor={colors.text.black}
            >
              {title}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {subtitle}
            </BodyText>
          </Flex>
        </Flex>
        {actionButtonsRequired && (
          <Flex
            gap={2}
            flexDirection={{ md: "row" }}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Button
              borderRadius={12}
              bg={
                colorButtonCancel ? colorButtonCancel : colors.backgrounds.white
              }
              color={
                colorTextButtonCancel
                  ? colorTextButtonCancel
                  : colors.figmaColors.secColor
              }
              border="1px solid"
              borderColor={
                colorButtonCancel
                  ? colorButtonCancel
                  : colors.figmaColors.secColor
              }
              fontSize="14px"
              fontWeight={"600"}
              _hover={{
                color: colorButtonCancel
                  ? colorButtonCancel
                  : colors.backgrounds.white,
                bg: colorTextButtonCancel
                  ? colorTextButtonCancel
                  : colors.figmaColors.secColor,
                borderColor: colorButtonCancel ? colorButtonCancel : "blue.600",
              }}
              _active={{
                bg: colors.figmaColors.secColor,
                borderColor: "blue.700",
              }}
              onClick={() => (declineButton ? declineCallback() : onCancel())}
              isDisabled={isSaving}
              height={"48px"}
            >
              {cancelText}
            </Button>
            <Button
              borderRadius={12}
              bg={
                colorButtonConfirm
                  ? colorButtonConfirm
                  : colors.figmaColors.secColor
              }
              color={
                colorTextButtonConfirm
                  ? colorTextButtonConfirm
                  : colors.text.white
              }
              border="1px solid"
              borderColor={colors.figmaColors.secColor}
              fontSize="14px"
              fontWeight={"600"}
              _hover={{
                color: colors.backgrounds.white,
                bg: colors.figmaColors.secColor,
                borderColor: "blue.600",
              }}
              _active={{
                bg: colors.figmaColors.secColor,
                borderColor: "blue.700",
              }}
              onClick={onConfirm}
              isDisabled={isSaving}
              height={"48px"}
            >
              {confirmText}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ModalHeaderComponent;
