import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { CloseCircleIcon } from "../../definitions/constants/IconsComponent";
import BodyText from "../../components/Common/BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import IconArrowLarge from "../../assets/icons/IconArrowLarge";
import IconAddSquare from "../../assets/icons/IconAddSquare";

interface BaseProps {
  title: string;
  subtitle: string;
  onCancel: () => void;
  onBack: () => void;
  onClick: () => void;
  onNext: () => void;
  hasMoreSteps: boolean;
  isSaving: boolean;
}

const ModalHeaderMatches = ({
  title,
  subtitle,
  onCancel,
  onBack,
  onClick,
  onNext,
  hasMoreSteps = false,
  isSaving,
}: BaseProps) => {
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={onCancel}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Flex gap={2}>
          {hasMoreSteps && onBack && (
            <Button
              onClick={onBack}
              p={0}
              height={"auto"}
              minWidth={0}
              variant="ghost"
              color={colors.text.black}
              transform="rotate(180deg)"
              _hover={{
                backgroundColor: "transparent",
                color: colors.text.black,
              }}
            >
              <IconArrowLarge
                stroke={colors.figmaColors.secColor}
                strokeWidth="1"
                width="28px"
                height="28px"
              />
            </Button>
          )}
          <Flex flexDirection={"column"}>
            <BodyText
              fontSize={{ base: "20px", md: "28px" }}
              fontWeight="light"
              textColor={colors.text.black}
            >
              {title}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {subtitle}
            </BodyText>
          </Flex>
        </Flex>
        <Flex
          gap={2}
          flexDirection={{ md: "row" }}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
        >
          <Button
            gap={1}
            borderRadius={16}
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => onClick()}
            isDisabled={isSaving}
            height={"48px"}
          >
            <IconAddSquare />
            {i18n.t("createRandomly")}
          </Button>
          <Button
            gap={1}
            borderRadius={16}
            bg={colors.figmaColors.secColor}
            color={colors.text.white}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => onNext()}
            isDisabled={isSaving}
            height={"48px"}
          >
            <IconAddSquare />
            {i18n.t("createNew")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ModalHeaderMatches;
