export const steps = {
    default: "default",
    newCup: "newCup",
    selectTournaments: "selectTournaments",
    successScreen: "successScreen",
    editCup: "editCup",
    successEdit: "successEdit",
    deleteCup: "deleteCup",
    successDelete: "successDelete",
    successSelectTournaments: "successSelectTournaments",
    selectEditTournaments: "selectEditTournaments"
  };
  