export enum RolManager {
  president = "president",
  vicePresident = "vicePresident",
  secretary = "secretary",
  treasurer = "treasurer",
  vocal = "vocal",
  vocal1 = "vocal1",
  vocal2 = "vocal2",
  vocal3 = "vocal3",
  vocal4 = "vocal4",
  vocal5 = "vocal5",
  vocal6 = "vocal6",
}

export enum TypePhasesSetup {
  all_against_all = "all_against_all",
  playoffs = "playoffs",
  groups = "groups",
}

export enum TypeViewToShow {
  CreateTeamView = "CreateTeamView",
  CreateTournamentView = "CreateTournamentView",
  CreateOrEditCupView = "CreateOrEditCupView",
  UpdateOrViewProfileData = "UpdateOrViewProfileData",
  ShowDocumentsUserView = "ShowDocumentsUserView",
  ShowSupportGroupView = "ShowSupportGroupView",
  SureDeleteAccountView = "SureDeleteAccountView",
  UpdateOrViewTournamentData = "UpdateOrViewTournamentData",
  UpdateOrViewTeamData = "UpdateOrViewTeamData",
  PlayersTournamentTeam = "PlayersTournamentTeam",
  CoachingStaff = "CoachingStaff",
  TechnicalStaff = "TechnicalStaff",
  TeamSquad = "TeamSquad",
}

export enum BackgroundColorsProfile {
  color1 = "#CB62F5",
  color2 = "#54343f",
  color3 = "#7E68E8",
  color4 = "#e77a77",
  color5 = "#6298F5",
  color6 = "#741952",
  color7 = "#94bb68",
  color8 = "#94bb68",
  color9 = "#94bb68",
  color0 = "#94bb68",
}

export enum RolDirective {
  coach1 = "coach1",
  coach2 = "coach2",
  assistant1 = "assistant1",
  assistant2 = "assistant2",
  kinesiologist = "kinesiologist",
  delegate = "delegate",
}

export enum TypeFiles {
  identification = "identification",
  others = "others",
}

export enum MatchRolUser {
  referee = "referee",
  own = "own",
  vocal = "vocal",
  player = "player",
  user = "user",
  ownTeam = "own_team",
}

export enum IncidenceTypes {
  goal = "goal",
  own_goal = "own_goal",
  change_player = "change_player",
  yellow_card = "yellow_card",
  red_card = "red_card",
  team_not_present = "team_not_present",
}

export enum IncidenceTypesResume {
  goals = "goals",
  red_cards = "red_cards",
  substitutions = "substitutions",
  yellow_cards = "yellow_cards",
}

export enum RefereeTypes {
  central = "central",
  first_attendee = "first_attendee",
  second_attendee = "second_attendee",
  quarter_referee = "quarter_referee",
  first_attendee_additional = "first_attendee_additional",
  second_attendee_additional = "second_attendee_additional",
}

export enum TypeNotifications {
  teamParticipation = "team_participation",
  teamInvitation = "team_invitation",
  playerInvitation = "player_invitation",
  managerInvitation = "manager_invitation",
  technicalStaff = "technical_staff",
  like = "like",
  comment = "comment",
  teamAcceptParticipation = "team_accept_participation",
  playerAcceptInvitation = "player_accept_invitation",
  managerAcceptInvitation = "manager_accept_invitation",
  manageDeniedInvitation = "manager_denied_invitation",
  technicalAcceptStaff = "technical_accept_staff",
  technicalDeniedStaff = "technical_denied_staff",
  teamDeniedParticipation = "team_denied_participation",
  playerDeniedInvitation = "player_denied_invitation",
  follow = "following",
  message = "message",
  refereeAssignment = "referee_assignment",
}

export enum TypeTimeOfMatch {
  firstTime = "firstTime",
  secondTime = "secondTime",
  firstOvertime = "firstOvertime",
  secondOvertime = "secondOvertime",
  penalty = "penalty",
  break = "break",
  finished = "finished",
  pending = "pending",
  cancel = "cancel",
  HT = "HT", //Half Time
  TBD = "TBD", //To Be Defined
  NS = "NS", //Not Started
  ET = "ET", //Extra Time
  BT = "BT", //Break Time
  P = "P", //Penalty
  SUSP = "SUSP", //Suspended
  INT = "INT", //Interrupted
  PST = "PST", //Postponed
  CANC = "CANC", //Cancelled
  ABD = "ABD", //Abandoned
  AWD = "AWD", //Awarded
  WO = "WO", //WalkOver
  LIVE = "LIVE", //Live
}

export enum MatchStatusSpanish {
  finished = "finished",
  playing = "playing",
  pending = "pending",
  cancel = "cancelMatch",
  FT = "finished",
  AET = "finished",
  PEN = "finished",
  "1H" = "firstTime",
  HT = "secondTime",
  "2H" = "halfTime",
  TBD = "TBD",
  NS = "NS",
  ET = "ET",
  BT = "BT",
  P = "P",
  SUSP = "SUSP",
  INT = "INT",
  PST = "PST",
  CANC = "CANC",
  ABD = "ABD",
  AWD = "AWD",
  WO = "WO",
  LIVE = "LIVE",
}
