import { Avatar, Button, Checkbox, Flex } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";
import CountryFlag from "./CountryFlag";

const ItemInviteTeamSelection = ({
  item,
  selectedItem,
  setSelectedItem,
}: {
  item: any;
  selectedItem: any;
  setSelectedItem: any;
}): JSX.Element => {
  const isChecked = selectedItem?.id === item?.id;

  return (
    <Button
      key={item.id}
      sx={{
        ...style.container,
        border:
          selectedItem?.id === item?.id
            ? `1px solid ${colors.figmaColors.secColor}`
            : null,
      }}
      className="container-item-user"
      onClick={() => setSelectedItem(item)}
    >
      <Flex gap={2} alignItems="center">
        <Avatar src={item.media_url} name={item.name} />
        <Flex direction="column" alignItems="flex-start">
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.black}
          >
            {item.name}
          </BodyText>
          <Flex alignItems="center" gap={1}>
            <CountryFlag countryName={item?.place?.terms?.at(-1)?.value} />
            <BodyText
              color={colors.figmaColors.grey3}
              fontWeight={"medium"}
              size="small"
            >
              {item?.place?.terms?.at(-1)?.value || i18n.t("noRegion")}
            </BodyText>
          </Flex>
        </Flex>
      </Flex>
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedItem(item);
        }}
        sx={{
          "& .chakra-checkbox__control": {
            boxSize: "20px",
            borderRadius: "5px",
            border: `1px solid ${colors.figmaColors.secColor}`,
            bg: isChecked ? colors.figmaColors.secColor : colors.text.white,
          },
        }}
      />
    </Button>
  );
};

export default ItemInviteTeamSelection;

const style = {
  container: {
    padding: "12px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
};
