import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import i18n from "../../i18n/i18n";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";
import { colors } from "../../definitions/constants/GlobalStyles";

interface CustomMenuSelectProps {
  selectedDescription: string;
  setSelectedDescription: (phase: string) => void;
  options: Record<string, string>;
  isInvalid?: boolean;
}

const CustomMenuSelect: React.FC<CustomMenuSelectProps> = ({
  selectedDescription,
  setSelectedDescription,
  options,
  isInvalid = false,
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Box as={IconArrowLinear} transform={"rotate(90deg)"} />}
        bg="white"
        border="1px solid"
        borderColor={isInvalid ? "red.500" : colors.backgrounds.gray1}
        borderRadius={20}
        textAlign="left"
        fontSize="14px"
        fontWeight="400"
        height="60px"
        _hover={{
          bg: "white",
        }}
        _active={{
          bg: "white",
        }}
        width="100%"
        color={colors.text.lightGray3}
        style={{
          boxShadow: isInvalid ? "0 0 0 1px #E53E3E" : "none",
        }}
      >
        {i18n.t(selectedDescription) || i18n.t("selectOption")}
      </MenuButton>
      <Box className="custom-width">
        <MenuList
          gap={1}
          padding={2}
          border="1px solid"
          borderColor={colors.backgrounds.gray1}
          borderRadius={16}
        >
          {Object.values(options).map((item) => (
            <MenuItem
              padding="0 16px"
              borderRadius={12}
              fontSize="13px"
              fontWeight="400"
              height="40px"
              key={item}
              color={colors.text.lightGray3}
              onClick={() => setSelectedDescription(item)}
            >
              {i18n.t(item)}
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </Menu>
  );
};

export default CustomMenuSelect;
