import React from "react";
import CardComponent from "../../../components/Common/CardComponent";
import { appStore } from "../../../zustand/globalStore";
import i18n from "../../../i18n/i18n";

const TeamCard: React.FC<{ teams: any[]; }> = ({ teams }) => {
  const { setCurrentTeam } = appStore((state) => state);

  return (
    <CardComponent
      items={teams}
      isTeam={true}
      onSetCurrentItem={setCurrentTeam}
      getItemName={(team) => team.name}
      getItemRegion={(team) => {
        return team.place?.terms?.at(-1)?.value || i18n.t("noRegion");
      }}
      getItemImage={(team) => team.media_url}
      getItemCountryImage={(team) => team.place?.terms?.at(-1)?.value}
    />
  );
};

export default TeamCard;
