import { Box } from '@chakra-ui/react';
import React, { useState } from 'react'
import ModalHeaderComponent from '../../../../components/Common/ModalHeaderComponent';
import i18n from '../../../../i18n/i18n';
import { steps } from '../containers/steps.container';
import CustomTextInput from '../../../../components/Common/CustomTextInput';
import { CreateCoup, EditCoup } from '../containers/Coup.container';
import { appStore } from '../../../../zustand/globalStore';
import { Coup } from '../containers/Coups.interface';

const CreateAndEditCoup = ({ type, goToStep, hideContainerModal, currentCoup }: { currentCoup?:Coup ;type: string; goToStep: (step: string) => void; hideContainerModal: any }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({ name: type=== "edit"?currentCoup?.name:"" })
    const [errors, setErrors] = useState({ name: "" })
    const {userData} = appStore(store=>store)
    const validate = (formData:{name:string}) => {
        const newErrors: { [key: string]: string } = {};
        const fieldsToValidate = [
            { key: "name", value: formData.name },
        ];

        fieldsToValidate.forEach((field) => {
            if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
        });

        setErrors(newErrors as {name:string});
        return Object.keys(newErrors).length === 0;
    }

    const handleConfirm = async() => {
       setIsSaving(true)
       if(validate(formData) && type === "create"){    
            await CreateCoup({name:formData.name, user_id: userData?.id})
            setIsSaving(false)
            goToStep(steps.selectTournaments)
       }else if(validate(formData) && type === "edit"){
            await EditCoup({name:formData.name, coup_id: currentCoup?.id, is_deleted:false})
            setIsSaving(false)
            if(type === "edit"){
                goToStep(steps.selectEditTournaments)
            }else{
                goToStep(steps.selectTournaments)
            }
       }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            overflowY="auto"
            css={{
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
            }}
        >
            <ModalHeaderComponent
                title={i18n.t(type === "create" ? "newCoup" : "editCoup")}
                subtitle={i18n.t("msgCreateCup")}
                confirmText={i18n.t("continue")}
                onCancel={hideContainerModal}
                onConfirm={handleConfirm}
                isSaving={isSaving}
                onBack={() => goToStep(steps.default)}
                hasMoreSteps={true}
                actionButtonsRequired={true}
            />
            <Box padding={"10px 0px"}>
                <CustomTextInput
                    textField={i18n.t("nameCoup")}
                    placeholder={i18n.t("nameCoup") + "..."}
                    defaultValue={formData.name}
                    onChangeValue={(e) => {
                        setFormData((prev) => ({
                            ...prev,
                            name: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, name: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.name}
                    showError={!!errors.name}
                    typeInput="text"
                />
            </Box>
        </Box>
    )
}

export default CreateAndEditCoup
