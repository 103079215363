import { Button, Flex, Input } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { DecrementIcon, IncrementIcon } from "../../definitions/constants/IconsComponent";

interface IncrementDecrementInputProps {
  value: number;
  setValue: (newValue: number) => void;
}

const IncrementDecrementInput = ({
  value,
  setValue,
}: IncrementDecrementInputProps) => {
  const handleDecrement = () => {
    setValue(Math.max(value - 1, 0));
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      border={`1px solid ${colors.backgrounds.gray1}`}
      borderRadius={20}
      padding="0px 24px"
      minHeight="60px"
      flex={1}
    >
      <Button
        size="sm"
        onClick={handleDecrement}
        bg="white"
        _hover={{ bg: "gray.300" }}
      >
        <DecrementIcon />
      </Button>
      <Input
        value={value}
        readOnly
        p={0}
        textAlign="center"
        border="none"
        borderRadius={12}
        fontWeight="400"
        fontSize="17px"
        pointerEvents="none"
        _focus={{ outline: "none" }}
        width="40px"
        height="40px"
        color={colors.text.white}
        backgroundColor={colors.figmaColors.borderCard}
      />
      <Button
        size="sm"
        onClick={handleIncrement}
        bg="white"
        _hover={{ bg: "gray.300" }}
      >
        <IncrementIcon />
      </Button>
    </Flex>
  );
};

export default IncrementDecrementInput;
