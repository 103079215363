import { Box, Icon, IconButton } from "@chakra-ui/react";
import { User } from "../../../../definitions/interfaces/Users/Users.interface";
import {
  ReactDispatchStringState,
  ReactDispatchUserState,
} from "../../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { appStore } from "../../../../zustand/globalStore";
import { colors } from "../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../Common/BodyText";
import i18n from "../../../../i18n/i18n";
import IconTrash from "../../../../assets/icons/IconTrash";
import { steps } from "../container/steps.container";

const ItemTechnicalStaff = ({
  item,
  setSelectedUser,
  goToStep,
}: {
  item: User;
  setSelectedUser: ReactDispatchUserState;
  goToStep: ReactDispatchStringState;
}): JSX.Element => {
  const { userData } = appStore((store) => store);

  return (
    <Box
      key={item.id}
      className="container-item-user-manager"
      sx={{ border: `1px solid ${colors.backgrounds.gray1}` }}
    >
      <Box>
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.black}
        >
          {item.firstname} {item.lastname}
        </BodyText>
        <BodyText
          fontSize={{ base: "11px", md: "12px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {i18n.t(item.rol)}
          {item.id === userData.id && i18n.t("admin")}
        </BodyText>
      </Box>
      {item.id !== userData.id && (
        <IconButton
          aria-label=""
          background={"transparent"}
          onClick={() => {
            goToStep(steps.deleteTechnicalStaff);
            setSelectedUser(item);
          }}
        >
          <IconTrash stroke={colors.backgrounds.red} />
        </IconButton>
      )}
    </Box>
  );
};
export default ItemTechnicalStaff;
