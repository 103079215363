import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { consumeApi } from "../../../../../utils/tools";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import ToggleQuestion from "../../../../../components/Shared/ToggleQuestion";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";
import CustomTextInput from "../../../../../components/Common/CustomTextInput";

interface StepData {
  [key: string]: any;
}

interface CreateSeasonBaseProps {
  isEdit: boolean;
  currentStep: string;
  goToStep: (key: string, data?: StepData) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditSeasonProps =
  | (CreateSeasonBaseProps & { isEdit: false; selectedSeason?: never })
  | (CreateSeasonBaseProps & {
      isEdit: true;
      selectedSeason: { name: string; [key: string]: any };
    });

const CreateOrEditSeason: React.FC<CreateOrEditSeasonProps> = ({
  isEdit,
  goToStep,
  currentStep,
  isSaving,
  setIsSaving,
  selectedSeason,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDuplicateRegulations, setIsDuplicateRegulations] = useState(false);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedSeason.name : "",
  };
  const [formData, setFormData] = useState(dataForm);

  const onPressCreateOrEditSeason = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isEdit) {
        const payloadEdit = {
          season_id: selectedSeason.season_id,
          season_name: data.name.trim(),
        };

        await consumeApi(`tournaments/edit_season`, "POST", payloadEdit);
        goToStep(stepsTournaments.successSeasonName);
      } else {
        const payloadCreate = {
          tournament_id: currentTournament.id,
          season_name: data.name.trim() || i18n.t("season") + " 1",
          season_year: new Date().getFullYear(),
          duplicate_regulations: isDuplicateRegulations,
        };

        const createdSeason = await consumeApi(
          `tournaments/set_season`,
          "POST",
          payloadCreate
        );

        goToStep(stepsTournaments.successNewSeason, {
          name: createdSeason.season.name,
        });
      }
    } catch (error) {
      console.error("Error creating or updating season: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditSeason(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === stepsTournaments.seasonName
            : currentStep === stepsTournaments.newSeason) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              <ModalHeaderComponent
                title={isEdit ? i18n.t("seasonName") : i18n.t("newSeason")}
                subtitle={i18n.t("readyTournament")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={() =>
                  isEdit
                    ? goToStep(stepsTournaments.optionsSeason)
                    : goToStep(stepsTournaments.default)
                }
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
              {!isEdit && (
                <ToggleQuestion
                  title={i18n.t("duplicateRegulations")}
                  subTitle={i18n.t("regulationsAdded")}
                  value={isDuplicateRegulations}
                  onChange={() =>
                    setIsDuplicateRegulations(!isDuplicateRegulations)
                  }
                />
              )}
              <CustomTextInput
                textField={i18n.t("seasonName")}
                placeholder={i18n.t("seasonName") + "..."}
                defaultValue={formData.name}
                onChangeValue={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  setErrors((prev) => ({ ...prev, name: "" }));
                }}
                isPassword={false}
                errorMessage={errors.name}
                showError={!!errors.name}
                typeInput="text"
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditSeason;
