import { Flex, Button, Spinner } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../Common/BodyText";
import {
  CloseCircleIcon,
  WuamballIcon,
  MessageIcon,
} from "../../definitions/constants/IconsComponent";
import i18n from "../../i18n/i18n";

const DeleteContentModal: React.FC<{
  message: string;
  subMessage?: string;
  onCancel: () => void;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading?: boolean
}> = ({ message, subMessage, onCancel, onConfirm,loading }) => {
  const {hideContainerModal} = appStore(store=>store)
  return (
    <>
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={hideContainerModal}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>

      <Flex direction="column" justifyContent="center">
        <WuamballIcon width="100%" height="36px" />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        gap={2}
      >
        <MessageIcon />
        <BodyText
          fontSize={{ base: "20px", md: "28px" }}
          fontWeight="light"
          textAlign="center"
          textColor={colors.text.black}
        >
          {message}
        </BodyText>
        {subMessage && (
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="light"
            textColor={colors.text.lightGray3}
          >
            {subMessage}
          </BodyText>
        )}
        <Flex
          justifyContent="center"
          alignItems="center"
          alignSelf="stretch"
          gap={2}
        >
          <Button
            borderRadius="12px"
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            height="48px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={onCancel}
          >
            {i18n.t("cancel")}
          </Button>
          <Button
            borderRadius="12px"
            bg={colors.figmaColors.secColor}
            color={colors.text.white}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            height="48px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={onConfirm}
            disabled={loading}
          >
            {loading?<Spinner/>:i18n.t("confirm")}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default DeleteContentModal;
