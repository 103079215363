import { useState } from "react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { deleteMatch } from "../../../../../models/MatchModel";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

const DeleteMatch = ({
  goToStep,
  selectedMatch,
}: {
  goToStep: (key: string) => void;
  selectedMatch: { [key: string]: any };
}): JSX.Element => {
  const { hideContainerModal } = appStore((store) => store);
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await deleteMatch(selectedMatch?.id);
      goToStep(stepsTournaments.successDeletePhase);
    } catch (error) {
      console.error("Error deleting team:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <DeleteContentModal
      message={i18n.t("sureDeleteMatch", {
        teamA: selectedMatch.first_team_name,
        teamB: selectedMatch.second_team_name,
      })}
      onCancel={hideContainerModal}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteMatch;
