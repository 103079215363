import { useState } from "react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { deleteGroup } from "../../../../../models/Tournament";
import { deleteMatch } from "../../../../../models/MatchModel";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../config/firebase";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { GetMatchesResponse } from "../../../../../definitions/types/GlobalTypes";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";

const DeleteGroup = ({
  onBack,
  selectedGroup,
  selectedPhase,
}: {
  onBack: () => void;
  selectedPhase: { [key: string]: any };
  selectedGroup: { [key: string]: any };
}): JSX.Element => {
  const { currentTournament, hideContainerModal } = appStore((store) => store);
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await deleteGroup(
        currentTournament.id,
        selectedPhase.id,
        selectedGroup.id
      );
      await deleteMatchesGroup(selectedGroup);
    } catch (error) {
      console.error("Error deleting team:", error);
    } finally {
      setLoading(false);
      onBack();
    }
  };

  const deleteMatchesGroup = async (group: any) => {
    if (selectedPhase.id && group.id) {
      try {
        const response = await httpsCallable<{}, GetMatchesResponse>(
          functions,
          "getMatchesAndTeamsByGroup"
        )({
          group_id: group.id,
        });

        const matches = response.data.matches;

        if (matches && matches.length > 0) {
          await Promise.all(
            matches.map(async (match) => {
              return deleteMatch(match.id);
            })
          );
        }
      } catch (error) {
        console.error("Error deleting matches of group: ", error);
      }
    }
  };

  if (loading) {
    return <SpinnerComponent spinnerKey="modal" />;
  }

  return (
    <DeleteContentModal
      message={i18n.t("sureDeleteGroup", { name: selectedGroup.name })}
      onCancel={hideContainerModal}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteGroup;
