import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { createGroup, editGroup } from "../../../../../models/Tournament";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";
import CustomTextInput from "../../../../../components/Common/CustomTextInput";

interface CreateGroupBaseProps {
  isEdit: boolean;
  currentStep: string;
  onBack: () => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPhase: { [key: string]: any };
}

type CreateOrEditGroupProps =
  | (CreateGroupBaseProps & {
      isEdit: false;
      selectedGroup?: never;
    })
  | (CreateGroupBaseProps & {
      isEdit: true;
      selectedGroup: { [key: string]: any };
    });

const CreateOrEditGroup: React.FC<CreateOrEditGroupProps> = ({
  isEdit,
  onBack,
  currentStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  selectedGroup,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [groups, setGroups] = useState([]);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedGroup.name : "",
  };
  const [formData, setFormData] = useState(dataForm);

  const onPressCreateOrEditGroup = async (data: any) => {
    setIsSaving(true);
    try {
      if (selectedGroup?.id && isEdit) {
        let editedGroup: any = await editGroup(
          currentTournament?.id,
          selectedPhase,
          data.name,
          selectedPhase?.id,
          selectedGroup?.id
        );
        let index = groups.findIndex((group) => group.id === selectedGroup.id);
        let newGroups = [...groups];
        newGroups[index] = editedGroup;
        setGroups(newGroups);
      } else if (selectedPhase?.id && !isEdit) {
        let createdGroup: any = await createGroup(
          currentTournament?.id,
          selectedPhase,
          data?.name,
          selectedPhase?.id
        );
        setGroups([...groups, createdGroup]);
      } else {
        setGroups([...groups, selectedGroup]);
      }
    } catch (error) {
      console.error("Error adding or editing the group: ", error);
    } finally {
      setIsSaving(false);
      onBack();
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditGroup(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === stepsTournaments.updateGroup
            : currentStep === stepsTournaments.createGroup) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              <ModalHeaderComponent
                title={i18n.t("groups")}
                subtitle={i18n.t("completeGroupName")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={onBack}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
              <CustomTextInput
                textField={i18n.t("nameGroup")}
                placeholder={i18n.t("nameGroup") + "..."}
                defaultValue={formData.name}
                onChangeValue={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  setErrors((prev) => ({ ...prev, name: "" }));
                }}
                isPassword={false}
                errorMessage={errors.name}
                showError={!!errors.name}
                typeInput="text"
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditGroup;
