import React, { useState } from "react";
import { Box, FormLabel, Flex, Button, FormErrorMessage, FormControl } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import PrivacySwitch from "../../../../../components/Common/PrivacySwitch";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import CustomMenuSelect from "../../../../../components/Shared/CustomMenuSelect";
import { TypePhasesSetup } from "../../../../../definitions/enums/GlobalEnums";
import { createPhase, updatePhase } from "../../../../../models/Tournament";
import IconEdit from "../../../../../assets/icons/IconEdit";
import IconTrash from "../../../../../assets/icons/IconTrash";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import SpinnerComponent from "../../../../../components/Common/SpinnerComponent";
import CustomTextInput from "../../../../../components/Common/CustomTextInput";
import { InputErrorIcon } from "../../../../../definitions/constants/IconsComponent";

interface CreatePhaseBaseProps {
  isEdit: boolean;
  currentStep: string;
  groups: any[];
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditPhaseProps =
  | (CreatePhaseBaseProps & {
      isEdit: false;
      selectedPhase?: never;
      setSelectedGroup?: never;
    })
  | (CreatePhaseBaseProps & {
      isEdit: true;
      selectedPhase: { [key: string]: any };
      setSelectedGroup: React.Dispatch<
        React.SetStateAction<{ [key: string]: any } | undefined>
      >;
    });

const CreateOrEditPhase: React.FC<CreateOrEditPhaseProps> = ({
  isEdit,
  goToStep,
  groups,
  currentStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  setSelectedGroup,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedPhase.name : "",
    count_teams_by_group: isEdit ? selectedPhase.count_teams_by_group : "",
    count_teams_for_next_phase: isEdit
      ? selectedPhase.count_teams_for_next_phase
      : "",
    count_groups: isEdit ? selectedPhase.count_groups : "",
  };
  const [formData, setFormData] = useState(dataForm);
  const [typePhaseDescription, setTypePhaseDescription] = useState(
    isEdit ? selectedPhase.type_phase_setup : ""
  );
  const [haveGroups, setHaveGroups] = useState(
    isEdit ? selectedPhase.have_groups : false
  );
  const [isRound, setIsRound] = useState(
    isEdit ? selectedPhase.is_round : false
  );

  const onPressCreateOrEditPhase = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      data.have_groups = haveGroups;
      data.type_phase_setup = typePhaseDescription;
      data.is_round = isRound;

      if (isEdit) {
        await updatePhase(selectedPhase.id, currentTournament.id, data);
        goToStep(stepsTournaments.successEditPhase);
      } else {
        await createPhase(currentTournament.id, data, groups);
        goToStep(stepsTournaments.successNewPhase);
      }
    } catch (error) {
      console.error("Error creating or updating phase: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSwitchChange = () => {
    setIsRound(!isRound);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
      {
        key: "typePhaseDescription",
        value: typePhaseDescription,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditPhase(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <>
          {(isEdit
            ? currentStep === stepsTournaments.editPhase
            : currentStep === stepsTournaments.newPhase) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              gap={4}
              width="100%"
              height="100%"
            >
              <ModalHeaderComponent
                title={isEdit ? i18n.t("editPhase") : i18n.t("newPhase")}
                subtitle={i18n.t("readyTournament")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={() => goToStep(stepsTournaments.modality)}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
              <Flex gap={2} flexDirection={"column"} width="100%" height="100%">
                <CustomTextInput
                  textField={i18n.t("phaseName")}
                  placeholder={i18n.t("phaseName") + "..."}
                  defaultValue={formData.name}
                  onChangeValue={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                    setErrors((prev) => ({ ...prev, name: "" }));
                  }}
                  isPassword={false}
                  errorMessage={errors.name}
                  showError={!!errors.name}
                  typeInput="text"
                />
                <FormControl
                  gap={1}
                  flexDirection={"column"}
                  width="100%"
                  isRequired={true}
                  isInvalid={!!errors.typePhaseDescription}
                >
                  <FormLabel
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    requiredIndicator={null}
                  >
                    <BodyText
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="semiBold"
                      paddingLeft={4}
                      textColor={colors.text.black}
                    >
                      {i18n.t("phaseType")}
                    </BodyText>
                    <BodyText
                      fontSize={{ base: "12px", md: "13px" }}
                      fontWeight="regular"
                      textColor={colors.text.lightGray3}
                    >
                      {i18n.t("required")}
                    </BodyText>
                  </FormLabel>
                  <CustomMenuSelect
                    options={TypePhasesSetup}
                    isInvalid={!!errors.typePhaseDescription}
                    selectedDescription={typePhaseDescription}
                    setSelectedDescription={setTypePhaseDescription}
                  />
                  {errors.typePhaseDescription && (
                    <FormErrorMessage color="red.500" gap="4px">
                      <InputErrorIcon />
                      <BodyText color="red.500">
                        {errors.typePhaseDescription || i18n.t("input_error")}
                      </BodyText>
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Flex gap={4} justifyContent="space-between">
                  <CustomTextInput
                    textField={i18n.t("numberTeams")}
                    placeholder={i18n.t("numberTeams") + "..."}
                    defaultValue={formData.count_teams_by_group}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        count_teams_by_group: e.target.value,
                      }));
                    }}
                    isRequired={false}
                    isPassword={false}
                    typeInput="number"
                  />
                  <CustomTextInput
                    textField={
                      typePhaseDescription === TypePhasesSetup.groups
                        ? i18n.t("numberClassifiedGroup")
                        : i18n.t("numberClassified")
                    }
                    placeholder={
                      (typePhaseDescription === TypePhasesSetup.groups
                        ? i18n.t("numberClassifiedGroup")
                        : i18n.t("numberClassified")) + "..."
                    }
                    defaultValue={formData.count_teams_for_next_phase}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        count_teams_for_next_phase: e.target.value,
                      }));
                    }}
                    isRequired={false}
                    isPassword={false}
                    typeInput="number"
                  />
                </Flex>
                {typePhaseDescription === TypePhasesSetup.groups && (
                  <Flex direction="column" gap={2} width="100%" height="100%">
                    <Flex
                      borderBottom="1px"
                      borderColor={colors.backgrounds.gray1}
                      padding="8px 16px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <BodyText
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="semiBold"
                        textColor={colors.text.black}
                      >
                        {i18n.t("groups")}
                      </BodyText>
                      <Button
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="600"
                        gap={2}
                        color={colors.figmaColors.secD}
                        bg="white"
                        onClick={() => {
                          goToStep(stepsTournaments.createGroup);
                        }}
                      >
                        {i18n.t("add")}
                        <IconAddSquare />
                      </Button>
                    </Flex>
                    <Flex
                      direction="column"
                      gap={2}
                      maxHeight="100px"
                      flexGrow={1}
                      overflowY="auto"
                      css={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",
                      }}
                    >
                      {groups.length > 0 ? (
                        groups.map((group) => (
                          <Flex
                            padding="12px 16px"
                            borderRadius={16}
                            gap={3}
                            alignItems="center"
                            justifyContent="space-between"
                            bg={colors.backgrounds.gray0}
                          >
                            <Flex direction="column">
                              <BodyText
                                fontSize={{ base: "12px", md: "14px" }}
                                fontWeight="medium"
                                textColor={colors.text.black}
                              >
                                {group.name}
                              </BodyText>
                            </Flex>
                            <Flex gap={3}>
                              <IconEdit
                                stroke={colors.figmaColors.secColor}
                                cursor="pointer"
                                onClick={() => {
                                  setSelectedGroup(group);
                                  goToStep(stepsTournaments.updateGroup);
                                }}
                              />
                              <IconTrash
                                stroke={colors.backgrounds.red}
                                cursor="pointer"
                                onClick={() => {
                                  setSelectedGroup(group);
                                  goToStep(stepsTournaments.deleteGroup);
                                }}
                              />
                            </Flex>
                          </Flex>
                        ))
                      ) : (
                        <BodyText
                          fontSize={{ base: "11px", md: "12px" }}
                          fontWeight="semiBold"
                          textColor={colors.text.black}
                        >
                          {i18n.t("noData")}
                        </BodyText>
                      )}
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Box padding="0 16px 0 16px">
                <PrivacySwitch
                  isPrivate={isRound}
                  isPrivateText={i18n.t("roundTrip")}
                  onSwitchChange={handleSwitchChange}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditPhase;
