import { Avatar, Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { appStore } from '../../../zustand/globalStore'
import CoupDefault from "../../../assets/images/coup-default.png"
import { colors } from '../../../definitions/constants/GlobalStyles'
import BodyText from '../../../components/Common/BodyText'

const CoupHeaderComponent = () => {
    const {currentCup} = appStore(store=>store)
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      gap={2}
      padding={"8px 0px"}
    >
        <Flex direction={"column"}  alignItems={"center"} gap={2}>
          <Avatar src={CoupDefault} border={"1px solid "+colors.figmaColors.secColor} width={"64px"} height={"64px"}/>
          <BodyText
                fontSize={{ base: "15px", md: "17px" }}
                fontWeight="semiBold"
                textColor={colors.text.black}
                textAlign="left"
          >
                {currentCup.name}
          </BodyText>
        </Flex>
    </Flex>
  )
}

export default CoupHeaderComponent
