import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { useState } from "react";
import "../styles/InviteManagers.scss";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";
import { RolManager } from "../../../../../definitions/enums/GlobalEnums";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import { InputErrorIcon } from "../../../../../definitions/constants/IconsComponent";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../config/firebase";
import { appStore } from "../../../../../zustand/globalStore";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import ItemInviteManager from "./components/ItemInviteManager";
import { inviteUserAsManagerTeam } from "../../../../../models/TeamModel";
import CustomMenuSelect from "../../../../../components/Shared/CustomMenuSelect";
import SearchUser from "../../../../../components/Common/SearchUser";

const InviteManagers = ({ goToStep, hideContainerModal }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rol, setRol] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectUser, setSelectUser] = useState<User | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { userData, currentTeam } = appStore((store) => store);

  const processInvitation = async () => {
    setLoading(true);
    await inviteUserAsManagerTeam(
      userData.id,
      selectUser.id,
      currentTeam.id,
      currentTeam.name,
      rol
    );
    setLoading(false);
    goToStep(stepsTeams.successInvite);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    if (searchText.length > 0) {
      setSearchLoading(true);
      const findUsers = await httpsCallable(
        functions,
        "searchUsersByText"
      )({
        filter: `%${e.target.value.toLocaleLowerCase()}%`,
        userId: userData.id,
      });
      setSearchLoading(false);
      console.log(findUsers);
      if (findUsers && findUsers.data) {
        setUsers(findUsers.data as User[]);
      }
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "rol",
        value: rol,
      },
      {
        key: "selectUser",
        value: selectUser,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      processInvitation();
    } else {
      return;
    }
  };

  return (
    <Flex direction="column" gap={4} width={"100%"} height={"100%"}>
      <ModalHeaderComponent
        title={i18n.t("inviteManager")}
        subtitle={i18n.t("StartManagingTeam")}
        confirmText={i18n.t("invite")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(stepsTeams.managers)}
        onConfirm={handleSubmit}
        isSaving={loading}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      <Flex
        className="container-inputs"
        direction="column"
        gap={4}
        width={"100%"}
        height={"100%"}
      >
        <FormControl
          gap={1}
          flexDirection={"column"}
          width="100%"
          isRequired={true}
          isInvalid={!!errors.rol}
        >
          <FormLabel
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            requiredIndicator={null}
          >
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="semiBold"
              paddingLeft={4}
              textColor={colors.text.black}
            >
              {i18n.t("role")}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          </FormLabel>
          <CustomMenuSelect
            options={RolManager}
            isInvalid={!!errors.rol}
            selectedDescription={rol}
            setSelectedDescription={setRol}
          />
          {errors.rol && (
            <FormErrorMessage color="red.500" gap="4px">
              <InputErrorIcon />
              <BodyText color="red.500">
                {errors.rol || i18n.t("input_error")}
              </BodyText>
            </FormErrorMessage>
          )}
        </FormControl>
        <SearchUser
          searchText={searchText}
          handleSearch={handleSearch}
          errors={errors}
          label={i18n.t("searchUser")}
          placeholder={i18n.t("searchUser") + "..."}
        />
        <Flex
          className="container-content-users"
          direction="column"
          gap={2}
          width={"100%"}
          height={"100%"}
        >
          <BodyText
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="regular"
            textColor={colors.text.lightGray3}
          >
            {i18n.t("searchResult")}: {searchText}
          </BodyText>
          {searchLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={"20px 0px"}
            >
              <Spinner size="lg" color="blue.500" />
            </Box>
          ) : (
            <Flex direction="column" gap={2}>
              {users.length > 0 &&
                users
                  .slice(0, 3)
                  .map((item) => (
                    <ItemInviteManager
                      item={item}
                      setUser={setSelectUser}
                      userActive={selectUser}
                    />
                  ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InviteManagers;
