import { Avatar, Box, Button, Flex } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { User } from "../../definitions/interfaces/Users/Users.interface";
import { ReactDispatchUserState } from "../../definitions/interfaces/Global/ReactDispatchStringState.type";
import BodyText from "./BodyText";

const ItemInviteUser = ({
  item,
  setUser,
  userActive,
}: {
  item: User;
  setUser: ReactDispatchUserState;
  userActive: User;
}): JSX.Element => {
  return (
    <Button
      key={item.id}
      sx={{
        ...style.container,
        border:
          userActive?.id === item?.id
            ? `1px solid ${colors.figmaColors.secColor}`
            : null,
      }}
      className="container-item-user"
      onClick={() => setUser(item)}
    >
      <Box className="user-detail">
        <Avatar
          src={item.image_url}
          name={`${item.firstname} ${item.lastname}`}
        />
        <Flex direction="column" alignItems="flex-start">
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.black}
          >
            {item.firstname} {item.lastname}
          </BodyText>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="regular"
            textColor={colors.text.lightGray3}
          >
            {`@${item.username?.toLowerCase().trim()}`}
          </BodyText>
        </Flex>
      </Box>
    </Button>
  );
};
export default ItemInviteUser;

const style = {
  container: {
    padding: "12px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
};
