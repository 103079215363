import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import { appStore } from "../../../zustand/globalStore";
import ItemInviteUser from "../../Common/ItemInviteUser";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import SearchUser from "../../Common/SearchUser";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import "../Players/styles/InvitePlayers.style.scss";
import { ReactDispatchStringState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { steps } from "../Players/containers/steps.container";
import { inviteUserAsPlayer } from "../../../models/TeamModel";
import { addPlayerToTeam } from "../../../models/Tournament";

const InvitePlayers = ({
  goStep,
}: {
  goStep: ReactDispatchStringState;
}): JSX.Element => {
  const [players, setPlayers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { userData, currentTournament, hideContainerModal, currentTeam } =
    appStore((store) => store);
  const [selectUser, setSelectUser] = useState<User | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (searchText.length > 0) {
        const users = await httpsCallable(
          functions,
          "searchPlayer"
        )({
          filter: `%${searchText.toLocaleLowerCase()}%`,
          type_user: "user",
          userId: userData.id,
          tournamentId: currentTournament?.id,
        });
        setPlayers(users.data as User[]);
        setLoading(false);
      } else {
        const users = await httpsCallable(
          functions,
          "searchPlayer"
        )({
          filter: "%%",
          type_user: "user",
          userId: userData.id,
          tournamentId: currentTournament?.id,
        });
        setPlayers(users.data as User[]);
        setLoading(false);
      }
    })();
  }, [currentTournament?.id, userData?.id, searchText]);

  const processInvitation = async () => {
    setIsSaving(true);
    await inviteUserAsPlayer(
      userData.id,
      selectUser.id,
      currentTournament?.id,
      currentTeam?.id,
      currentTournament?.name,
      currentTeam?.name
    );
    await addPlayerToTeam(userData, selectUser, currentTeam, currentTournament);
    setIsSaving(false);
    goStep(steps.successInvitePlayer);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "selectUser",
        value: selectUser,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      processInvitation();
    } else {
      return;
    }
  };

  return (
    <Flex width={"100%"} height="100%" direction={"column"} gap={"20px"}>
      <ModalHeaderComponent
        title={i18n.t("invitePlayers")}
        subtitle={i18n.t("get_ready_for_the_tournament")}
        hasMoreSteps={true}
        actionButtonsRequired={true}
        onBack={() => goStep(steps.default)}
        onCancel={hideContainerModal}
        onConfirm={handleSubmit}
        confirmText={i18n.t("confirm")}
        isSaving={isSaving}
      />
      <SearchUser
        searchText={searchText}
        handleSearch={handleSearch}
        errors={errors}
        label={i18n.t("searchUser")}
        placeholder={i18n.t("searchUser") + "..."}
      />
      <Box border={`0.5px solid ${colors.backgrounds.gray1}`} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={"20px 0px"}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          overflow={"scroll"}
          className="container-content-users"
          gap={2}
        >
          {searchText.trim() !== "" && (
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("searchResults")}: {searchText}
            </BodyText>
          )}
          {players.length > 0 &&
            players.map((item, index) => (
              <ItemInviteUser
                item={item}
                setUser={setSelectUser}
                userActive={selectUser}
              />
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default InvitePlayers;
