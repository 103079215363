import { Avatar, Box, Button, Flex } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";
import CountryFlag from "./CountryFlag";

const ItemInviteTeam = ({
  item,
  setUser,
  teamActive,
}: {
  item: any;
  setUser: any;
  teamActive: any;
}): JSX.Element => {
  return (
    <Button
      key={item.id}
      sx={{
        ...style.container,
        border:
          teamActive?.id === item?.id
            ? `1px solid ${colors.figmaColors.secColor}`
            : null,
      }}
      className="container-item-user"
      onClick={() => setUser(item)}
    >
      <Box className="user-detail">
        <Avatar src={item.media_url} name={item.name} />
        <Flex direction="column" alignItems="flex-start">
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.black}
          >
            {item.name}
          </BodyText>
          <Flex alignItems="center" gap={1}>
            <CountryFlag countryName={item?.place?.terms?.at(-1)?.value} />
            <BodyText
              color={colors.figmaColors.grey3}
              fontWeight={"medium"}
              size="small"
            >
              {item?.place?.terms?.at(-1)?.value || i18n.t("noRegion")}
            </BodyText>
          </Flex>
        </Flex>
      </Box>
    </Button>
  );
};
export default ItemInviteTeam;

const style = {
  container: {
    padding: "12px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
};
