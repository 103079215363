import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import BodyText from "../../../../../components/Common/BodyText";
import OptionCard from "../../../../../components/Common/OptionCard";
import i18n from "../../../../../i18n/i18n";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import {
  FieldIcon,
  FlagIcon,
  MessageSquareIcon,
  ProfileCircle,
  RulerAndPenIcon,
} from "../../../../../definitions/constants/IconsComponent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import IconGroup from "../../../../../assets/icons/IconGroup";
import IconPeopleFC from "../../../../../assets/icons/IconPeopleFC";

interface BaseProps {
  goToStep: (key: string) => void;
}

const SeasonOptions: React.FC<BaseProps & FlexProps> = ({ goToStep }) => {
  return (
    <Flex {...flexProps}>
      <Flex flexDirection="column" gap={2}>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("seasonOption")}
        </BodyText>
        <OptionCard
          title={i18n.t("seasonName")}
          description={i18n.t("updateSeasonName")}
          icon={<ProfileCircle />}
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.seasonName)}
        />
        <OptionCard
          title={i18n.t("culminateSeason")}
          description={i18n.t("chooseChampion")}
          icon={<FlagIcon />}
          iconColor={colors.backgrounds.black}
          onClick={() => goToStep(stepsTournaments.finishSeason)}
        />
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("seasonAdjustments")}
        </BodyText>
        <OptionCard
          title={i18n.t("tournamentRules")}
          description={i18n.t("regulateRules")}
          icon={<RulerAndPenIcon />}
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.regulations)}
        />
        <OptionCard
          title={i18n.t("tournamentPhases")}
          description={i18n.t("createTournamentPhases")}
          icon={
            <IconGroup
              width="24px"
              height="24px"
              stroke={colors.backgrounds.white}
            />
          }
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.modality)}
        />
        <OptionCard
          title={i18n.t("selectTeams")}
          description={i18n.t("teamEachPhases")}
          icon={
            <IconPeopleFC
              fill={colors.backgrounds.white}
              stroke={colors.backgrounds.white}
            />
          }
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.selectTeams)}
        />
        <OptionCard
          title={i18n.t("createMatches")}
          description={i18n.t("selectClashes")}
          icon={<FieldIcon />}
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.createMatches)}
        />
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("inviteMoreTeams")}
        </BodyText>
        <OptionCard
          title={i18n.t("inviteTeams")}
          description={i18n.t("joinTeams")}
          icon={
            <IconPeopleFC
              fill={colors.backgrounds.white}
              stroke={colors.backgrounds.white}
            />
          }
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.inviteTeams)}
        />
        {/* <OptionCard
          title={i18n.t("applicationSent")}
          description={i18n.t("checkStatusInvitations")}
          icon={<MessageSquareIcon />}
          iconColor={colors.figmaColors.borderCard}
          onClick={() => goToStep(stepsTournaments.requestsSent)}
        /> */}
      </Flex>
    </Flex>
  );
};

export default SeasonOptions;

const flexProps: FlexProps = {
  flexDirection: "column",
  gap: 4,
  overflowY: "auto",
  css: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
};
