import { Box, Flex, Spinner } from "@chakra-ui/react";

interface SpinnerComponentProps {
  spinnerKey: string;
}

const SpinnerComponent: React.FC<SpinnerComponentProps> = ({ spinnerKey }) => {
  switch (spinnerKey) {
    case "modal":
      return (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      );

    case "match":
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={"20px 0px"}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      );

    case "detail":
      return (
        <Flex justify="center" align="center">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      );

    case "middle":
      return (
        <Flex justifyContent="center" alignItems="center" minHeight="50px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      );

    case "fullscreen":
      return (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      );
  }
};

export default SpinnerComponent;
