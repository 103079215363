import React, { useState, useEffect, useCallback } from "react";
import { Avatar, Box, Flex, FlexProps } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../../components/Common/BodyText";
import OptionCard from "../../../components/Common/OptionCard";
import HeaderDefaultOptions from "../../../components/Common/HeaderDefaultOptions";
import { consumeApi } from "../../../utils/tools";
import i18n from "../../../i18n/i18n";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import DeleteContentModal from "../../../components/Modals/DeleteContentModal";
import CreateOrEditPhase from "./Options/Phases/CreateOrEditPhase";
import CreateOrEditGroup from "./Options/Groups/CreateOrEditGroup";
import CreateOrEditSeason from "./Options/Seasons/CreateOrEditSeason";
import MessageConfirmationComponent from "../../../components/MessageConfirmationComponent";
import CreateOrEditTournament from "../../../components/Tournaments/CreateOrEditTournament";
import {
  getPhasesByTournamentId,
  getTeamsFromTournament,
} from "../../../models/Tournament";
import { stepsTournaments } from "../../../definitions/constants/GlobalConstants";
import { getGroupsByTournamentAndPhase } from "../../../models/Tournament";
import DeletePhase from "./Options/Phases/DeletePhase";
import DeleteGroup from "./Options/Groups/DeleteGroup";
import DeleteSeason from "./Options/Seasons/DeleteSeason";
import DeleteTournament from "./Options/DeleteTournament/DeleteTournament";
import TournamentRegulations from "./Options/Regulations/TournamentRegulations";
import InviteTeams from "./Options/Selection/InviteTeams";
import RequestsSent from "./Options/Selection/RequestsSent";
import TeamGroupPhaseSelection from "./Options/Selection/TeamGroupPhaseSelection";
import TeamTournamentSelection from "./Options/Selection/TeamTournamentSelection";
import { TypePhasesSetup } from "../../../definitions/enums/GlobalEnums";
import NoContentComponent from "../../../components/Common/NoContentComponent";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import DeleteMatch from "./Options/Matches/DeleteMatch";
import ShowMatchesScreen from "./Options/Matches/ShowMatchesScreen";
import ShowModalities from "./Options/Modality/ShowModalities";
import ShowSeasons from "./Options/Seasons/ShowSeasons";
import SeasonOptions from "./Options/Seasons/SeasonOptions";
import CreateOrEditMatch from "./Options/Matches/CreateOrEditMatch";

interface StepData {
  name?: string;
}

function UpdateOrViewTournamentData() {
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(stepsTournaments.default);
  const [stepHistory, setStepHistory] = useState<string[]>([]);
  const [stepData, setStepData] = useState<StepData>({});
  const [teams, setTeams] = useState([]);
  const [phases, setPhases] = useState([]);
  const [groups, setGroups] = useState([]);
  const [matches, setMatches] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [teamsMatch, setTeamsMatch] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const { currentTournament, hideContainerModal } = appStore((state) => state);
  console.log("The current step is: ", currentStep);

  const goToStep = (stepKey: string, data?: StepData) => {
    setStepHistory((prevHistory) => [...prevHistory, currentStep]);
    setCurrentStep(stepKey);
    if (data) setStepData((prevData) => ({ ...prevData, ...data }));
  };

  const handleBack = () => {
    const previousStep = stepHistory[stepHistory.length - 1];
    if (previousStep) {
      setStepHistory((prevHistory) => prevHistory.slice(0, -1));
      setCurrentStep(previousStep);
    }
  };

  const handleGoToStepGroupSelection = (phase: any) => {
    if (phase.type_phase_setup === TypePhasesSetup.groups) {
      return currentStep === stepsTournaments.createMatches
        ? stepsTournaments.groupsSelectionMatch
        : stepsTournaments.groupsSelection;
    } else {
      return currentStep === stepsTournaments.createMatches
        ? stepsTournaments.matchesOf
        : stepsTournaments.teamsSelection;
    }
  };

  const fetchSeasons = useCallback(async () => {
    try {
      const payload = { tournament_id: currentTournament?.id };
      const response = await consumeApi(
        "tournaments/get_seasons",
        "POST",
        payload
      );

      if (response.status === "success") {
        setSeasons(response.seasons);
      } else {
        console.error("Error fetching seasons:", response.message);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }, [currentTournament?.id, setSeasons]);

  const fetchPhases = useCallback(async () => {
    try {
      const phasesFetch = await getPhasesByTournamentId(currentTournament.id);
      setPhases(phasesFetch);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching phases:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  }, [currentTournament?.id, setPhases]);

  const fetchMatches = useCallback(async () => {
    try {
      const matchesLoad: any = await httpsCallable(
        functions,
        selectedPhase.have_groups
          ? "getMatchesAndTeamsByGroup"
          : "getMatchesAndTeamsByPhase"
      )(
        selectedPhase.have_groups
          ? { group_id: selectedGroup?.id }
          : { phase_id: selectedPhase.id }
      );

      setTeamsMatch(matchesLoad.data.teams);
      setMatches(matchesLoad.data.matches);
    } catch (error) {
      console.error("Error fetching matches:", error);
    }
  }, [
    selectedPhase?.have_groups,
    selectedPhase?.id,
    selectedGroup?.id,
    setMatches,
    setTeamsMatch,
  ]);

  const fetchGroups = useCallback(async () => {
    if (selectedPhase?.id) {
      try {
        const groupsData = await getGroupsByTournamentAndPhase(
          currentTournament?.id,
          selectedPhase.id
        );
        setGroups(groupsData);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    }
  }, [currentTournament?.id, selectedPhase?.id, setGroups]);

  const fetchTeams = useCallback(async () => {
    try {
      getTeamsFromTournament(currentTournament.id).then((teams) =>
        setTeams(teams)
      );
    } catch (error) {
      console.error("Error loading teams: ", error);
    }
  }, [currentTournament?.id]);

  useEffect(() => {
    if (currentTournament?.id) {
      fetchTeams();
      fetchPhases();
      fetchSeasons();
    }
  }, [fetchTeams, fetchPhases, fetchSeasons, currentTournament]);

  useEffect(() => {
    if (selectedPhase?.id) {
      fetchGroups();
      fetchMatches();
    }
  }, [fetchGroups, fetchMatches, selectedPhase?.id, groups]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={
        currentStep === stepsTournaments.deletePhase ||
        currentStep === stepsTournaments.deleteMatch ||
        currentStep === stepsTournaments.deleteSeason ||
        currentStep === stepsTournaments.deleteGroup ||
        currentStep === stepsTournaments.deleteTournament ||
        currentStep === stepsTournaments.sureDeletion
          ? 8
          : 4
      }
      width="100%"
      height="auto"
    >
      {currentStep === stepsTournaments.default && (
        <HeaderDefaultOptions
          title={i18n.t("tournamentOptions")}
          description={i18n.t("readyTournament")}
        />
      )}
      {/* Content for header modals */}
      {currentStep === stepsTournaments.optionsSeason && selectedSeason && (
        <ModalHeaderComponent
          title={i18n.t("seasonOptions", { name: selectedSeason.name })}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.default)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.selectTeams && (
        <ModalHeaderComponent
          title={i18n.t("selectTeams")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.groupsSelection && (
        <ModalHeaderComponent
          title={i18n.t("selectGroup")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.selectTeams)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.groupsSelectionMatch && (
        <ModalHeaderComponent
          title={i18n.t("selectGroup")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.createMatches)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.createMatches && (
        <ModalHeaderComponent
          title={i18n.t("createMatches")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {/* Content for body modals */}
      {currentStep === stepsTournaments.default && (
        <ShowSeasons
          goToStep={goToStep}
          seasons={seasons}
          setSelectedSeason={setSelectedSeason}
        />
      )}
      {currentStep === stepsTournaments.editTournament && (
        <CreateOrEditTournament
          isEdit={true}
          steps={stepsTournaments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successEdit && (
        <MessageConfirmationComponent
          title={i18n.t("tournamentUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.newSeason && (
        <CreateOrEditSeason
          isEdit={false}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successNewSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonCreated", { name: stepData.name })}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.optionsSeason && (
        <SeasonOptions goToStep={goToStep} />
      )}
      {currentStep === stepsTournaments.seasonName && (
        <CreateOrEditSeason
          isEdit={true}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedSeason={selectedSeason}
        />
      )}
      {currentStep === stepsTournaments.successSeasonName && (
        <MessageConfirmationComponent
          title={i18n.t("nameSeasonUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.finishSeason && (
        <TeamTournamentSelection
          goToStep={goToStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedItem={selectedTeam}
          setSelectedItem={setSelectedTeam}
          teams={teams}
          setTeams={setTeams}
        />
      )}
      {currentStep === stepsTournaments.successFinishSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonFinished")}
          middleTitle={i18n.t("haveChampion", { name: selectedTeam.name })}
          subtitle={i18n.t("seeNextSeason")}
          teamIcon={
            <Avatar
              src={selectedTeam.media_url}
              name={selectedTeam.name}
              boxSize="60px"
            />
          }
          teamName={selectedTeam.name}
          isDelete={false}
          isFinished={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.regulations && (
        <TournamentRegulations
          goToStep={goToStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successSaveRegulations && (
        <MessageConfirmationComponent
          title={i18n.t("regulationsUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.modality && (
        <ShowModalities
          goToStep={goToStep}
          isSaving={isSaving}
          phases={phases}
          setSelectedPhase={setSelectedPhase}
        />
      )}
      {currentStep === stepsTournaments.newPhase && (
        <CreateOrEditPhase
          isEdit={false}
          goToStep={goToStep}
          groups={groups}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successNewPhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseCreated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.editPhase && (
        <CreateOrEditPhase
          isEdit={true}
          goToStep={goToStep}
          groups={groups}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          setSelectedGroup={setSelectedGroup}
        />
      )}
      {currentStep === stepsTournaments.successEditPhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseEdited")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.deletePhase && (
        <DeletePhase goToStep={goToStep} selectedPhase={selectedPhase} />
      )}
      {currentStep === stepsTournaments.successDeletePhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.createGroup && (
        <CreateOrEditGroup
          isEdit={false}
          onBack={handleBack}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
        />
      )}
      {currentStep === stepsTournaments.updateGroup && (
        <CreateOrEditGroup
          isEdit={true}
          onBack={handleBack}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
        />
      )}
      {currentStep === stepsTournaments.deleteGroup && (
        <DeleteGroup
          onBack={handleBack}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
        />
      )}
      {(currentStep === stepsTournaments.selectTeams ||
        currentStep === stepsTournaments.createMatches) && (
        <Flex
          {...flexProps}
          width="100%"
          height="100%"
          justifyContent={phases.length === 0 ? "center" : "flex-start"}
          alignItems={phases.length === 0 ? "center" : "flex-start"}
        >
          {currentStep === stepsTournaments.createMatches &&
            phases.length > 0 && (
              <BodyText
                fontSize={{ base: "12px", md: "14px" }}
                fontWeight="semiBold"
                textColor={colors.text.black}
              >
                {i18n.t("selectPhase")}
              </BodyText>
            )}
          {phases.length > 0 ? (
            phases.map((phase) => (
              <OptionCard
                title={phase.name}
                description={i18n.t("teamsSelected", {
                  teams:
                    phase.count_teams_by_group &&
                    phase.count_teams_by_group.trim() !== ""
                      ? phase.count_teams_by_group
                      : 0,
                  teamsLimit:
                    phase.count_teams_by_group &&
                    phase.count_teams_by_group.trim() !== ""
                      ? phase.count_teams_by_group
                      : 0,
                })}
                arrowColor={colors.figmaColors.secColor}
                onClick={() => {
                  setSelectedPhase(phase);
                  setSelectedGroup(null);
                  goToStep(handleGoToStepGroupSelection(phase));
                }}
              />
            ))
          ) : (
            <NoContentComponent textPrimary={i18n.t("phasesNotLoaded")} />
          )}
        </Flex>
      )}
      {(currentStep === stepsTournaments.groupsSelection ||
        currentStep === stepsTournaments.groupsSelectionMatch) && (
        <Flex
          {...flexProps}
          width="100%"
          height="100%"
          justifyContent={groups.length === 0 ? "center" : "flex-start"}
          alignItems={groups.length === 0 ? "center" : "flex-end"}
        >
          {groups.length > 0 ? (
            groups.map((group) => (
              <OptionCard
                key={group.id}
                title={group.name}
                description={i18n.t("teamsSelected", {
                  teams:
                    group.count_teams && group.count_teams.trim() !== ""
                      ? group.count_teams
                      : 0,
                  teamsLimit:
                    group.count_teams && group.count_teams.trim() !== ""
                      ? group.count_teams
                      : 0,
                })}
                arrowColor={colors.figmaColors.secColor}
                onClick={() => {
                  setSelectedGroup(group);
                  const nextStep =
                    currentStep === stepsTournaments.groupsSelection
                      ? stepsTournaments.teamsSelection
                      : stepsTournaments.matchesOf;

                  goToStep(nextStep);
                }}
              />
            ))
          ) : (
            <NoContentComponent textPrimary={i18n.t("groupsNotLoaded")} />
          )}
        </Flex>
      )}
      {currentStep === stepsTournaments.teamsSelection && (
        <TeamGroupPhaseSelection
          goToStep={goToStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
        />
      )}
      {currentStep === stepsTournaments.successTeamsSelection && (
        <MessageConfirmationComponent
          title={i18n.t("phaseEdited")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.matchesOf && (
        <ShowMatchesScreen
          matches={matches}
          teams={teamsMatch}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
          goToStep={goToStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          setSelectedMatch={setSelectedMatch}
        />
      )}
      {(currentStep === stepsTournaments.newMatch ||
        currentStep === stepsTournaments.technicalTeam) && (
        <CreateOrEditMatch
          goToStep={goToStep}
          isEdit={false}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          // steps={stepsTournaments}
        />
      )}
      {(currentStep === stepsTournaments.editMatch ||
        currentStep === stepsTournaments.editTechnicalTeam) && (
        <CreateOrEditMatch
          goToStep={goToStep}
          isEdit={true}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
          selectedMatch={selectedMatch}
          // steps={stepsTournaments}
        />
      )}
      {currentStep === stepsTournaments.successEditMatch && (
        <MessageConfirmationComponent
          title={i18n.t("matchUpdated", {
            teamA: selectedMatch.first_team_name,
            teamB: selectedMatch.second_team_name,
          })}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.deleteMatch && (
        <DeleteMatch goToStep={goToStep} selectedMatch={selectedMatch} />
      )}
      {currentStep === stepsTournaments.successDeleteMatch && (
        <MessageConfirmationComponent
          title={i18n.t("matchDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.inviteTeams && (
        <InviteTeams goToStep={goToStep} />
      )}
      {currentStep === stepsTournaments.successInviteTeams && (
        <MessageConfirmationComponent
          title={i18n.t("invitationSuccessfully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.requestsSent && (
        <RequestsSent goToStep={goToStep} />
      )}
      {currentStep === stepsTournaments.deleteSeason && (
        <DeleteSeason
          goToStep={goToStep}
          selectedSeason={selectedSeason}
          setSeasons={setSeasons}
        />
      )}
      {currentStep === stepsTournaments.successDeleteSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.deleteTournament && (
        <DeleteContentModal
          message={i18n.t("sureDeleteTournament")}
          subMessage={i18n.t("allDeleteTournamentData")}
          onCancel={hideContainerModal}
          onConfirm={() => goToStep(stepsTournaments.sureDeletion)}
        />
      )}
      {currentStep === stepsTournaments.sureDeletion && (
        <DeleteTournament goToStep={goToStep} />
      )}
      {currentStep === stepsTournaments.successDeleteTournament && (
        <MessageConfirmationComponent
          title={i18n.t("tournamentDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
    </Box>
  );
}

export default UpdateOrViewTournamentData;

const flexProps: FlexProps = {
  flexDirection: "column",
  gap: 4,
  overflowY: "auto",
  css: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
};
