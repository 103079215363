import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/react";
import BodyText from "../../Common/BodyText";
import CitySelector from "../../Common/CitySelector";
import ImageUploader from "../../Common/ImageUploader";
import { colors } from "../../../definitions/constants/GlobalStyles";
import i18n from "../../../i18n/i18n";
import { PlaceDetails } from "../../../definitions/interfaces/Place.interface";
import { appStore } from "../../../zustand/globalStore";
import "react-datepicker/dist/react-datepicker.css";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import {
  handleImageChange,
  consumeApi,
  createLocalityFromPlace,
  uploadFileToAmazonS3Web,
} from "../../../utils/tools";
import { steps } from "../Players/containers/steps.container";
import { DataformTeam } from "./Interfaces/DataFormPlayer.interface";
import SelectDate from "../../Common/SelectDate";
import ImageIdUploader from "../../Common/ImageIdUploader";
import { getUserByUsername } from "../../../models/UserModel";
import { v4 as uuidV4 } from "uuid";
import { DeviceOs } from "../../../hooks/DeviceOs";
import { addPlayerToTeam } from "../../../models/Tournament";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { Positions } from "../Players/containers/positions.container";
import CustomMenuSelect from "../../Shared/CustomMenuSelect";
import SpinnerComponent from "../../Common/SpinnerComponent";
import CustomTextInput from "../../Common/CustomTextInput";
import { InputErrorIcon } from "../../../definitions/constants/IconsComponent";

const RegisterAndEditPlayer: React.FC<{
  action: string;
  goToStep: (key: string) => void;
  player?: User;
  // isSaving: boolean;
  // setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ action, goToStep, player }) => {
  const [place, setPlace] = useState<PlaceDetails | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [position, setPosition] = useState<string>(
    action === "edit" ? player.position : ""
  );
  const { currentTeam, currentTournament, hideContainerModal, userData } =
    appStore((state) => state);

  const dataFormInit =
    action === "edit"
      ? {
          jerseyNumber: player.number,
          name: player.firstname,
          lastname: player.lastname,
          date: player.bith_date,
          email: player.email,
          direction: player.direction,
          city: "",
          mediaProfile: player.image_url,
          mediaId: player.image_dni,
          position: player.position,
        }
      : {
          jerseyNumber: "",
          name: "",
          lastname: "",
          date: "",
          email: "",
          direction: "",
          city: "",
          mediaProfile: "",
          mediaId: "",
          position: "",
        };
  const [formData, setFormData] = useState<DataformTeam>(dataFormInit);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [image, setImage] = useState(
    action === "edit" ? currentTeam.media_url : ""
  );
  const [imageId, setImageId] = useState("");
  const [mediaId, setMediaId] = useState<any>(null);
  useEffect(() => {
    if (action === "edit" && currentTeam?.private !== undefined) {
      setIsPrivate(currentTeam.private);
    }
  }, [action, currentTeam]);

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const handleInputIdImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImageId, setMediaId);
  };

  const onPressRegisterUser = async (data: any) => {
    try {
      const dataSave: any = {};
      dataSave.direction = data.direction;
      dataSave.email = data.email;
      dataSave.position = position;
      if (place) {
        dataSave.location_data = createLocalityFromPlace(place);
      }
      if (data.birthDate) {
        dataSave.bith_date = data.birthDate;
      }
      // else {
      //     delete data.bith_date
      // }
      let username = `${data.name}${data.lastname}`
        .toLowerCase()
        .split(" ")
        .join("")
        .split("@")
        .join("");
      dataSave.number = data.jerseyNumber;
      const usersByUsername = await getUserByUsername(username);
      if (usersByUsername.length > 0) {
        username = `${username}${usersByUsername.length}`;
      }
      dataSave.username = username;
      dataSave.firstname = data.name;
      dataSave.lastname = data.lastname;

      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        dataSave.image_url = response;
      }
      if (mediaId) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        dataSave.image_dni = response;
      }
      let publicIp = await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => data.ip);
      dataSave.device_os = DeviceOs();
      dataSave.ip_address = publicIp;

      let createOrEditUser = null;
      if (action === "edit") {
        delete data.email;
        delete dataSave.email;
        createOrEditUser = await consumeApi(`users/update_user`, "POST", {
          user_id: player.id,
          fields_to_update: dataSave,
        });
      } else {
        dataSave.firebase_id = uuidV4().toString();
        createOrEditUser = await consumeApi(
          `users/create_user`,
          "POST",
          dataSave
        );
      }
      console.log(createOrEditUser, "USER RESPONSE CREATE");
      if (createOrEditUser?.status === "success") {
        if (action === "create") {
          dataSave.id = dataSave.firebase_id;
          await addPlayerToTeam(
            userData,
            dataSave,
            currentTeam,
            currentTournament
          );
          goToStep(steps.successRegisterPlayer);
        } else {
          goToStep(steps.successEditPlayer);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
      { key: "email", value: formData.email },
      { key: "jerseyNumber", value: formData.jerseyNumber },
      { key: "lastname", value: formData.lastname },
      { key: "direction", value: formData.direction },
      { key: "position", value: position },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      setIsSaving(true);
      await onPressRegisterUser(formData);
      setIsSaving(false);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerComponent spinnerKey="modal" />
      ) : (
        <Box>
          <Box
            as="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={"16px"}
            flexGrow={1}
          >
            <ModalHeaderComponent
              title={
                action === "edit"
                  ? i18n.t("editPlayer")
                  : i18n.t("registerPlayers")
              }
              subtitle={
                action === "edit"
                  ? i18n.t("StartManagingTeam")
                  : i18n.t("get_ready_for_the_tournament")
              }
              confirmText={
                action === "edit" ? i18n.t("save") : i18n.t("confirm")
              }
              onCancel={hideContainerModal}
              onBack={() => goToStep(steps.default)}
              onConfirm={handleSubmit}
              isSaving={isSaving}
              hasMoreSteps={true}
              actionButtonsRequired={true}
            />
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap={8}
              width={"100%"}
              height={"100%"}
            >
              <GridItem colSpan={1} display="flex">
                <Flex direction={"column"} gap={6} width={"100%"}>
                  <FormControl
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ImageUploader
                      image={
                        formData.mediaProfile ? formData.mediaProfile : image
                      }
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                  <FormControl
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ImageIdUploader
                      image={formData.mediaId ? formData.mediaId : imageId}
                      onImageChange={handleInputIdImageChange}
                    />
                  </FormControl>
                </Flex>
              </GridItem>
              <GridItem colSpan={1}>
                <Flex gap={2.5} flexDirection={"column"}>
                  {/* INPUT JERSEY */}
                  <CustomTextInput
                    textField={i18n.t("jersey")}
                    placeholder={i18n.t("jerseyNumber") + "..."}
                    defaultValue={formData.jerseyNumber}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        jerseyNumber: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, jerseyNumber: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.jerseyNumber}
                    showError={!!errors.jerseyNumber}
                    typeInput="number"
                  />
                  {/* INPUT NAME */}
                  <CustomTextInput
                    textField={i18n.t("name")}
                    placeholder={i18n.t("name") + "..."}
                    defaultValue={formData.name}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, name: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.name}
                    showError={!!errors.name}
                    typeInput="text"
                  />
                  {/* INPUT LASTNAME */}
                  <CustomTextInput
                    textField={i18n.t("lastName")}
                    placeholder={i18n.t("lastName") + "..."}
                    defaultValue={formData.lastname}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        lastname: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, lastname: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.lastname}
                    showError={!!errors.lastname}
                    typeInput="text"
                  />
                  {/* INPUT DATE PICKER */}
                  <SelectDate
                    date={birthDate}
                    error={errors.birthDate}
                    setDate={setBirthDate}
                    label="birthDate"
                    placeholder="birthDate"
                    required={true}
                  />
                </Flex>
              </GridItem>
              <GridItem colSpan={1}>
                <Flex gap={2.5} flexDirection={"column"}>
                  {/* INPUT EMAIL */}
                  <CustomTextInput
                    textField={i18n.t("email")}
                    placeholder={i18n.t("email") + "..."}
                    defaultValue={formData.email}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, email: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.email}
                    showError={!!errors.email}
                    typeInput="email"
                  />
                  {/* INPUT DIRECTION */}
                  <CustomTextInput
                    textField={i18n.t("direction")}
                    placeholder={i18n.t("direction") + "..."}
                    defaultValue={formData.direction}
                    onChangeValue={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        direction: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, direction: "" }));
                    }}
                    isPassword={false}
                    errorMessage={errors.direction}
                    showError={!!errors.direction}
                    typeInput="text"
                  />
                  {/* INPUT PLACE PICKER CITY */}
                  <CitySelector
                    error={errors.city}
                    place={place}
                    setPlace={setPlace}
                  />
                  <FormControl
                    gap={1}
                    flexDirection={"column"}
                    width="100%"
                    isRequired={true}
                    isInvalid={!!errors.position}
                  >
                    <FormLabel
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                      requiredIndicator={null}
                    >
                      <BodyText
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="semiBold"
                        paddingLeft={4}
                        textColor={colors.text.black}
                      >
                        {i18n.t("position")}
                      </BodyText>
                      <BodyText
                        fontSize={{ base: "12px", md: "13px" }}
                        fontWeight="regular"
                        textColor={colors.text.lightGray3}
                      >
                        {i18n.t("required")}
                      </BodyText>
                    </FormLabel>
                    <CustomMenuSelect
                      options={Positions}
                      isInvalid={!!errors.position}
                      selectedDescription={position}
                      setSelectedDescription={setPosition}
                    />
                    {errors.position && (
                      <FormErrorMessage color="red.500" gap="4px">
                        <InputErrorIcon />
                        <BodyText color="red.500">
                          {errors.position || i18n.t("input_error")}
                        </BodyText>
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RegisterAndEditPlayer;
