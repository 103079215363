import { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
} from "@chakra-ui/react";
import {
  InputErrorIcon,
  EyeOpenedIcon,
  EyeClosedIcon,
} from "../../definitions/constants/IconsComponent";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";
import { colors } from "../../definitions/constants/GlobalStyles";

interface BaseProps {
  placeholder?: string;
  textField: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  onChangeText?: (text: string) => void;
  showError?: boolean;
  defaultValue?: string;
  errorMessage?: string;
  isContentDelete?: boolean;
  onChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface PasswordProps extends BaseProps {
  isPassword: true;
  typeInput?: never;
}

interface NonPasswordProps extends BaseProps {
  isPassword: false;
  typeInput: string;
}

type CustomTextInputProps = PasswordProps | NonPasswordProps;

const CustomTextInput: React.FC<CustomTextInputProps> = ({
  placeholder,
  textField,
  isRequired = true,
  isReadOnly = false,
  onChangeText,
  isPassword,
  showError,
  typeInput,
  defaultValue,
  errorMessage,
  onChangeValue,
  isContentDelete = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    if (onChangeText) {
      onChangeText(text);
    }
  };

  return (
    <Box mt={0} width="100%" maxWidth={isContentDelete ? "500px" : "none"}>
      <FormControl
        display="flex"
        flexDirection="column"
        isRequired={isRequired}
        isInvalid={showError}
        gap="4px"
      >
        <FormLabel
          display="flex"
          flexDirection="row"
          fontWeight={600}
          gap={1}
          mb={0}
          ml={4}
          requiredIndicator={null}
          fontSize={14}
        >
          {textField}
          {isRequired && (
            <BodyText
              color={colors.text.lightGray3}
              fontSize={13}
              fontWeight="regular"
              as="span"
            >
              {i18n.t("required")}
            </BodyText>
          )}
        </FormLabel>
        <InputGroup>
          <Input
            type={isPassword && !showPassword ? "password" : typeInput}
            placeholder={placeholder}
            value={defaultValue || inputValue}
            onChange={onChangeValue || handleChange}
            border="1px"
            borderColor={colors.backgrounds.gray1}
            borderRadius="20px"
            height="60px"
            fontSize="14px"
            color={colors.text.lightGray3}
            formNoValidate={true}
            aria-required={isRequired ? true : false}
            css={{
              "::-ms-reveal": { display: "none" },
            }}
            isReadOnly={isReadOnly ? true : false}
          />
          {isPassword && (
            <InputRightElement top="10px" right="5px">
              <IconButton
                aria-label={showPassword ? "Hide password" : "Show password"}
                icon={showPassword ? <EyeClosedIcon /> : <EyeOpenedIcon />}
                onClick={() => setShowPassword(!showPassword)}
                display="flex"
                variant="unstyled"
                size="sm"
              />
            </InputRightElement>
          )}
        </InputGroup>
        {showError && (
          <FormErrorMessage color="red.500" mt={0} gap="4px">
            <InputErrorIcon />
            <BodyText color="red.500">
              {errorMessage || i18n.t("input_error")}
            </BodyText>
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomTextInput;
