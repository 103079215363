import { Avatar, Box, Button, Flex, Image } from "@chakra-ui/react";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { ReactDispatchUserState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { useEffect, useState } from "react";
import axios from "axios";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { TShirtIcon } from "../../../definitions/constants/IconsComponent";
import i18n from "../../../i18n/i18n";


const ItemUser = ({
  item,
  type,
  setUser,
  userActive,
}: {
  item: User;
  setUser?: ReactDispatchUserState;
  userActive?: User;
  type: string;
}): JSX.Element => {
  const [flag, setFlag] = useState(null);
  const [location, setLocation] = useState(null)
  useEffect(() => {
    (async () => {
      try {
        const jsonLocation = JSON.parse(item.location_data);
        setLocation(jsonLocation.country || jsonLocation.terms[0]?.value)
        const responseFlag = await axios.get(
          `https://restcountries.com/v3.1/name/${jsonLocation.country}?fullText=true`
        );
        if (responseFlag.data) {
          setFlag(responseFlag.data[0]?.flags.svg);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [item]);

  return (
    <Button className="container-item-user" sx={{justifyContent:"flex-start"}}>
      <Box className="user-detail">
        <Avatar
          src={item.image_url}
          name={`${item.firstname} ${item.lastname}`}
        />
        <Box>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.black}
          >
            {item.firstname} {item.lastname}
          </BodyText>
          <Flex gap={"5px"} alignItems={"center"}>
            <Image src={flag} height="14px" width="20px" />
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {location} ({type === "coach"?i18n.t(item.rol):i18n.t(item.position)})
            </BodyText>
          </Flex>
        </Box>
        {type === "player" && item.number && (
        <Box
          background={colors.figmaColors.secColor}
          padding={"8px 10px"}
          borderRadius={"20px"}
          display={"flex"}
          gap={"5px"}
        >
          <TShirtIcon style={{stroke:"white", width:"16px", height:"16px"}}/>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.white}
          >
            #{item.number}
          </BodyText>
        </Box>
      )}
      </Box>    
    </Button>
  );
};
export default ItemUser;